<template>
	<skeleton-block>
		<div class="skeleton-card-item">
			<div :key="line" v-for="line in lines">
				<skeleton-text-placeholder :length="getLineLength(line)" />
			</div>
		</div>
	</skeleton-block>
</template>

<script>
	import SkeletonBlock from './SkeletonBlock';
	import SkeletonTextPlaceholder from './SkeletonTextPlaceholder';

	export default {
		name: 'SkeletonCardItem',
		components: {SkeletonTextPlaceholder, SkeletonBlock},
		props: {
			lines: {
				type: Number,
				default: 4
			},
			linesWidth: {
				type: Array,
				default: () => [30, 20, 50]
			}
		},
		methods: {
			getLineLength(line) {
				const arrayIndex = (line - 1) % this.linesWidth.length;
				return this.linesWidth[arrayIndex];
			}
		}
	};
</script>

<style>
	.skeleton-card-item {
		padding: 20px;
	}
</style>
