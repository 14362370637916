<template>
	<div>
		<skeleton-block>
			<div :class="`block-result-grey`" class="block-result">
				<div class="block-result_title">
					<icon-status />
					<h2>
						<skeleton-text-placeholder length="20" />
					</h2>
				</div>
				<div class="block-result_content">
					<skeleton-text-placeholder length="12" />
					<br />
					<skeleton-text-placeholder length="20" />
				</div>
				<div class="block-result_content" v-if="!small">
					<skeleton-text-placeholder length="12" />
					<br />
					<em class="icon-chevron-right"></em>
					<skeleton-text-placeholder length="30" />

				</div>
				<div class="block-result_content" v-if="!small">
					<button class="button button-no-border">
						<em class="icon-edit"></em>
						<skeleton-text-placeholder length="10" />
					</button>
				</div>
			</div>
		</skeleton-block>
		<br>
	</div>

</template>

<script>
	import SkeletonTextPlaceholder from '@/components/generic/SkeletonTextPlaceholder';
	import SkeletonBlock from '@/components/generic/SkeletonBlock';
	import IconStatus from '@/components/icons/IconStatus';

	export default {
		name: 'SkeletonCardBlockResult',
		props: {
			small: {
				type: Boolean,
				default: false
			}
		},
		components: {IconStatus, SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>

