<template>
	<div v-if="answers.length > 0" class="block-question-container">
		<div class="block-question-content">
			<h2 class="question-content_title" v-html="descriptionWithFunctionGroup"></h2>

			<p v-for="answer in answers"
			   :key="answer.answerId"
			   class="question-content_answer">

				<template v-if="answer.length > 0">
					{{ answer }}
				</template>
				<template v-else>
					<span class="text-grey" title="Geen antwoord">&mdash;</span>
				</template>

				<button :class="{'disabled': !hasWriteAccess}"
				        class="question-content_edit-answer"
				        type="button"
				        @click="$emit('editAnswer', question)">
					<em class="icon-edit"></em>
				</button>
			</p>
		</div>
	</div>
</template>

<script>
	import {PHYSICAL_STRAIN_STORE} from '@/store/check/physicalstrain/physicalStrainMethods';
	import NumberUtils from '@/utils/numberUtils';
	import {replaceTag} from '@/utils/text';

	export default {
		name: 'AnswersOverviewQuestion',
		props: {
			question: {
				type: Object,
				required: true
			},
			answers: {
				type: Array,
				default: () => []
			},
			hasWriteAccess: {
				type: Boolean,
				required: true
			}
		},
		data: () => ({
			loading: false
		}),
		computed: {
			functionGroupId() {
				return NumberUtils.stringToNumber(this.$route.query.functionGroupId ?? null);
			},
			functionGroup() {
				return this.$store.getters[PHYSICAL_STRAIN_STORE.GET_FUNCTION_GROUP_BY_ID](this.functionGroupId);
			},
			descriptionWithFunctionGroup() {
				if (!this.functionGroupId) {
					return this.question.description;
				}
				const functionGroupName = this.functionGroup?.name ?? 'Functiegroep';
				return replaceTag(this.question.description, '${functiongroup}', functionGroupName, '<strong>', '</strong>');
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import 'src/assets/variables';
	@import 'src/assets/mixins';

	.block-question-container {
		margin: 0 0 20px;

		@include clearfix;
	}

	.block-question-content {
		background: #ffffff;
	}

	.question-content_title {
		background: #ffffff;
		color: #000000;
		font-family: $font-stack-content;
		font-weight: 600;
		font-size: 14px;
		line-height: 2em;
		margin: 0;
		padding: 6px 1em;
		border-bottom: 1px solid #eeeeee;
	}

	.question-content_answer {
		font-weight: normal;
		font-size: 14px;
		line-height: 2em;
		margin: 0;
		padding: 6px 1em;
		background: #f7f7f7;
	}

	.question-content_edit-answer {
		margin: 0;
		font-size: 15px;
		line-height: 1;
		text-decoration: none;
		color: $blue;

		-webkit-transition: color 0.3s;
		transition: color 0.3s;

		p & {
			padding: 6px 13px;
			float: none;
			background: none;
		}

		&:hover {
			color: #000000;
		}

		&.disabled {
			@include disabledIconButton;
		}

		.icon-edit {
			position: relative;
			top: 2px;
		}
	}
</style>
