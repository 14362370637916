<template>
	<base-grid page-title="Gegevens aanpassen" sidebar="company">

		<form-update-company-details :company-id="companyId" v-on:delete-company="confirmDeleteCompany" />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormUpdateCompanyDetails from './FormUpdateCompanyDetails';
	import {pageHelper} from '@/utils/pageHelper';
	import {Api} from '@/utils/api';

	export default {
		name: 'PageCompanyDetails',
		components: {FormUpdateCompanyDetails, BaseGrid},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			}
		},
		methods: {
			confirmDeleteCompany(companyId, companyName) {
				this.$confirmDialog({
						title: 'Bedrijf verwijderen',
						content: `Weet je zeker dat je het bedrijf '${companyName}' wilt verwijderen? Opgeslagen Verbeterchecks en bijbehorende vragen zullen ook worden verwijderd.`,
						confirmButton: 'Verwijderen',
						dialogType: 'warning'
					})
					.then(() => {
						this.deleteCompany(companyId);
					});
			},
			deleteCompany(companyId) {
				Api.deleteCompany(companyId)
					.then(() => {
						this.$router.push({name: pageHelper.PAGE_MY_COMPANIES});
					})
					.catch(() => {
						this.$alertDialog({
							title: 'Bedijf verwijderen mislukt',
							content: `Bedrijf verwijderen is niet gelukt.`,
							dialogType: 'warning'
						});
					});
			}
		}
	};
</script>
