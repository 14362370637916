<template>
	<p class="block-header-last-edited">
		<slot>Laatste wijziging op <strong>{{ date }}</strong> door <strong>{{ name }}</strong></slot>
	</p>
</template>

<script>
	export default {
		props: {
			date: {
				type: String,
				default: '.....'
			},
			name: {
				type: String,
				default: '.....'
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import 'src/assets/variables';
	@import 'src/assets/mixins';

	.block-header-last-edited {
		background: #ffffff;
		border-left: 2px solid #fadc41;
		font-size: 12px;
		color: #666666;
		line-height: 16px;
		padding: 4px 10px;
		margin: 0 0 18px;

		@media #{$mobile} {
			margin-bottom: 8px;
		}
	}
</style>
