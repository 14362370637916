export const physicalScriptKey = Object.freeze({
	education: 'physical_strain_script_education_questions',
	actionsQuestion: 'physical_strain_script_actions_questions',
	liftAboveKnee: 'physical_strain_script_lift_above_knee_questions',
	liftBelowKnee: 'physical_strain_script_lift_below_knee_questions',
	liftAboveShoulder: 'physical_strain_script_lift_above_shoulder_questions',
	sittingWork: 'physical_strain_script_sitting_work',
	pregnancy: 'physical_strain_script_pregnancy'
});

export const physicalScriptInformationPageKey = Object.freeze({
	actions: 'physical_strain_script_actions_information',
	education: 'physical_strain_script_education_information',
	liftAboveKnee: 'physical_strain_script_lift_above_knee_information',
	liftBelowKnee: 'physical_strain_script_lift_below_knee_information',
	liftAboveShoulder: 'physical_strain_script_lift_above_shoulder_information',
	carry: 'physical_strain_script_carry_information',
	pushAndPull: 'physical_strain_script_push_and_pull_information',
	strenuousPosture: 'physical_strain_script_strenuous_posture_information',
	repetitiveMovement: 'physical_strain_script_repetitive_movement_information',
	sittingWork: 'physical_strain_script_sitting_work_information',
	pregnancy: 'physical_strain_script_pregnancy_information'
});

export const getGenericScriptTitle = (scriptKey) => {
	if (scriptKey === physicalScriptKey.pregnancy) {
		return 'Zwangere medewerkers';
	}

	if (scriptKey === physicalScriptKey.sittingWork) {
		return 'Kantoormedewerkers';
	}

	return null;
};

export const isLiftingScript = scriptKey =>
	[
		physicalScriptKey.liftAboveKnee,
		physicalScriptKey.liftBelowKnee,
		physicalScriptKey.liftAboveShoulder
	].includes(scriptKey);
