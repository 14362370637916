<template>
	<card-list-item :router-link-to="detailPageRouterObject"
	                read-more="Bekijk bezoekverslag">

		<visit-report-overview :visit-report="visitReport" compact-mode />

	</card-list-item>
</template>

<script>
	import {getThemeTitle} from '../../utils/themeHelper';
	import {getFormattedDate} from '../../utils/date';
	import {pageHelper} from '../../utils/pageHelper';
	import CardListItem from '../generic/CardListItem';
	import VisitReportOverview from './VisitReportOverview';

	export default {
		name: 'VisitReportListItem',
		components: {VisitReportOverview, CardListItem},
		props: {
			visitReport: {
				type: Object,
				required: true
			}
		},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			},
			detailPageRouterObject() {
				return {
					name: pageHelper.PAGE_COMPANY_VISIT_REPORT_DETAIL,
					params: {
						companyId: this.companyId,
						visitReportId: this.visitReport.id
					}
				};
			}
		},
		methods: {
			getFormattedDate,
			getThemeTitle
		}
	};
</script>
