<template>
	<div class="col-submenu">
		<h2 class="submenu-header">Mijn 5xbeter</h2>
		<ul class="submenu">
			<li>
				<router-link :to="{name: pageHelper.PAGE_LOGIN}">
					Inloggen
				</router-link>
			</li>
			<li>
				<router-link :to="{name: pageHelper.PAGE_FORGOT_PASSWORD}">
					Wachtwoord vergeten
				</router-link>
			</li>
			<li>
				<router-link :to="{name: pageHelper.PAGE_REGISTER}">
					Aanmelden
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';

	export default {
		name: 'SidebarLogin',
		computed: {
			pageHelper() {
				return pageHelper;
			}
		}
	};
</script>
