<template>
    <vuelidate-form :is-submitting="isSubmitting"
                    :vuelidate="v$"
                    :warning-message="warningMessage"
                    @cancel="$emit('cancel')"
                    @validSubmit="handleSubmit"
                    cancel-button-text="Annuleren"
                    class="content-form content-form--edit-action"
                    submit-button-text="Advies opslaan">

        <form-checkbox-list :options="themeOptions"
                            :validation="v$.form.themes"
                            label="Thema's"
                            multicolumn
                            option-label="title"
                            option-value="enum"
                            required
                            v-model="v$.form.themes.$model" />

        <form-textarea :validation="v$.form.reason"
                       label="Constatering / aanleiding"
                       required
                       v-model.trim="v$.form.reason.$model" />

        <form-textarea :validation="v$.form.description"
                       label="Advies"
                       required
                       v-model.trim="v$.form.description.$model" />

        <form-textarea label="Benodigde beheersmaatregel"
                       v-model.trim="form.necessaryMeasure" />

        <form-textarea :validation="v$.form.agreements"
                       label="Gemaakte afspraken"
                       required
                       v-model.trim="v$.form.agreements.$model" />

    </vuelidate-form>

</template>

<script>
    import {Api} from '@/utils/api';
    import VuelidateForm from '../forms/VuelidateForm';
    import {themes} from '@/utils/themeHelper';
    import FormTextarea from '../forms/FormTextarea';
    import {getToday} from '@/utils/date';
    import FormCheckboxList from '../forms/FormCheckboxList';
    import {pageHelper} from '@/utils/pageHelper';
    import {required} from '@vuelidate/validators';
	import {useVuelidate} from '@vuelidate/core';


    export default {
        name: 'FormAdvice',
        components: {FormCheckboxList, FormTextarea, VuelidateForm},
        props: {
            advice: {
                type: Object,
                default: () => ({})
            },
            newAdvice: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isSubmitting: false,
                warningMessage: '',
                form: {
                    themes: [],
                    reason: null,
                    necessaryMeasure: null,
                    description: null,
                    agreements: null
                }
            };
        },
	    setup() {
		    return {
			    v$: useVuelidate()
		    };
	    },
        validations: {
            form: {
                themes: {
                    required
                },
                reason: {
                    required
                },
                description: {
                    required
                },
                agreements: {
                    required
                }
            }
        },
        computed: {
            themeOptions() {
                return themes;
            }
        },
        methods: {
            async handleSubmit() {
                this.isSubmitting = true;
                this.warningMessage = '';
                await this.doApiCall()
                    .then(() => {
                        this.$emit('submitted');
                    })
                    .catch((error) => {
                        if (error.message && error.message.length > 0) {
                            this.warningMessage = error.message;
                            return;
                        }
                        this.warningMessage = 'Advies kon niet worden opgeslagen';
                    }).finally(() => {
                        this.isSubmitting = false;
                    });
            },
            initFormObject() {
                let adviceNew = {};

                if (this.newAdvice) {
                    adviceNew = {
                        themes: [],
                        companyId: this.$route.params.companyId,
                        createdBy: {
                            name: this.$store.state.user.fullName
                        },
                        dateAppointment: getToday()
                    };
                }

                this.form = {...this.form, ...this.advice, ...adviceNew};
            },
            doApiCall() {
                if (this.newAdvice) {
                    return Api.postAddAdvice(this.$route.params.companyId, this.form)
                        .then((response) => {
                            if (response.responseObject.id) {
                                this.$router.push({
                                    name: pageHelper.PAGE_COMPANY_ADVICE_DETAIL,
                                    params: {
                                        adviceId: response.responseObject.id
                                    }
                                });
                            }
                        });
                }
                return Api.postUpdateAdvice(this.$route.params.companyId, this.advice.id, this.form);
            }
        },
        mounted() {
            this.initFormObject();
        }
    };
</script>
