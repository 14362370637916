<template>
	<base-grid page-title="Geen toegang" sidebar="account">
		<p>Je hebt niet de juiste rechten om de gewenste pagina te bezoeken.</p>
	</base-grid>
</template>
<script>
	import BaseGrid from './decorations/BaseGrid';

	export default {
		components: {BaseGrid}
	};
</script>
