<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                cancel-button-text="Annuleren"
	                class="content-form content-form--edit-action"
	                submit-button-text="Beoordeling opslaan"
	                @cancel="$emit('cancel')"
	                @validSubmit="handleSubmit">

		<div>
			<label>Cijfer</label>
			<div class="rating">
				<label v-for="i in 5"
				       :key="i"
				       :aria-label="`rating ${i} van de 5`"
				       :class="form.rating >= i ? 'active' : ''"
				       class="rating__star">
					<input v-model="v$.form.rating.$model"
					       :value="`${i}`"
					       name="rating"
					       type="radio" />
					<svg class="bi bi-star-fill"
					     fill="currentColor"
					     height="1em"
					     viewBox="0 0 16 16"
					     width="1em"
					     xmlns="http://www.w3.org/2000/svg">
						<path class="bi-star-fill__fill"
						      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />

						<path class="bi-star-fill__border"
						      d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.523-3.356c.329-.314.158-.888-.283-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767l-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288l1.847-3.658 1.846 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.564.564 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
						      fill-rule="evenodd" />
					</svg>
				</label>
			</div>
		</div>

		<form-textarea v-model.trim="v$.form.comment.$model"
		               :validation="v$.form.comment"
		               fullwidth
		               input-class="input-fullwidth"
		               input-wrapper-class="input-inline"
		               label="Toelichting" />

	</vuelidate-form>
</template>

<script>
	import {required, requiredIf} from '@vuelidate/validators';
	import VuelidateForm from '../forms/VuelidateForm';
	import FormTextarea from '../forms/FormTextarea';
	import {Api} from '@/utils/api';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormVisitReportRating',
		components: {FormTextarea, VuelidateForm},
		props: {
			visitReport: {
				type: Object,
				default: () => ({})
			}
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					rating: null,
					comment: ''
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations() {
			return {
				form: {
					rating: {
						required
					},
					comment: {
						required: requiredIf(form => form.rating < 3)
					}
				}
			};
		},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await Api.postAddOrUpdateVisitReportRating(this.companyId, this.visitReport.id, this.form)
					.then(() => {
						this.$emit('submitted');
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Beoordeling kon niet worden opgeslagen.';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			initFormObject() {
				if (this.visitReport.rating) {
					this.form = {...this.visitReport.rating};
				}
			}
		},
		mounted() {
			this.initFormObject();
		}
	};
</script>

<style lang="scss">
	.rating {
		input {
			display: none;
		}

		.rating__star {
			display: inline-block;
			margin-right: .25em;
			margin-left: -.25em;
			padding: .25em;
			font-size: 2em;
			cursor: pointer;

			.bi-star-fill__fill {
				fill: #ffffff;
				transition: fill .05s ease-in-out;
			}

			.bi-star-fill__border {
				fill: #00a0db;
			}

			&.active {
				.bi-star-fill__fill {
					fill: #00a0db;
					transition: fill .15s ease-in-out;
				}
			}
		}
	}
</style>
