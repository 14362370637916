<template>
	<div class="block-container">
		<visit-report-list-item :visit-report="visitReport"
		                        :key="visitReport.id"
		                        v-for="visitReport in sortedVisitReports" />

		<card-block v-if="visitReports.length === 0">Geen bezoekverslagen</card-block>
	</div>
</template>

<script>
	import VisitReportListItem from './VisitReportListItem';
	import {getFormattedDate} from '../../utils/date';
	import {getThemeTitle} from '../../utils/themeHelper';
	import {sortArrayByDate} from '../../utils/array';
	import CardBlock from '../generic/CardBlock';

	export default {
		name: 'VisitReportList',
		components: {CardBlock, VisitReportListItem},
		props: {
			visitReports: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			sortedVisitReports() {
				return sortArrayByDate(this.visitReports, 'dateAppointment');
			}
		},
		methods: {
			getFormattedDate,
			getThemeTitle
		}
	};
</script>
