<template>
	<base-grid page-title="Bedrijfsgegevens" sidebar="admin">
		<form-company-detail />
		<br />
		<router-link :to="{name: pageHelper.PAGE_ADMIN_MIGRATE_IMPROVEMENT_CHECK, query: {from: this.companyId}}"
		             class="button">
			Verbetercheck verplaatsen
		</router-link>
	</base-grid>
</template>

<script>
import BaseGrid from '@/components/decorations/BaseGrid';
import FormCompanyDetail from '@/components/admin/company/FormCompanyDetail';
import {pageHelper} from '@/utils/pageHelper';

export default {
	name: 'PageCompanyDetail',
	components: {BaseGrid, FormCompanyDetail},
	computed: {
		companyId() {
			return this.$route.params.companyId;
		},
		pageHelper() {
			return pageHelper;
		}
	}
};
</script>
