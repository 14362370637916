import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper';
import {pageHelper} from '@/utils/pageHelper';
import {PHYSICAL_STRAIN_STORE} from '@/store/check/physicalstrain/physicalStrainMethods';
import {getGenericScriptTitle} from '@/components/check/physicalstrain/physicalScriptKeys';
import PageResultsPhysical from '@/components/check/physicalstrain/PageResultsPhysical';
import PageResultsPhysicalFunctionGroup from '@/components/check/physicalstrain/PageResultsPhysicalFunctionGroup';
import PagePhysicalAnswersOverview from '@/components/check/physicalstrain/PagePhysicalAnswersOverview';
import store from '@/store';
import {inviteeRole} from '@/types/InviteeRole';
import {personRole} from '@/types/PersonRole';
import {check} from '@/types/Check';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: 'fysieke-belasting',
		component: RouterViewWrapper,
		meta: {
			breadcrumb: 'Resultaten Verbetercheck Fysieke belasting',
			roles: [personRole.ROLE_VIJFXBETER_USER],
			check: check.PHYSICAL_STRAIN,
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			]
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_PHYSICAL_RESULTS,
				component: PageResultsPhysical,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					check: check.PHYSICAL_STRAIN,
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					backLink: {
						label: 'verbeterchecks',
						url: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW
					},
					progressStep: '4'
				}
			},
			{
				path: ':functionGroupIdOrScriptKey',
				component: RouterViewWrapper,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					check: check.PHYSICAL_STRAIN,
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					breadcrumb(route) {
						const param = route.query.functionGroupId ?? route.params.functionGroupIdOrScriptKey;
						const functionGroup = store.getters[PHYSICAL_STRAIN_STORE.GET_FUNCTION_GROUP_BY_ID](param);

						return functionGroup?.name ?? getGenericScriptTitle(param);
					}
				},
				children: [
					{
						path: '',
						name: pageHelper.PAGE_PHYSICAL_RESULTS_FUNCTION_GROUP,
						component: PageResultsPhysicalFunctionGroup,
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							check: check.PHYSICAL_STRAIN,
							inviteeRoles: [
								inviteeRole.ROLE_COACH,
								inviteeRole.ROLE_COLLEAGUE,
								inviteeRole.ROLE_EXTERNAL_ADVISOR,
								inviteeRole.ROLE_COMPANY_OWNER
							],
							progressStep: '4',
							backLink: {
								label: 'resultaatmatrix',
								url: pageHelper.PAGE_PHYSICAL_RESULTS
							}
						},
						props: true
					},
					{
						path: 'antwoorden',
						name: pageHelper.PAGE_PHYSICAL_ANSWERS_OVERVIEW,
						component: PagePhysicalAnswersOverview,
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							check: check.PHYSICAL_STRAIN,
							inviteeRoles: [
								inviteeRole.ROLE_COACH,
								inviteeRole.ROLE_COLLEAGUE,
								inviteeRole.ROLE_EXTERNAL_ADVISOR,
								inviteeRole.ROLE_COMPANY_OWNER
							],
							breadcrumb: 'Antwoorden',
							progressStep: '4',
							backLink: {
								label: 'overzicht',
								url: (route) => {
									const functionGroupId = route.query.functionGroupId;
									return {
										name: pageHelper.PAGE_PHYSICAL_RESULTS_FUNCTION_GROUP,
										params: {'functionGroupIdOrScriptKey': functionGroupId}
									};
								}
							}
						},
						props: true
					}
				]
			}
		]
	}
];
