const setScriptFlow = (state, scriptFlow) => {
	state.scriptFlow = scriptFlow;
};

const setScript = (state, script) => {
	state.scripts[script.scriptKey] = script;
};

const setClientAnswers = (state, answers) => {
	state.clientAnswersPerScript = {
		...state.clientAnswersPerScript,
		[answers.scriptKey]: answers
	};
};

const setFunctionGroups = (state, functionGroups) => {
	state.functionGroups = functionGroups;
};

const clearClientScriptAnswers = (state, scriptKey) => {
	state.clientAnswersPerScript = {
		...state.clientAnswersPerScript,
		[scriptKey]: {}
	};
};

const clearStore = (state) => {
	state.scripts = {};
	state.scriptFlow = null;
	state.clientAnswersPerScript = {};
	state.functionGroups = [];
};

export {
	setScriptFlow,
	setScript,
	setClientAnswers,
	setFunctionGroups,
	clearClientScriptAnswers,
	clearStore
};
