<template>
		<span :class="lowerCaseColor" class="status-icon" :title="statusHoverText">
			<template v-if="(!hideIcon && number) || (hideIcon && !number)">
				<em class="icon-number" v-text="number"></em>
			</template>
			<template v-else>
				<em v-if="color === 'GREEN'" class="icon-check"></em>
				<em v-if="color === 'RED'" class="icon-cross"></em>
				<em v-if="color === 'ORANGE'" class="icon-cross"></em>
			</template>
		</span>
</template>

<script>
	import {checkStatus, isValidCheckStatus} from '@/utils/checkHelper';

	export default {
		name: 'IconStatus',
		props: {
			color: {
				type: String,
				validator: value => isValidCheckStatus(value),
				default: checkStatus.GREY.enum
			},
			number: {
				type: [Number, String],
				default: null
			},
			hideIcon: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			lowerCaseColor() {
				const color = this.color ?? checkStatus.GREY.enum;
				return color.toLocaleLowerCase();
			},

			statusHoverText() {
				if (this.color === checkStatus.GREY.enum) {
					return 'Dit onderdeel is nog niet afgerond';
				}
				if (this.color === checkStatus.RED.enum) {
					return 'Er zijn een of meer risico\'s geïdentificeerd';
				}
				if (this.color === checkStatus.GREEN.enum) {
					return 'Er zijn geen risico\'s naar voren gekomen met betrekking tot dit onderdeel';
				}
				if (this.color === checkStatus.WHITE.enum) {
					return 'Dit onderdeel is niet geselecteerd';
				}
				return null;
			}
		}
	};

</script>
<style scoped>
	.status-icon.white {
		background-color: #ffffff;
	}

</style>
