import {personRole} from '@/types/PersonRole';

export const getPersonRoleFromEnum = (role) => {
	switch (role) {
	case personRole.ROLE_VIJFXBETER_USER:
		return '5xbeter gebruiker';
	case personRole.ROLE_VIJFXBETER_COACH:
		return '5xbeter verbetercoach';
	case personRole.ROLE_VIJFXBETER_ADMIN:
		return '5xbeter beheerder';
	case personRole.ROLE_VIJFXBETER_GEVAARLIJKESTOFFEN_SPECIAL_USER:
		return '5xbeter gevaarlijkestoffen special user';
	case personRole.ROLE_VIJFXBETER_BRONDATABASE:
		return '5xbeter brondatabase';
	default:
		return 'Onbekende rol';
	}
};
