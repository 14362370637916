<template>
	<div>
		<p class="warning-block">{{ message }}</p>

		<skeleton-check />

		<button type="button" class="button-blue" @click="$router.go(-1)">
			Ga terug
		</button>
	</div>
</template>

<script>
	import SkeletonCheck from '@/components/check/SkeletonCheck';

	export default {
		name: 'CheckErrorPage',
		components: {SkeletonCheck},
		props: {
			message: {
				type: String,
				required: true
			}
		}
	};
</script>
