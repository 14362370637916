import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper.vue';
import {pageHelper} from '@/utils/pageHelper';
import PageUsersOverview from '@/components/admin/PageUsersOverview.vue';
import PageUserDetail from '@/components/admin/PageUserDetail.vue';
import PageCompaniesOverview from '@/components/admin/company/PageCompaniesOverview.vue';
import PageMoveImprovementCheck from '@/components/admin/company/PageMigrateImprovementCheck.vue';
import PageCompanyDetail from '@/components/admin/company/PageCompanyDetail.vue';
import {personRole} from '@/types/PersonRole';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '/gebruikersbeheer',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_ADMIN],
			breadcrumb: 'Gebruikersbeheer'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_ADMIN_USERS_OVERVIEW,
				component: PageUsersOverview,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_ADMIN]
				}
			},
			{
				path: ':userId',
				name: pageHelper.PAGE_ADMIN_USER_DETAIL,
				component: PageUserDetail,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_ADMIN],
					breadcrumb: 'Gebruikersgegevens'
				}
			}
		]

	},
	{
		path: '/bedrijvenbeheer',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_ADMIN],
			breadcrumb: 'Bedrijvenbeheer'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_ADMIN_COMPANIES_OVERVIEW,
				component: PageCompaniesOverview,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_ADMIN]
				}
			},
			{
				path: 'verbetercheck-verplaatsen',
				name: pageHelper.PAGE_ADMIN_MIGRATE_IMPROVEMENT_CHECK,
				component: PageMoveImprovementCheck,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_ADMIN],
					breadcrumb: 'Verbetercheck verplaatsen'
				}
			},
			{
				path: ':companyId',
				name: pageHelper.PAGE_ADMIN_COMPANY_DETAIL,
				component: PageCompanyDetail,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_ADMIN],
					breadcrumb: 'Bedrijfsgegevens'
				}
			}
		]
	}
];
