import {findIndexByIdValue} from '@/utils/array';

const shoppingCart = {
	namespaced: true,

	state: () => ({
		items: []
	}),

	mutations: {
		addProduct(state, product) {
			const indexOfProduct = findIndexByIdValue(state.items, product.id);
			if (indexOfProduct === -1) {
				state.items.push(product);
				return;
			}

			state.items[indexOfProduct].quantity = product.quantity;
		},

		removeProduct(state, id) {
			state.items = state.items.filter(item => item.id !== id);
		},

		removeAllProducts(state) {
			state.items = [];
		},

		updateQuantityOfProduct(state, product) {
			const indexOfProduct = findIndexByIdValue(state.items, product.id);
			state.items[indexOfProduct].quantity = product.quantity;
		}
	}
};

export default shoppingCart;
