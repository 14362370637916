<template>
	<div class="notice-bar" :class="cssClass">
		<slot></slot>
	</div>
</template>
<script>
	export default {
		name: 'NoticeBar',
		props: {
			cssClass: {
				type: String
			}
		}
	};
</script>
