<template>
	<p v-if="loading">
		Gegevens worden opgehaald
	</p>

	<p v-else-if="loadingFailed && warningMessage">
		{{ warningMessage }}
	</p>

	<vuelidate-form v-else
	                :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="formWarningMessage"
	                class="content-form"
	                submit-button-text="Opslaan"
	                @validSubmit="handleSubmit">
		<div class="block-form">
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input v-model="v$.form.firstName.$model"
					            :validation="v$.form.firstName"
					            label="Voornaam"
					            maxlength="255"
					            required />

					<form-input v-model="v$.form.middleName.$model"
					            :validation="v$.form.middleName"
					            label="Tussenvoegsel"
					            maxlength="255"
					            placeholder="Tussenvoegsel" />

					<form-input v-model="v$.form.lastName.$model"
					            :validation="v$.form.lastName"
					            label="Achternaam"
					            maxlength="255"
					            required />
					<form-input id="emailAddressinput"
					            v-model="v$.form.emailAddress.$model"
					            :validation="v$.form.emailAddress"
					            label="E-mailadres"
					            maxlength="255"
					            required
					            type="email" />

					<label>Rollen</label>
					<div class="checkbox-list">
						<form-checkbox-list :options="allRoles"
						                    :validation="v$.form.roles"
						                    option-label="title"
						                    option-value="enum"
						                    required
						                    v-model="v$.form.roles.$model" />
					</div>

					<div class="checkbox-list" style="max-width: 260px;">
						<div>
							<input id="receiveNewsletter"
							       v-model="form.receivesNewsletter"
							       style="width: auto;"
							       type="checkbox" />
							<label for="receiveNewsletter">Nieuwsbrief ontvangen</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</vuelidate-form>

</template>

<script>
	import VuelidateForm from '../forms/VuelidateForm';
	import {Api} from '@/utils/api';
	import {email, maxLength, required} from '@vuelidate/validators';
	import {pageHelper} from '@/utils/pageHelper';
	import FormInput from '../forms/FormInput';
	import {getPersonRoleFromEnum} from '@/utils/personRoleHelper';
	import {personRole} from '@/types/PersonRole';
	import {useVuelidate} from '@vuelidate/core';
	import FormCheckboxList from '@/components/forms/FormCheckboxList.vue';

	export default {
		name: 'FormUserDetail',
		components: {FormCheckboxList, VuelidateForm, FormInput},
		data() {
			return {
				loading: true,
				loadingFailed: false,
				isSubmitting: false,
				warningMessage: '',
				formWarningMessage: '',
				form: {
					firstName: '',
					middleName: '',
					lastName: '',
					emailAddress: '',
					receivesNewsletter: '',
					roles: ''
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations: {
			form: {
				firstName: {
					required,
					maxLength: maxLength(255)
				},
				middleName: {
					maxLength: maxLength(255)
				},
				lastName: {
					required,
					maxLength: maxLength(255)
				},
				emailAddress: {
					required,
					email
				},
				roles: {
				}
			}
		},
		computed: {
			allRoles() {
				return Object.values(personRole).map(role => ({
						title: getPersonRoleFromEnum(role),
						enum: role
					}));
			},
			userId() {
				return this.$route.params.userId;
			}
		},
		methods: {
			getPersonRoleFromEnum,
			async handleSubmit() {
				this.isSubmitting = true;
				this.formWarningMessage = '';

				await Api.postUpdateUserByAdmin(this.userId, this.form)
					.then(() => {
						this.$router.push({
							name: pageHelper.PAGE_ADMIN_USERS_OVERVIEW
						});
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.formWarningMessage = error.message;
							return;
						}
						this.formWarningMessage = 'Het is niet gelukt de gebruikersgegevens aan te passen';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			async getAccountData() {
				this.loading = true;

				await Api.getUserDetailForAdmin(this.userId).then((response) => {
					this.form = response.responseObject;
				}).catch(() => {
					this.warningMessage = 'Gegevens ophalen is niet gelukt';
					this.loadingFailed = true;
				}).finally(() => {
					this.loading = false;
				});
			}
		},
		mounted() {
			this.getAccountData();
		}
	};
</script>
