<template>
	<div>
		<label v-if="label && !hideLabel">
			{{label}}:
			<span v-if="required">*</span>
		</label>
		<div class="checkbox-list" :class="multicolumn ? 'checkbox-list--multicolumn' : ''">
			<checkbox-list-item v-for="option in options"
			                    :key="option[optionValue]"
			                    :label="option[optionLabel]"
			                    :value="option[optionValue]"
			                    :modelValue="modelValue.includes(option[optionValue])"
			                    :name="`checkboxlist${listId}`"
			                    @change="emitNewValue" />
		</div>

		<form-warning-block v-if="validation && validation.$error" :message="validationMessage" ></form-warning-block>
	</div>
</template>

<script>

	import CheckboxListItem from './CheckboxListItem';
	import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
	import FormWarningBlock from './FormWarningBlock';
	import {createUniqueId} from '@/utils/uid';

	export default {
		name: 'FormCheckboxList',
		components: {FormWarningBlock, CheckboxListItem},
		props: {
			label: {
				type: String
			},
			hideLabel: {
				type: Boolean,
				default: false
			},
			required: {
				type: Boolean,
				default: false
			},
			options: {
				type: [Array, Object],
				required: true
			},
			optionLabel: {
				type: String,
				required: true
			},
			optionValue: {
				type: String,
				required: true
			},
			modelValue: {
				type: Array,
				default: () => []
			},
			id: {
				type: String
			},
			multicolumn: {
				type: Boolean,
				default: false
			},
			validation: {
				type: Object
			}
		},
		data() {
			return {
				value: this.modelValue,
				uid: createUniqueId()
			};
		},
		computed: {
			listId() {
				return this.id ?? `input-id-${this.uid}`;
			},
			validationMessage() {
				const validationObject = this.validation;
				if (validationObject === null) {
					return null;
				}
				return getFirstInvalidValidationMessage(validationObject);
			}
		},
		methods: {
			emitNewValue(newValue) {
				const newArray = [... new Set([...this.value, newValue.target.value])];
				this.value = newArray.filter(optionValue => optionValue !== newValue.target.value || newValue.target.checked);
				this.$emit('update:modelValue', this.value);
			}
		},
		watch: {
			modelValue(newValue) {
				this.value = newValue;
			}
		}
	};
</script>

<style lang="scss">
	.checkbox-list.checkbox-list--multicolumn {
		columns: 260px auto;
		column-gap: 20px;

		& > * {
			break-inside: avoid;
		}
	}

	.checkbox-list + .warning-block {
		margin-top: 0;
	}
</style>
