<template>
	<div class="header-white">
		<div class="header-container">
			<ul class="header-breadcrumb">
				<li>
					<router-link to="/">{{ homeTitle }}</router-link>
					<span class="separator" v-if="items.length > 0"></span>
				</li>
				<li v-for="(item, index) in items" :key="index">
					<template v-if="(index + 1) === items.length">
						{{ item.text }}
					</template>
					<template v-else>
						<router-link :to="item.to">{{ item.text }}</router-link>
						<span class="separator"></span>
					</template>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'BreadCrumbs',
		props: {
			items: {
				type: Array,
				default: () => []
			}
		},

		data() {
			return {
				homeTitle: process.env.VUE_APP_TITLE
			};
		},

		watch: {
			items: {
				immediate: true,
				handler(items) {
					const title = items.map(item => item.text).join(' - ');
					document.title = `5xbeter - ${title.length > 0 ? title : 'Mijn omgeving'}`;
				}
			}
		}
	};
</script>
