<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<skeleton-card-item v-if="loading"
		                    :lines="17"
		                    :lines-width="[14, 30, 0]"
		                    title="Actiepunt wordt geladen" />

		<card-block v-if="!loading && action !== null">
			<template v-if="!editMode">
				<h4>Thema's</h4>
				<p>{{ getCombinedThemeTitles(action.themes) }}</p>

				<h4>Actiepunt</h4>
				<p :style="descriptionStyle">
					{{ getTextOrFallback(action.description) }}
				</p>

				<h4>Deadline</h4>
				<p>{{ getTextOrFallback(getFormattedDate(action.deadline)) }}</p>

				<h4>Actiehouder</h4>
				<p>{{ getTextOrFallback(action.actionHolder) }}</p>

				<h4>Aangemaakt op</h4>
				<p>{{ getFormattedDate(action.createdDate) }}</p>

				<h4>Aangemaakt door</h4>
				<p>{{ action.createdBy.name }}</p>

				<h4>Afgerond</h4>
				<p>{{ action.completed ? 'Ja' : 'Nee' }}</p>

				<template v-if="userCanEditActionItem && !this.action.completed">
					<button class="button"
					        style="margin-right: 10px"
					        type="button"
					        @click="editMode = !editMode">
						Actiepunt bewerken
					</button>

					<button class="button"
					        type="button"
					        @click="confirmCompleteActionItem">
						Actiepunt afronden
					</button>
				</template>

			</template>

			<template v-if="editMode">
				<form-action :action="action"
				             @cancel="editMode = false"
				             @submitted="updatePage" />
			</template>

		</card-block>

	</base-grid>
</template>

<script>
	import {Api} from '@/utils/api';
	import BaseGrid from '../decorations/BaseGrid';
	import {getFormattedDate} from '@/utils/date';
	import {getCombinedThemeTitles} from '@/utils/themeHelper';
	import {getTextOrFallback} from '@/utils/text';
	import CardBlock from '../generic/CardBlock';
	import SkeletonCardItem from '../generic/SkeletonCardItem';
	import FormAction from '@/components/actionitem/FormAction';

	export default {
		name: 'PageActionDetail',
		components: {SkeletonCardItem, CardBlock, FormAction, BaseGrid},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			actionId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				action: null,
				editMode: false,
				descriptionStyle: {
					'white-space': 'pre-line'
				}
			};
		},
		computed: {
			pageTitle() {
				return `Actiepunt voor ${this.$store.state.company.current.name}`;
			},
			isUserCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			isUserCompanyAdmin() {
				return this.$store.getters.isUserCompanyAdmin(this.companyId);
			},
			isCoachAndCoachInvited() {
				return this.$store.getters.isUserInvitedAsCoachByCompany(this.companyId) && this.$store.getters.userHasRoleCoach();
			},
			userIsCreator() {
				return this.$store.getters.userHasId(this.action.createdBy.id);
			},
			userCanEditActionItem() {
				return this.isUserCompanyOwner
					|| ((this.isUserCompanyAdmin || this.isCoachAndCoachInvited) && this.userIsCreator);
			}
		},
		methods: {
			confirmCompleteActionItem() {
				this.$confirmDialog({
						title: 'Actiepunt afronden',
						content: `Weet je zeker dat je het actiepunt wilt afronden? Het actiepunt kan daarna niet meer worden bewerkt.`,
						confirmButton: 'Afronden',
						dialogType: 'warning'
					})
					.then(async () => await this.completeActionItem());
			},
			async completeActionItem() {
				this.action.completed = true;
				await Api.postUpdateAction(this.companyId, this.actionId, this.action)
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
						}
					});
			},
			async loadActionById() {
				await Api.getAction(this.companyId, this.actionId)
					.then(response => this.action = response.responseObject)
					.catch(() => this.warningMessage = 'Actiepunt kon niet geladen worden')
					.finally(() => this.loading = false);
			},
			initPage() {
				this.loading = true;
				this.loadActionById();
			},
			resetPage() {
				this.editMode = false;
				this.action = null;
			},
			updatePage() {
				this.resetPage();
				this.initPage();
			},
			getCombinedThemeTitles,
			getFormattedDate,
			getTextOrFallback
		},
		watch: {
			$route() {
				this.updatePage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
