export const createUniqueId = () => {
	const crypto = globalThis?.crypto;
	if (crypto && typeof crypto.randomUUID === 'function') {
		return crypto.randomUUID();
	}

	return 'xxxx-xxxx-xxx-xxxx'.replace(/x/g, () => {
		const randomNumber = Math.floor(Math.random() * 16);
		return randomNumber.toString(16);
	});
};
