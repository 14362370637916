export const accountFunctionOptions = Object.freeze([
	'Arboprofessional',
	'Personeelsvertegenwoordiger',
	'Werkgever/manager',
	'Werknemer',
	'Werkzaam in andere functie'
]);

export const accountBranchOptions = Object.freeze([
	'Bouwbedrijven',
	'Chemie en kunststof Industrie',
	'Detailhandel',
	'Dienstverlening',
	'Groothandel',
	'Horeca',
	'Hout- en meubelindustrie',
	'Installatie-/isolatiebedrijven',
	'Landbouw, veeteelt en visserij',
	'Metaalbewerking',
	'Metalektro',
	'Motorvoertuigen, garages en tankstations',
	'Overige industrie',
	'Papier en karton',
	'Publieke sector/overheid',
	'Schilders-, afbouw- en onderhoudsbedrijven',
	'Transportmiddelen',
	'Vervoer en opslag',
	'Voedingsmiddelenindustrie',
	'Weg- en waterbouw',
	'Zorg',
	'Anders'
]);

export const branchIsOther = branch => branch === 'Anders';

export const branchNotFromList = branch => accountBranchOptions.indexOf(branch) === -1;
