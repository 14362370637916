export const pageHelper = Object.freeze({
	PAGE_LOGIN: 'Login',
	PAGE_FORGOT_PASSWORD: 'ForgotPassword',
	PAGE_RESET_PASSWORD: 'ResetPassword',
	PAGE_UPDATE_PASSWORD: 'UpdatePassword',
	PAGE_MY_ACCOUNT: 'MyAccount',
	PAGE_MY_ORDERS: 'MyOrders',
	PAGE_MY_COMPANIES: 'MyCompanies',
	PAGE_ADD_COMPANY: 'AddCompany',
	PAGE_COMPANY_ADVICE_OVERVIEW: 'CompanyAdvices',
	PAGE_COMPANY_ADVICE_DETAIL: 'CompanyAdviceDetail',
	PAGE_COMPANY_ACTION_OVERVIEW: 'CompanyActions',
	PAGE_COMPANY_ACTION_DETAIL: 'CompanyActionsDetail',
	PAGE_COMPANY_DETAILS: 'CompanyDetails',
	PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW: 'CompanyImprovementCheckOverview',
	PAGE_COMPANY_RECORDS_NEW: 'CompanyRecordsNew',
	PAGE_COMPANY_RECORDS_EDIT: 'CompanyRecordsEdit',
	PAGE_COMPANY_RECORDS_OVERVIEW: 'CompanyRecordsOverview',
	PAGE_COMPANY_VISIT_REPORT_OVERVIEW: 'VisitReportOverview',
	PAGE_COMPANY_VISIT_REPORT_DETAIL: 'VisitReportDetail',
	PAGE_COMPANY_VISIT_REPORT_NEW: 'VisitReportNew',
	PAGE_COMPANY_IMPROVE: 'CompanyImprove',
	PAGE_ADMIN_USERS_OVERVIEW: 'UsersOverview',
	PAGE_ADMIN_COMPANIES_OVERVIEW: 'CompaniesOverview',
	PAGE_SHOPPING_CART_OVERVIEW: 'ShoppingCartOverview',
	PAGE_SHOPPING_CART_CHECKOUT: 'ShoppingCartCheckout',
	PAGE_ADMIN_USER_DETAIL: 'UserDetail',
	PAGE_ADMIN_COMPANY_DETAIL: 'CompanyDetail',
	PAGE_ADMIN_MIGRATE_IMPROVEMENT_CHECK: 'MoveImprovementCheck',
	PAGE_NO_RIGHTS: 'PageNoRights',
	PAGE_REGISTER: 'PageRegister',
	PAGE_CHECK_START: 'PageCheckStart',
	PAGE_CHECK_PHYSICAL_STRAIN: 'PageCheckPhysicalStrain',
	PAGE_CHECK_PHYSICAL_END_OF_FUNCTION_GROUP: 'CheckPhysicalEndOfFunctionGroup',
	PAGE_CHECK_PHYSICAL_END: 'CheckPhysicalEnd',
	PAGE_PHYSICAL_RESULTS: 'PagePhysicalResults',
	PAGE_PHYSICAL_RESULTS_FUNCTION_GROUP: 'PagePhysicalResultsFunctionGroup',
	PAGE_PHYSICAL_FUNCTION_GROUP: 'PageFunctionGroup',
	PAGE_PHYSICAL_ADD_EDIT_FUNCTION_GROUP: 'PageAddEditFunctionGroup',
	PAGE_PHYSICAL_ANSWERS_OVERVIEW: 'PagePhysicalAnswersOverview'
});
