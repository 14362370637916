import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import addAdvice from '@/store/modals/addAdvice';
import addAction from '@/store/modals/addAction';
import user from '@/store/user';
import company from '@/store/company';
import shoppingCart from '@/store/shoppingCart';
import physicalStrain from '@/store/check/physicalstrain/physicalStrain';
import {getCookie, setCookie} from '@/utils/cookie';
import checkStore from '@/store/check/checkStore';

// TODO Manier verzinnen om te voorkomen dat test/acceptatie omgeving zelfde cookie uitlezen.
const COOKIE_NAME_SHARED_STORAGE = 'vuex-mijn5xbeter-shared';

const store = createStore({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		company,
		modals: {
			namespaced: true,
			modules: {
				addAction,
				addAdvice
			}
		},
		shoppingCart,
		user,
		checkStore,
		physicalStrain
	},
	plugins: [
		createPersistedState({
			key: 'vuex-mijn5xbeter',
			paths: ['user', 'company', 'shoppingCart', 'checkStore', 'physicalStrain'],
			getState: (key, storage) => {
				// Some data is shared between the lavic and the toolbox application by a cookie.
				let storageValue = {};
				let cookieValue = {};

				try {
					storageValue = storage.getItem(key);
					cookieValue = getCookie(COOKIE_NAME_SHARED_STORAGE);

					if (typeof storageValue === 'undefined' && cookieValue === null) {
						return 'undefined';
					}

					storageValue = storageValue !== 'undefined' ? JSON.parse(storageValue) : {};
					cookieValue = cookieValue !== null ? JSON.parse(cookieValue) : {};

					return {
						...storageValue,
						...cookieValue
					};
				} catch (err) {
					// Do nothing, just continue
				}

				return undefined;
			},
			setState: (key, state, storage) => {
				// Some data is shared between the lavic and the toolbox application by a cookie.
				const objectForSharedStorage = {
					user: {
						...state.user
					},
					shoppingCart: {
						...state.shoppingCart
					}
				};

				setCookie(COOKIE_NAME_SHARED_STORAGE, JSON.stringify(objectForSharedStorage), {
					days: 1,
					domain: process.env.VUE_APP_COOKIE_DOMAIN || ''
				});
				return storage.setItem(key, JSON.stringify(state));
			}
		})
	]
});

export default store;
