<template>
	<base-grid page-title="Bedrijfsdossier item bewerken" sidebar="company">
		<p v-if="warningMessage">{{ warningMessage }}</p>
		<skeleton-content-form v-if="loading" :columns="1" :rows="3" />
		<card-block v-if="!loading && !warningMessage">
			<form-company-record :company-id="companyId"
			                     :company-record="localCompanyRecord"
			                     @cancel="goToOverview"
			                     @submitted="goToOverview" />

		</card-block>
	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {pageHelper} from '@/utils/pageHelper';
	import CardBlock from '@/components/generic/CardBlock';
	import FormCompanyRecord from '@/components/companyrecords/FormCompanyRecord';
	import SkeletonContentForm from '@/components/generic/SkeletonContentForm';
	import {Api} from '@/utils/api';

	export default {
		name: 'PageEditCompanyRecord',
		components: {
			SkeletonContentForm,
			FormCompanyRecord,
			CardBlock,
			BaseGrid
		},
		data() {
			return {
				loading: true,
				warningMessage: '',
				localCompanyRecord: {}
			};
		},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			companyRecordId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			companyRecord: {
				type: Object,
				default: () => ({})
			}
		},

		watch: {
			companyRecordId() {
				this.setCompanyRecordData();
			}
		},

		methods: {
			goToOverview() {
				this.$router.push({
					name: pageHelper.PAGE_COMPANY_RECORDS_OVERVIEW,
					params: {
						companyId: this.companyId
					}
				});
			},

			async loadCompanyRecord() {
				await Api.getCompanyRecord(this.companyId, this.companyRecordId)
					.then((response) => {
						this.localCompanyRecord = response.responseObject;
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Bedrijfsdossier item kon niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},

			async setCompanyRecordData() {
				this.loading = true;
				this.localCompanyRecord = {};
				if (this.companyRecordId !== this.companyRecord.id) {
					await this.loadCompanyRecord();
					return;
				}

				this.localCompanyRecord = this.companyRecord;
				this.loading = false;
			}
		},
		mounted() {
			this.setCompanyRecordData();
		}
	};
</script>
