<template>
	<div class="row">
		<div class="col-12">
			<block-page-header>Bestellijst</block-page-header>
		</div>

		<div class="col-8 col-sm-12">
			<order-list :items="shoppingCartItems"
			            no-products-text="Je hebt geen items in de winkelwagen" />

			<p class="text-grey">Alle artikelen op 5xbeter zijn gratis te bestellen en worden gratis bezorgd.</p>

			<div v-if="userIsLoggedIn" class="button-bar">
				<router-link :to="checkoutPage"
				             class="button button--primary">
					Ga verder met bestellen
				</router-link>
			</div>

			<template v-if="!userIsLoggedIn">

				<block-page-header>Bestellen</block-page-header>

				<div class="row">
					<div class="col-6 col-sm-12">
						<div class="block-content-blue order-list_login">
							<h3><strong>Bestaande gebruiker</strong></h3>
							<form-login :redirect="checkoutPage"
							            submit-button-text="Log in en ga verder" />
						</div>
					</div>

					<div class="col-6 col-ms-12">
						<div class="block-content-white order-list_login">
							<h3><strong>Nieuwe gebruiker</strong></h3>
							<p>
								Om producten te bestellen is een Mijn&nbsp;5xbeter account nodig.
							</p>

							<a class="login-form-signup button js-fancybox fancybox.ajax"
							   href="/mijn5xbeter/registratie/start.web">
								Registreren
							</a>
						</div>
					</div>
				</div>

			</template>

		</div>

		<div class="col-4 col-sm-12">
			<div class="order-list_more">
				<strong class="text-grey">Wil je meer items toevoegen?</strong>
				<ul>
					<li>
						<a href="/site/nl/themas/fysieke-belasting/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Fysieke belasting
						</a>
					</li>
					<li>
						<a href="/site/nl/themas/gevaarlijke-stoffen/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Gevaarlijke stoffen
						</a>
					</li>
					<li>
						<a href="/site/nl/themas/lasrook/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Lasrook
						</a>
					</li>
					<li>
						<a href="/site/nl/themas/machineveiligheid/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Machineveiligheid
						</a>
					</li>
					<li>
						<a href="/site/nl/themas/schadelijk-geluid/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Schadelijk geluid
						</a>
					</li>
					<li>
						<a href="/site/nl/themas/valgevaar/hulpmiddelen">
							<i class="icon-chevron-right"></i>Hulpmiddelen Tijdelijk werken op hoogte
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import BlockPageHeader from '@/components/decorations/BlockPageHeader';
	import OrderList from '@/components/orders/OrderList';
	import {pageHelper} from '@/utils/pageHelper';

	export default {
		name: 'PageShoppingCartOverview',
		components: {OrderList, BlockPageHeader},
		computed: {
			shoppingCartItems() {
				return this.$store.state.shoppingCart.items;
			},
			userIsLoggedIn() {
				return this.$store.state.user.isLoggedIn;
			},
			checkoutPage() {
				return {
					name: pageHelper.PAGE_SHOPPING_CART_CHECKOUT
				};
			}
		}
	};
</script>

<style lang="scss">
	.order-list_login {
		.header-login-form {
			padding: 0;
			position: relative;
		}
	}
</style>
