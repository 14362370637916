<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<skeleton-card-item v-if="loading"
		                    :lines="17"
		                    :lines-width="[14, 30, 0]"
		                    title="Advies wordt geladen" />

		<card-block v-if="!loading && advice !== null" class="advice-detail">
			<template v-if="!editMode && !openFormArchive">
				<h4>Thema's</h4>
				<p>{{ getCombinedThemeTitles(advice.themes) }}</p>

				<h4>Constatering / aanleiding</h4>
				<p>{{ getTextOrFallback(advice.reason) }}</p>

				<h4>Advies</h4>
				<p>{{ getTextOrFallback(advice.description) }}</p>

				<h4>Benodigde beheersmaatregel</h4>
				<p>{{ getTextOrFallback(advice.necessaryMeasure) }}</p>

				<h4>Gemaakte afspraken</h4>
				<p>{{ getTextOrFallback(advice.agreements) }}</p>

				<h4>Aangemaakt op</h4>
				<p>{{ getFormattedDate(advice.createdDate) }}</p>

				<h4>Aangemaakt door <strong v-if="advice.createdByCoach">Verbetercoach</strong></h4>
				<p>{{ advice.createdBy.name }}</p>

				<div class="button-bar">
					<button v-if="userCanEdit"
					        class="button"
					        type="button"
					        @click="editMode = !editMode">
						Advies bewerken
					</button>

					<button v-if="userCanCreateActionItem"
					        class="button"
					        type="button"
					        @click.prevent="openAddActionModal">
						Actiepunt maken voor advies
					</button>

					<button v-if="userCanEdit"
					        class="button button-no-border"
					        type="button" @click="archivingAdvice">
						{{ getArchiveButtonTitle() }}
					</button>
				</div>

			</template>

			<template v-if="editMode">
				<form-advice :advice="advice"
				             @cancel="editMode = false"
				             @submitted="updatePage" />
			</template>

			<template v-if="openFormArchive">
				<form-archive-advice :advice="advice"
				                     @cancel="updatePage"
				                     @submitted="updatePage" />
			</template>
		</card-block>

	</base-grid>
</template>

<script>
	import {Api} from '@/utils/api';
	import BaseGrid from '../decorations/BaseGrid';
	import {getFormattedDate} from '@/utils/date';
	import {getCombinedThemeTitles} from '@/utils/themeHelper';
	import {getTextOrFallback} from '@/utils/text';
	import FormAdvice from '@/components/advice/FormAdvice';
	import FormArchiveAdvice from '@/components/advice/FormArchiveAdvice';
	import CardBlock from '../generic/CardBlock';
	import SkeletonCardItem from '../generic/SkeletonCardItem';

	export default {
		name: 'PageAdviceDetail',
		components: {SkeletonCardItem, CardBlock, FormAdvice, BaseGrid, FormArchiveAdvice},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			adviceId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				advice: null,
				editMode: false,
				openFormArchive: false
			};
		},
		computed: {
			pageTitle() {
				return `Advies voor ${this.$store.state.company.current.name}`;
			},
			userCanEdit() {
				return this.userIsCompanyOwner || this.userIsCompanyAdminAndCreator || this.userIsCoachAndCreator;
			},
			userCanCreateActionItem() {
				return this.userIsCompanyOwner || this.userIsCompanyAdminAndCreator;
			},
			userIsCreator() {
				return this.$store.getters.userHasId(this.advice.createdBy.id);
			},
			userIsCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			userIsCompanyAdmin() {
				return this.$store.getters.isUserCompanyAdmin(this.companyId);
			},
			userIsCoachAndCoachInvited() {
				return this.$store.getters.isUserInvitedAsCoachByCompany(this.companyId) && this.$store.getters.userHasRoleCoach();
			},
			userIsCompanyAdminAndCreator() {
				return this.userIsCompanyAdmin && this.userIsCreator;
			},
			userIsCoachAndCreator() {
				return this.userIsCoachAndCoachInvited && this.userIsCreator;
			}
		},
		methods: {
			getArchiveButtonTitle() {
				return this.advice.archived ? 'De-archiveren' : 'Archiveren';
			},
			archivingAdvice() {
				this.openFormArchive = !this.openFormArchive;

				if (this.advice.archived) {
					this.openFormArchive = false;
					this.updateArchivedStatus();
				}
			},
			async updateArchivedStatus() {
				await Api.postUpdateAdviceArchivedStatus(this.companyId, this.advice.id)
					.then(() => {
						this.openFormArchive = false;
						this.initPage();
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = '(De)-Archiveren van advies is mislukt.';
					});
			},
			async loadAdviceById() {
				await Api.getAdvice(this.companyId, this.adviceId)
					.then((response) => {
						this.advice = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Advies kon niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadAdviceById();
			},
			resetPage() {
				this.editMode = false;
				this.advice = null;
				this.openFormArchive = false;
			},
			updatePage() {
				this.resetPage();
				this.initPage();
			},
			openAddActionModal() {
				this.$store.dispatch('modals/addAction/openDialog', {
					companyId: this.companyId,
					adviceId: this.adviceId
				});
			},
			getCombinedThemeTitles,
			getFormattedDate,
			getTextOrFallback
		},
		watch: {
			$route() {
				this.updatePage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>

<style lang="scss">
	.advice-detail {
		p {
			white-space: pre-line;
		}
	}
</style>
