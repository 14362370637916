<template>
	<div v-if="archivedUrl != null"
	     class="text-grey"
	     style="clear: left; padding-top: .75em;">

		De verbetercheck Lasrook is op 1 juli 2020 vernieuwd. Eerder ingevoerde verbeterchecks moeten opnieuw worden
		ingevuld. De verbeterchecks van voor 1 juli 2020 zijn gearchiveerd.
		<a :href="archivedUrl">Bekijk gearchiveerde ruimtes</a>
	</div>
</template>

<script>
export default {
	name: 'LasrookUpdatedDisclaimer',
	props: {
		archivedUrl: {
			type: String
		}
	}
};
</script>
