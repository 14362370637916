const getDutchLocaleDateString = (dateObject, timeZone = 'Europe/Amsterdam') => dateObject.toLocaleDateString('nl-NL', {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
	timeZone
});

/**
 * Return a date as a formatted date in the dd-mm-yyyy format
 * @param {string} date Can be any date that can be interpreted by Date()
 * @return {string} - dd-mm-yyyy
 */
export const getFormattedDate = (date = '') => {
	if (date === null || date.length === 0) {
		return '';
	}

	const dateObject = new Date(date);
	let dateString = new Date(date).toString();

	if (dateString === 'Invalid Date') {
		return date;
	}

	try {
		dateString = getDutchLocaleDateString(dateObject);
	} catch (e) {
		// IE11 doesnt support the timeZone, so return the 'UTC' locale
		dateString = getDutchLocaleDateString(dateObject, 'UTC');
	}

	return dateString;
};

export const getDateWithoutTime = (date) => {
	const dateObject = new Date(date);
	return new Date(dateObject.getTime() - (dateObject.getTimezoneOffset() * 60000))
		.toISOString()
		.split('T')[0];
};

/**
 * Returns the current Date as an string in yyyy-mm-dd
 * based on {@link https://stackoverflow.com/a/50130338 | this stackoverflow post}
 * @return {string} - yyyy-mm-dd
 */
export const getToday = () => {
	let date = new Date();
	date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
	return getDateWithoutTime(date);
};
