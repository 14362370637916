<template>
	<base-grid page-title="Bestelling afronden">

		<template v-if="!showSuccessMessage">
			<form-checkout v-if="shoppingCartItems.length > 0"
			               @success="handleSucces" />

			<p v-if="shoppingCartItems.length === 0">
				Geen producten toegevoegd.
			</p>
		</template>

		<div v-if="showSuccessMessage">
			<div class="block-form-title">
				<h2>Bedankt voor je bestelling</h2>
			</div>

			<div class="block-form">
				<p>Je bestelling wordt zo spoedig mogelijk verwerkt.</p>

				<div>
					<h2>Zojuist besteld</h2>
					<order-list :items="ordered" readonly />
				</div>
			</div>
		</div>
	</base-grid>
</template>
<script>
	import BaseGrid from '@/components/decorations/BaseGrid';
	import FormCheckout from '@/components/orders/FormCheckout';
	import OrderList from '@/components/orders/OrderList';

	export default {
		name: 'PageShoppingCartCheckout',
		components: {OrderList, FormCheckout, BaseGrid},
		data() {
			return {
				showSuccessMessage: false,
				ordered: [],
				form: {
					company: null
				}
			};
		},
		computed: {
			shoppingCartItems() {
				return this.$store.state.shoppingCart.items;
			}
		},
		methods: {
			handleSucces() {
				this.ordered = this.$store.state.shoppingCart.items;
				this.$store.commit('shoppingCart/removeAllProducts');
				this.showSuccessMessage = true;
			}
		}
	};
</script>
