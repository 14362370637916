import store from '@/store';
import {IInviteeRole} from '@/types/InviteeRole';
import {IPersonRole, personRole} from '@/types/PersonRole';
import {IRight, rights} from '@/types/Rights';
import {rightsHelper} from '@/utils/rightsHelper';
import {ICheck} from '@/types/Check';

/**
 * Returns true if the user has role ADMIN or if the user meets the access requirements.
 */
export class AccessService {
	static async userCanAccess(requiredRoles?: IPersonRole[], requiredInvitedRole?: IInviteeRole[], requiredCheck?: ICheck[], requiredRight?: IRight, companyId?: number) {
		// 5xbeter admins can access everything
		if (store.getters.userHasRole(personRole.ROLE_VIJFXBETER_ADMIN)) {
			return true;
		}

		// If the user does not have the required role, he may not access
		if (requiredRoles && !requiredRoles.some(role => store.getters.userHasRole(role))) {
			return false;
		}

		if (!companyId) {
			return true;
		}

		// The following checks are only relevant if the user tries to access a company

		if (!store.getters.isCompanyLoaded(companyId)) {
			await store.dispatch('loadCompany', companyId);
		}

		// If the user does not have the required invited role, he may not access
		if (requiredInvitedRole && !requiredInvitedRole?.includes(store.getters.invitedRoleByCompanyId(companyId))) {
			return false;
		}

		// If the user does not have acccess to the specified check, he may not access
		if (requiredCheck && !store.getters.checkAccess(companyId).includes(requiredCheck)) {
			return false;
		}

		// If the user does not have the required right, he may not access
		const userRight = store.getters.highestLevelAccess(companyId);
		if (requiredRight) {
			switch (requiredRight) {
			case rights.READONLY:
				return rightsHelper.hasAtLeastReadOnlyAccess(userRight);
			case rights.WRITE:
				return rightsHelper.hasAtLeastWriteAccess(userRight);
			case rights.ADMIN:
				return rightsHelper.hasAtLeastAdminAccess(userRight);
			default:
				return false;
			}
		}

		return userRight !== rights.NONE;
	}
}
