/**
 * Get value of a cookie with the given name
 * @param {string} name
 * @returns {string|null}
 */
export const getCookie = (name) => {
	const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return v ? v[2] : null;
};

/**
 * Set a cookie
 * @param {string} name Name of the cookie
 * @param {string} value Value of the cookie
 * @param {Object} options
 * @param {number} [options.days = 365] Number of days to save the cookie
 * @param {string} [options.domain] Domain for the cookie
 * @param {string} [options.sameSite = lax] Needs to be Strict, Lax or None
 */
export const setCookie = (name, value, options = {}) => {
	const thisOptions = {
		days: 365,
		domain: '',
		sameSite: 'lax',
		...options
	};

	const d = new Date;
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * thisOptions.days);
	const domainString = thisOptions.domain ? `;domain=${thisOptions.domain}` : '';
	document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()};samesite=${thisOptions.sameSite}${domainString};`;
	// document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
};

/**
 * Delete a cookie with the given name
 * @param {string} name
 */
export const deleteCookie = (name) => {
	setCookie(name, '', -1);
};
