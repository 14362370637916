const STORE_PREFIX = 'checkStore/';

const getter = {
	GET_ERROR: 'getError'
};

const mutation = {
	UPDATE_ERROR: 'updateError',
	CLEAR_ERROR: 'clearError'
};

const action = {
	SET_WARNING: 'setWarningBottom',
	SET_WARNING_BOTTOM: 'setWarningBottom',
	SET_WARNING_TOP: 'setWarningTop',
	SET_ERROR: 'setError'
};

export const errorPosition = {
	TOP: 'positionTop',
	BOTTOM: 'positionBottom'
};

export const checkStoreMethod = {
	GET_ERROR: STORE_PREFIX + getter.GET_ERROR,

	UPDATE_ERROR: STORE_PREFIX + mutation.UPDATE_ERROR,
	CLEAR_ERROR: STORE_PREFIX + mutation.CLEAR_ERROR,

	SET_WARNING: STORE_PREFIX + action.SET_WARNING,
	SET_WARNING_BOTTOM: STORE_PREFIX + action.SET_WARNING_BOTTOM,
	SET_WARNING_TOP: STORE_PREFIX + action.SET_WARNING_TOP,
	SET_ERROR: STORE_PREFIX + action.SET_ERROR
};

const checkStore = {
	namespaced: true,

	state: () => ({
		error: {
			type: null,
			message: null,
			position: null
		}
	}),

	getters: {
		getError: state => state.error
	},

	mutations: {
		updateError: (state, error) => state.error = error,
		clearError: state => state.error = {message: null, type: null, position: null}
	},

	actions: {
		setWarningBottom: ({commit}, message) => commit(mutation.UPDATE_ERROR, {message, type: 'WARNING', position: errorPosition.BOTTOM}),
		setWarningTop: ({commit}, message) => commit(mutation.UPDATE_ERROR, {message, type: 'WARNING', position: errorPosition.TOP}),
		setError: ({commit}, message) => commit(mutation.UPDATE_ERROR, {message, type: 'ERROR'})
	}
};

export default checkStore;
