import store from '@/store';
import {pageHelper} from '@/utils/pageHelper';
import PageAddCompany from '@/components/company/PageAddCompany';
import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper';
import PageMyCompanies from '@/components/company/PageMyCompanies';
import PageCompanyDetails from '@/components/company/PageCompanyDetails';
import PageImprove from '@/components/company/PageImprove';
import PageCompanyImprovementCheckOverview from '@/components/company/PageCompanyImprovementCheckOverview';
import physicalStrainRoutes from '@/routes/results/physicalStrainRoutes';
import adviceRoutes from '@/routes/adviceRoutes';
import actionItemRoutes from '@/routes/actionItemRoutes';
import visitReportsRoutes from '@/routes/visitReportsRoutes';
import companyRecordsRoutes from '@/routes/companyRecordsRoutes';
import {inviteeRole} from '@/types/InviteeRole';
import {personRole} from '@/types/PersonRole';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '/bedrijf-toevoegen',
		name: pageHelper.PAGE_ADD_COMPANY,
		component: PageAddCompany,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Bedrijf toevoegen',
			progressStep: '4'
		}
	},

	{
		path: '/bedrijven',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Mijn bedrijven'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_MY_COMPANIES,
				component: PageMyCompanies,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					progressStep: '4'
				}
			},
			{
				path: 'externalId/:externalCompanyId',
				redirect: (to) => {
					const {params} = to;
					const company = store.getters.companyByExternalId(params.externalCompanyId);
					return company ? `/bedrijven/${company.id}/checks` : '/bedrijven';
				}
			},
			{
				path: 'verbeter/externalId/:externalCompanyId',
				redirect: (to) => {
					const {params} = to;
					const company = store.getters.companyByExternalId(params.externalCompanyId);
					return company ? `/bedrijven/${company.id}/verbeter` : '/bedrijven';
				}
			},
			{
				path: ':companyId',
				component: RouterViewWrapper,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					breadcrumb(route) {
						const companyId = route.params.companyId;
						return store.getters.companyNameById(companyId);
					}
				},
				children: [
					{
						path: '',
						redirect: {
							name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW
						},
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							inviteeRoles: [
								inviteeRole.ROLE_COACH,
								inviteeRole.ROLE_COLLEAGUE,
								inviteeRole.ROLE_EXTERNAL_ADVISOR,
								inviteeRole.ROLE_COMPANY_OWNER
							]
						}
					},
					{
						path: 'verbeter',
						name: pageHelper.PAGE_COMPANY_IMPROVE,
						component: PageImprove,
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							inviteeRoles: [
								inviteeRole.ROLE_COACH,
								inviteeRole.ROLE_COLLEAGUE,
								inviteeRole.ROLE_EXTERNAL_ADVISOR,
								inviteeRole.ROLE_COMPANY_OWNER
							],
							breadcrumb: 'Verbeter',
							progressStep: '5',
							backLink: {
								label: 'mijn bedrijven',
								url: pageHelper.PAGE_MY_COMPANIES
							}
						},
						props: true
					},
					{
						path: 'checks',
						component: RouterViewWrapper,
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							inviteeRoles: [
								inviteeRole.ROLE_COACH,
								inviteeRole.ROLE_COLLEAGUE,
								inviteeRole.ROLE_EXTERNAL_ADVISOR,
								inviteeRole.ROLE_COMPANY_OWNER
							],
							breadcrumb: 'Verbeterchecks'
						},
						children: [
							{
								path: '',
								name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW,
								component: PageCompanyImprovementCheckOverview,
								meta: {
									roles: [personRole.ROLE_VIJFXBETER_USER],
									inviteeRoles: [
										inviteeRole.ROLE_COACH,
										inviteeRole.ROLE_COLLEAGUE,
										inviteeRole.ROLE_EXTERNAL_ADVISOR,
										inviteeRole.ROLE_COMPANY_OWNER
									],
									breadcrumb: 'Verbeterchecks',
									progressStep: '4',
									backLink: {
										label: 'mijn bedrijven',
										url: pageHelper.PAGE_MY_COMPANIES
									}
								},
								props: true
							},
							...physicalStrainRoutes
						]
					},
					{
						path: 'info',
						name: pageHelper.PAGE_COMPANY_DETAILS,
						component: PageCompanyDetails,
						meta: {
							roles: [personRole.ROLE_VIJFXBETER_USER],
							inviteeRoles: [inviteeRole.ROLE_COMPANY_OWNER],
							breadcrumb: 'Bedrijfsgegevens',
							backLink: {
								label: 'mijn bedrijven',
								url: pageHelper.PAGE_MY_COMPANIES
							}
						}
					},
					...adviceRoutes,
					...actionItemRoutes,
					...visitReportsRoutes,
					...companyRecordsRoutes
				]
			}
		]
	}
];
