/* global _gaq */

/**
 * Get the pageName by reading the available breadcrumbs in the html
 * @returns {string}
 */
const getPageNameFromBreadcrumbs = () => {
	let page = '';
	const breadcrumbItems = document.querySelectorAll('ul.header-breadcrumb li');
	breadcrumbItems.forEach((element, index) => {
		page += (index > 0 ? ' - ' : '') + element.innerText.trim();
	});

	return page;
};

/**
 * Track an event with Google Analytics
 * @param {String} pageName
 * @param {String} action
 * @param {String} message
 */
export const trackEvent = (pageName, action, message) => {
	const page = pageName || getPageNameFromBreadcrumbs();
	if (typeof _gaq !== 'undefined') {
		_gaq.push(['_trackEvent', page, action, message]);
	} else {
		// If it's deactivated, log the parameters for debugging
		// eslint-disable-next-line no-console
		console.log(`_gaq.push(["_trackEvent", "${page}", "${action}", "${message}"]);`);
	}
};
