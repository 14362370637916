<template>
	<div v-if="startedChecks.length > 0" class="company-delete-check">
		<button class="company-delete-check__toggle"
		        type="button"
		        @click.prevent="showAll = !showAll">
			Verbetercheck verwijderen
			<i :class="showAll ? 'icon-chrevron-up' : 'icon-chrevron-down'"></i>
		</button>

		<transition-fade :show-if="showAll">

			<div>
				<div class="company-delete-check__info">
					<p>
						<strong>Let op:</strong>
						Het verwijderen van een verbetercheck is niet ongedaan te maken.
					</p>
				</div>
				<div v-for="(check) in startedChecks"
				     :key="check.check"
				     class="company-delete-check__check">

					<button :aria-label="`${checkTitle(check.check)} verwijderen`"
					        :title="`${checkTitle(check.check)} verwijderen`"
					        type="button"
					        @click="confirmDeleteCheck(check.check)">
						<i class="icon-delete"></i>
					</button>

					{{ checkTitle(check.check) }}

				</div>
			</div>
		</transition-fade>
	</div>

</template>

<script>

import TransitionFade from '@/components/decorations/TransitionFade';
import {checkHelper} from '@/utils/checkHelper';
import {Api} from '@/utils/api';

export default {
	name: 'CompanyDeleteCheck',
	components: {TransitionFade},
	data: () => ({
		showAll: true
	}),
	props: {
		checks: {
			type: Array,
			required: true
		},
		companyId: {
			type: [Number, String],
			required: true,
			validator: value => Number.isInteger(Number(value))
		}
	},
	computed: {
		startedChecks() {
			return this.checks.filter(check => check.started);
		}
	},
	methods: {
		checkTitle(check) {
			return checkHelper.getTitle(check);
		},
		async completeActionItem() {
			this.action.completed = true;
			await Api.postUpdateAction(this.companyId, this.actionId, this.action)
				.catch((error) => {
					if (error.message && error.message.length > 0) {
						this.warningMessage = error.message;
					}
				});
		},
		confirmDeleteCheck(check) {
			const checkTitle = this.checkTitle(check);

			this.$confirmDialog({
					title: `Verbetercheck ${checkTitle} verwijderen`,
					content: `Weet u zeker dat u de verbetercheck ${checkTitle} wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden`,
					confirmButton: 'Verwijderen',
					dialogType: 'warning'
				})
				.then(async () => {
					await this.deleteCheck(check);
				})
				.catch(() => {
					// Do nothing
				});
		},
		deleteCheck(check) {
			Api.deleteCheckForCompany(this.companyId, check)
				.then(() => location.reload())
				.catch((error) => {
					let errorText = 'Verwijderen verbetercheck is niet gelukt';
					if (error.message && error.message.length > 0) {
						errorText = error.message;
					}
					// eslint-disable-next-line no-alert
					alert(errorText);
				});
		}
	}
};
</script>

<style lang="scss">
@import '../../assets/variables';

.company-delete-check {
	margin-top: 20px;

	&__toggle {
		display: block;
		background: #e7e7e7;
		padding: .5em 20px;
		width: 100%;
		text-align: left;
		color: $blue;
		position: relative;

		&:hover {
			text-decoration: underline;
		}

		[class^="icon"] {
			position: absolute;
			font-size: 6px;
			top: 16px;
			right: 12px;
		}
	}

	&__info,
	&__check {
		margin: 2px 0;
		background: #e7e7e7;
		padding: .5em 20px;

		p {
			margin: 0;
		}
	}

	&__check {
		position: relative;
		padding: .5em 20px;
		overflow: hidden;
		font-weight: bold;

		button {
			position: absolute;
			right: 0;
			top: 0;
			height: 40px;
			background: transparent;
			float: right;
			margin: 0;
			padding: 8px 13px;
			font-size: 15px;
			line-height: 1;
			text-decoration: none;
			color: #00a0db;
			transition: color .3s;

			&:hover {
				color: #000000;
			}
		}
	}
}
</style>
