<template>
	<base-grid page-title="Verbetercheck verplaatsen" sidebar="admin">
		<form-migrate-improvement-check />
	</base-grid>
</template>

<script>
import BaseGrid from '@/components/decorations/BaseGrid';
import FormMigrateImprovementCheck from '@/components/admin/company/FormMigrateImprovementCheck';

export default {
	name: 'PageMigrateImprovementCheck',
	components: {FormMigrateImprovementCheck, BaseGrid}
};
</script>
