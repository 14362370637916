<template>
	<base-grid sidebar="login" page-title="Wachtwoord vergeten?">

		<div class="block-form">
			<form-reset-password v-if="!resetFormHasSuccessfullSubmit"
			                     @successfullSubmit="formSend" />

			<div v-if="resetFormHasSuccessfullSubmit">
				<p>
					<strong>Volg onderstaande instructies om je wachtwoord te wijzigen</strong><br>
				</p>

				<ol>
					<li>Er is een e-mail naar <strong v-text="email"></strong> verzonden.</li>
					<li>Klik op de link in de e-mail om je wachtwoord te wijzigen.</li>
				</ol>

				<div class="button-bar">
					<router-link class="button js-close-modal" :to="{ name: pageHelper.PAGE_LOGIN}">Inlogformulier
					</router-link>
				</div>
			</div>
		</div>

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormResetPassword from './FormForgotPassword';
	import {pageHelper} from '../../utils/pageHelper';

	export default {
		name: 'PageResetPassword',
		components: {FormResetPassword, BaseGrid},
		data() {
			return {
				resetFormHasSuccessfullSubmit: false,
				email: ''
			};
		},
		computed: {
			pageHelper() {
				return pageHelper;
			}
		},
		methods:{
			formSend(email) {
				this.resetFormHasSuccessfullSubmit = true;
				this.email = email;
			}
		}
	};
</script>
