<template>
	<base-grid page-title="Reset hier je wachtwoord" sidebar="login">

		<form-update-password v-if="showResetForm" :reset-password="true" :token="token" />

		<div v-if="!loading && !showResetForm">
			<strong>Wachtwoord resetten niet mogelijk</strong>
			<p>Resetten van je wachtwoord is niet mogelijk, mogelijk is deze link al eerder gebruikt.</p>
		</div>

	</base-grid>
</template>

<script>
import BaseGrid from '@/components/decorations/BaseGrid';
import FormUpdatePassword from '@/components/login/FormUpdatePassword';
import {Api} from '@/utils/api';
import {pageHelper} from '@/utils/pageHelper';

export default {
	name: 'PageResetPassword',
	components: {FormUpdatePassword, BaseGrid},
	data() {
		return {
			token: null,
			loading: true,
			showResetForm: false
		};
	},
	methods: {
		async isPasswordResetTokenValid() {
			if (!this.token) {
				this.loading = false;
				return;
			}
			await Api.getIsPasswordResetTokenValid(this.token)
				.then((response) => {
					if (response.responseObject) {
						this.showResetForm = true;
					}
				})
				.catch(() => {
					this.showResetForm = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		forgotPasswordUrl() {
			return {name: pageHelper.PAGE_FORGOT_PASSWORD};
		}
	},
	mounted() {
		this.token = this.$route.query.token;
		this.isPasswordResetTokenValid();
	}
};

</script>
