import {physicalScriptKey} from '@/components/check/physicalstrain/physicalScriptKeys';

export class ScriptService {

	/**
	 *
	 * @param scriptKey The key of the next Script
	 * @param scriptFlow The ScriptFlow of the improvement check.
	 * @param clientAnswers The clientAnswers for the improvement check.
	 * @returns The script key of the next applicable script
	 */
	static getApplicableScriptKey(scriptKey, scriptFlow, clientAnswers) {
		let applicableScriptKey = scriptKey;

		while (applicableScriptKey) {
			const script = scriptFlow.scripts.find(scriptObject => scriptObject.scriptKey === applicableScriptKey);

			const dependsOn = script.dependsOn;
			if (!dependsOn) {
				// This script does not depend on an answer, so it is applicable
				return applicableScriptKey;
			}

			const givenAnswersForScript = clientAnswers[dependsOn.scriptKey];
			const answers = givenAnswersForScript?.answers?.filter(answer => answer.questionInternalKey === dependsOn.questionKey);
			if (answers?.some(answer => dependsOn.answerKeys.includes(answer.internalKey))) {
				return applicableScriptKey;
			}
			applicableScriptKey = script?.nextScript;
		}
	}

	/**
	 *
	 * @param scriptKey Current scriptKey.
	 * @param scriptFlow ScriptFlow of the improvement check.
	 * @returns Script type
	 */
	static getScriptType(scriptKey, scriptFlow) {
		return scriptFlow.scripts.find(scriptObject => scriptObject.scriptKey === scriptKey).scriptType;
	}

	/**
	 *
	 * @param currentScriptKey Current scriptKey.
	 * @param currentCheckSectionId Current (function group / space).
	 * @param scriptFlow ScriptFlow of the improvement check.
	 * @param checkSections CheckSections (function groups / spaces).
	 * @param clientAnswers ClientAnswers for the improvement check.
	 * @returns Query parameters for the next script route, including the scriptKey and the functionGroupId.
	 */
	static getNextScriptRoute(currentScriptKey, currentCheckSectionId, scriptFlow, checkSections, clientAnswers) {
		const {startScriptKey, scripts} = scriptFlow;

		if (!currentScriptKey) {
			return {scriptKey: startScriptKey};
		}

		const currentScript = scripts.find(script => script.scriptKey === currentScriptKey);

		if (!currentScript.nextScript) {
			// End of Script flow. Go to results.
			return null;
		}

		const nextApplicableScriptKey = this.getApplicableScriptKey(currentScript.nextScript, scriptFlow, clientAnswers);
		const nextApplicableScript = scripts.find(script => script.scriptKey === nextApplicableScriptKey);

		if (currentCheckSectionId) {
			if (!nextApplicableScript.genericScript) {
				// Continue with current CheckSection
				return {scriptKey: nextApplicableScriptKey, functionGroupId: currentCheckSectionId};
			}

			// End of CheckSection scripts. Start with next CheckSection
			const currentCheckSectionIndex = checkSections.findIndex(checkSection => checkSection.id === currentCheckSectionId);
			const nextCheckSection = checkSections[currentCheckSectionIndex + 1];

			if (nextCheckSection) {
				return {scriptKey: startScriptKey, functionGroupId: nextCheckSection.id};
			}
		}

		// Start with first CheckSection
		if (!nextApplicableScript.genericScript) {
			return {scriptKey: nextApplicableScript.scriptKey, functionGroupId: checkSections[0].id};
		}

		// Return next generic script
		return {scriptKey: nextApplicableScriptKey};
	}

	static getPhysicalStrainGenericScripts() {
		return [physicalScriptKey.pregnancy, physicalScriptKey.sittingWork];
	}
}
