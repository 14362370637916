<template>
	<div v-if="hasWarning" class="warning-block">
		{{ error.message }}
	</div>
</template>

<script>
	export default {
		name: 'CheckErrorMessage',
		props: {
			error: {
				type: Object
			}
		},
		computed: {
			hasWarning() {
				return this.error?.type === 'WARNING' && this.error?.message;
			}
		}
	};
</script>
