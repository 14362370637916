<template>
	<base-grid sidebar="account" page-title="Maak je bedrijf aan">

		<div class="block-content block-content-white">
			<p>Om Verbeterchecks te kunnen uitvoeren moet je eerst één of meerdere bedrijven aanmaken.</p>
		</div>

		<form-add-company />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormAddCompany from './FormAddCompany';

	export default {
		name: 'PageAddCompany',
		components: {FormAddCompany, BaseGrid},
		data() {
			return {};
		}
	};
</script>
