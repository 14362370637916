import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper.vue';
import {pageHelper} from '@/utils/pageHelper';
import PageShoppingCartOverview from '@/components/orders/PageShoppingCartOverview.vue';
import PageShoppingCartCheckout from '@/components/orders/PageShoppingCartCheckout.vue';
import PageMyOrders from '@/components/orders/PageMyOrders.vue';
import {personRole} from '@/types/PersonRole';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '/winkelwagen',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Bestellijst'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_SHOPPING_CART_OVERVIEW,
				component: PageShoppingCartOverview,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER]
				}
			},
			{
				path: 'afronden',
				name: pageHelper.PAGE_SHOPPING_CART_CHECKOUT,
				component: PageShoppingCartCheckout,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					breadcrumb: 'Afronden'
				}
			}
		]
	},
	{
		path: '/bestellingen',
		name: pageHelper.PAGE_MY_ORDERS,
		component: PageMyOrders,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Mijn bestellingen'
		}
	}
];
