<template>
	<section class="check-page-content">
		<CheckErrorPage v-if="hasError" :message="error.message" />

		<template v-else>
			<slot></slot>
			<CheckErrorMessage :error="error" />
		</template>
	</section>
</template>

<script>
	import CheckErrorMessage from '@/components/check/CheckErrorMessage';
	import CheckErrorPage from '@/components/check/CheckErrorPage';
	import {checkStoreMethod} from '@/store/check/checkStore';

	export default {
		name: 'CheckPageTemplate',
		components: {CheckErrorPage, CheckErrorMessage},
		computed: {
			error() {
				return this.$store.getters[checkStoreMethod.GET_ERROR];
			},
			hasError() {
				return this.error?.type === 'ERROR';
			}
		}
	};
</script>

<style lang="scss" scoped>
	.check-page-content {
		position: relative;
		z-index: 2;
		margin: 0 auto 0;
		width: 100%;
		max-width: 500px;
		padding: 0 20px;
	}
</style>
