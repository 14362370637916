<template>
	<card-list-item :line-clamp="2"
	                :router-link-to="detailPageRouterObject"
	                read-more="Bekijk actiepunt">

		<template #heading>
			{{ getFormattedDate(action.createdDate) }} door {{ action.createdBy.name }}
		</template>

		<p>
			<strong>{{ getCombinedThemeTitles(action.themes) }}</strong> <br />
			{{ action.actionPoint }}
		</p>

		<template #footer v-if="showFooter">
			<div v-if="showDeadline">
				<strong>Deadline:</strong>
				{{ getTextOrFallback(getFormattedDate(action.deadline)) }}
			</div>

			<div v-if="showActionHolder">
				<strong>Actiehouder:</strong>
				{{ getTextOrFallback(action.actionHolder) }}
			</div>
		</template>

	</card-list-item>
</template>

<script>
	import {getCombinedThemeTitles} from '@/utils/themeHelper';
	import {getFormattedDate} from '@/utils/date';
	import {pageHelper} from '@/utils/pageHelper';
	import CardListItem from '../generic/CardListItem';
	import {getTextOrFallback} from '@/utils/text';

	export default {
		name: 'ActionsListItem',
		components: {CardListItem},
		props: {
			action: {
				type: Object,
				required: true
			}
		},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			},
			detailPageRouterObject() {
				return {
					name: pageHelper.PAGE_COMPANY_ACTION_DETAIL,
					params: {
						companyId: this.companyId,
						actionId: this.action.id
					}
				};
			},
			showDeadline() {
				return this.action.deadline.length > 0;
			},
			showActionHolder() {
				return this.action.actionHolder.length > 0;
			},
			showFooter() {
				return this.showDeadline || this.showActionHolder;
			}
		},
		methods: {
			getCombinedThemeTitles,
			getFormattedDate,
			getTextOrFallback
		}
	};
</script>
