import {ScriptService} from '@/services/check/scriptService';
import NumberUtils from '@/utils/numberUtils';

const getScript = state => scriptKey => state.scripts[scriptKey];

const getScriptFlow = state => state.scriptFlow;

const getFunctionGroups = state => state.functionGroups;

const getFunctionGroupById = state => functionGroupId => state.functionGroups.find(functionGroup => functionGroup.id === NumberUtils.stringToNumber(functionGroupId));

const getClientScriptAnswers = state => scriptKey => state.clientAnswersPerScript[scriptKey] ?? {};

const getRouteForNextScript = state => (currentScriptKey, currentFunctionGroupId) => {
	const {scriptFlow, functionGroups, clientAnswersPerScript} = state;
	return ScriptService.getNextScriptRoute(currentScriptKey, currentFunctionGroupId, scriptFlow, functionGroups, clientAnswersPerScript);
};

const getScriptType = state => currentScriptKey => ScriptService.getScriptType(currentScriptKey, state.scriptFlow);

export {
	getScript,
	getScriptFlow,
	getFunctionGroups,
	getFunctionGroupById,
	getClientScriptAnswers,
	getRouteForNextScript,
	getScriptType
};
