<template>
	<base-grid :page-title="pageTitle" sidebar="account">

		<block-search-field v-model="searchValue" label="Zoek op bedrijf of naam, email van eigenaar" />

		<skeleton-card-item v-if="loading" :lines="5" :lines-width="[90]" />

		<template v-if="!loading">
			<p v-if="showNoResults">
				Geen bedrijven gevonden.
			</p>

			<page-pagination v-else
			                 v-slot:="slotProps"
			                 :items="filteredCompanies"
			                 :items-per-page="20">
				<company-list-table :companies="slotProps.itemsOnPage" />
			</page-pagination>
		</template>

		<div v-if="warningMessage">
			{{ warningMessage }}
		</div>

	</base-grid>
</template>

<script>
	import BlockSearchField from '@/components/filters/BlockSearchField';
	import BaseGrid from '@/components/decorations/BaseGrid';
	import {getFilteredArray, includesTextInKeys} from '@/utils/filter';
	import {Api} from '@/utils/api';
	import CompanyListTable from '@/components/admin/company/CompanyListTable';
	import PagePagination from '@/components/generic/PagePagination';
	import SkeletonCardItem from '@/components/generic/SkeletonCardItem';

	export default {
		name: 'PageCompaniesOverview',
		components: {SkeletonCardItem, PagePagination, BlockSearchField, CompanyListTable, BaseGrid},
		data() {
			return {
				loading: true,
				warningMessage: '',
				companies: [],
				searchValue: ''
			};
		},

		computed: {
			filteredCompanies() {
				const filters = [
					...includesTextInKeys(this.searchValue, ['name', 'ownerName', 'ownerEmail'])
				];
				return getFilteredArray(this.companies, filters);
			},
			showNoResults() {
				return this.filteredCompanies.length === 0 && this.companies.length > 0;
			},
			pageTitle() {
				const title = 'Bedrijvenoverzicht';
				if (this.loading) {
					return title;
				}
				if (this.searchValue.length === 0) {
					return `${title} (${this.companies.length})`;
				}
				return `${title} (${this.filteredCompanies.length} / ${this.companies.length})`;
			}
		},

		methods: {
			async loadCompanies() {
				this.warningMessage = '';
				await Api.getAllCompanies()
					.then((response) => {
						this.companies = response.responseObject;
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Overzicht van bedrijven kan niet geladen worden.';
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		mounted() {
			this.loadCompanies();
		}
	};
</script>
