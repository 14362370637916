import {pageHelper} from '@/utils/pageHelper';
import PageLogin from '@/components/login/PageLogin.vue';
import PageRegister from '@/components/login/PageRegister.vue';
import shoppingCartRoutes from '@/routes/shoppingCartRoutes';
import PageForgotPassword from '@/components/login/PageForgotPassword.vue';
import PageResetPassword from '@/components/login/PageResetPassword.vue';
import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper.vue';
import PageMyAccount from '@/components/account/PageMyAccount.vue';
import PageUpdatePassword from '@/components/login/PageUpdatePassword.vue';
import {personRole} from '@/types/PersonRole';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '/inloggen',
		name: pageHelper.PAGE_LOGIN,
		component: PageLogin,
		meta: {
			breadcrumb: 'Inloggen'
		}
	},
	{
		path: '/aanmelden',
		name: pageHelper.PAGE_REGISTER,
		component: PageRegister,
		meta: {
			breadcrumb: 'Aanmelden'
		}
	},
	...shoppingCartRoutes,
	{
		path: '/wachtwoord-vergeten',
		name: pageHelper.PAGE_FORGOT_PASSWORD,
		component: PageForgotPassword,
		meta: {
			breadcrumb: 'Wachtwoord vergeten'
		}
	},
	{
		path: '/wachtwoord-resetten',
		name: pageHelper.PAGE_RESET_PASSWORD,
		component: PageResetPassword
	},
	{
		path: '/gegevens',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Mijn gegevens'
		},
		children: [
			{
				path: '',
				component: PageMyAccount,
				name: pageHelper.PAGE_MY_ACCOUNT,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					breadcrumb: 'Mijn gegevens'
				}
			},
			{
				path: 'wachtwoord-wijzigen',
				name: pageHelper.PAGE_UPDATE_PASSWORD,
				component: PageUpdatePassword,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					breadcrumb: 'Wachtwoord wijzigen'
				}
			}
		]
	}
];
