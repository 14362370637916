import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper.vue';
import {pageHelper} from '@/utils/pageHelper';
import PageActionOverview from '@/components/actionitem/PageActionOverview.vue';
import PageActionDetail from '@/components/actionitem/PageActionDetail.vue';
import {inviteeRole} from '@/types/InviteeRole';
import {personRole} from '@/types/PersonRole';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: 'actiepunten',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Actiepunten'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_COMPANY_ACTION_OVERVIEW,
				component: PageActionOverview,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					progressStep: '5',
					backLink: {
						label: 'mijn bedrijven',
						url: pageHelper.PAGE_MY_COMPANIES
					}
				},
				props: true
			},
			{
				path: ':actionId',
				name: pageHelper.PAGE_COMPANY_ACTION_DETAIL,
				component: PageActionDetail,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					breadcrumb: 'Actiepunt',
					progressStep: '5',
					backLink: {
						label: 'mijn bedrijven',
						url: pageHelper.PAGE_MY_COMPANIES
					}
				},
				props: true
			}
		]
	}
];
