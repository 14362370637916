<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<div class="block-container" title="Overzicht wordt geladen" v-if="loading">
			<skeleton-visit-report :key="i" compact-mode v-for="i in 3" />
		</div>

		<div class="checkbox-list">
			<checkbox-list-item
					id="showArchived"
					label="Toon gearchiveerde bezoekverslagen"
					name="showArchived"
					v-model="showArchived" />
		</div>

		<visit-report-list :visit-reports="filteredVisitReports" v-if="!loading" />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {Api} from '../../utils/api';
	import VisitReportList from './VisitReportList';
	import SkeletonVisitReport from './SkeletonVisitReport';
	import CheckboxListItem from '../forms/CheckboxListItem';

	export default {
		name: 'PageVisitReportOverview',
		components: {SkeletonVisitReport, CheckboxListItem, VisitReportList, BaseGrid},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				showArchived: false,
				warningMessage: '',
				visitReports: []
			};
		},
		computed: {
			pageTitle() {
				return `Bezoekverslagen voor ${this.$store.state.company.current.name}`;
			},
			filteredVisitReports() {
				if (this.showArchived) {
					return this.visitReports;
				}

				return this.visitReports.filter(report => !report.archived);
			}
		},
		methods: {
			async loadCompanyVisitReportsData() {
				await Api.getCompanyVisitReports(this.companyId)
					.then((response) => {
						this.visitReports = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Bezoekverslagen konden niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadCompanyVisitReportsData();
			},
			resetPage() {
				this.visitReports = [];
			}
		},
		watch: {
			$route() {
				this.resetPage();
				this.initPage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
