<template>
	<router-link v-if="insideSpa" :to="href">
		<slot></slot>
	</router-link>
	<a v-else :href="prefixedHref">
		<slot></slot>
	</a>
</template>

<script>
	import {currentlyInSpaMode} from '@/utils/integrationHelper';

	export default {
		name: 'LinkMijn5xbeter',
		data() {
			return {
				mijn5xbeterUrlPrefix: process.env.VUE_APP_VC_DOMAIN + process.env.VUE_APP_INTEGRATION_BASEURL
			};
		},
		props: {
			href: {
				type: String,
				required: true
			}
		},
		computed: {
			prefixedHref() {
				return this.mijn5xbeterUrlPrefix + this.href;
			},
			insideSpa() {
				return currentlyInSpaMode();
			}
		}
	};
</script>
