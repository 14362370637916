<template>
	<base-grid page-title="Mijn Bestellingen" sidebar="account">
		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<skeleton-card-item v-if="loading"
		                    :lines="2"
		                    :lines-width="[14, 30]"
		                    title="Bestellingen worden geladen" />

		<template v-if="!loading">
			<p v-if="orders.length === 0">
				Je hebt geen bestellingen
			</p>

			<card-block v-for="order in orders" :key="order.id">
				<h2 style="margin-bottom: 0;"><strong>{{ getFormattedDate(order.date) }}</strong></h2>
				<order-list :items="order.products" readonly />
			</card-block>
		</template>

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {Api} from '@/utils/api';
	import {getFormattedDate} from '@/utils/date';
	import OrderList from '@/components/orders/OrderList';
	import CardBlock from '@/components/generic/CardBlock';
	import SkeletonCardItem from '@/components/generic/SkeletonCardItem';

	export default {
		name: 'PageMyOrders',
		components: {SkeletonCardItem, CardBlock, OrderList, BaseGrid},
		data() {
			return {
				loading: false,
				warningMessage: '',
				orders: []
			};
		},
		methods: {
			async loadOrderHistory() {
				await Api.getOrderHistory()
					.then((response) => {
						this.orders = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Je bestellingen kunnen niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadOrderHistory();
			},
			getFormattedDate
		},
		mounted() {
			this.initPage();
		}
	};
</script>
