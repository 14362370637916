<template>
	<div class="block-page-header">
		<slot name="icon"></slot>
		<h1 class="block-page-header-title">
			<strong>
				<slot>&nbsp;</slot>
			</strong>
		</h1>
		<slot name="afterTitle"></slot>
	</div>
</template>
<script>
	export default {
		name: 'BlockPageHeader'
	};
</script>


