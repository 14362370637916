<template>
	<div class="order-list-item">
		<template v-if="!readonly">

			<a class="order-list-item_delete"
			   href="#"
			   @click.prevent="removeItem"><i class="icon-delete"></i></a>

			<div class="custom-select custom-select-blue order-list-item_select">
				<select v-model="localQuantity"
				        :aria-label="`hoeveelheid van ${item.title}`"
				        class="order-list-amount"
				        @change="updateQuantityOfProduct">
					<option v-for="i in 10"
					        :key="i"
					        :value="i">{{ i }}
					</option>
				</select>
			</div>
			<div class="order-list-item_name">
				{{ item.title }}
			</div>

		</template>

		<template v-if="readonly">
			<span class="order-list-small_item-quantity">{{ item.quantity }}x</span>
			{{ item.title }}
		</template>
	</div>
</template>

<script>
	export default {
		name: 'OrderListItem',
		props: {
			item: {
				type: Object,
				required: true
			},
			readonly: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				localQuantity: 1
			};
		},
		computed: {
			quantity() {
				return this.item.quantity;
			}
		},
		watch: {
			quantity: {
				immediate: true,
				handler(newQuantity) {
					this.localQuantity = newQuantity;
				}
			}
		},
		methods: {
			removeItem() {
				this.$store.commit('shoppingCart/removeProduct', this.item.id);
			},
			updateQuantityOfProduct() {
				this.$store.commit(
					'shoppingCart/updateQuantityOfProduct',
					{
						id: this.item.id,
						quantity: this.localQuantity
					}
				);
			}
		}
	};
</script>
