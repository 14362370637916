<template>
	<footer>
		TODO: mockup Footer
	</footer>
</template>

<script>
	export default {
		name: 'vijfxbeter-footer'
	};
</script>
