<template>
	<article class="card-list-item">
		<router-link :to="routerLinkTo">
			<h4 class="card-list-item__heading" v-if="renderHeading">
				<slot name="heading"></slot>
			</h4>

			<div class="card-list-item__main" :style="dynamicLineClampStylingOverwrite">
				<slot></slot>
			</div>

			<div class="card-list-item__footer" v-if="renderFooter">
				<slot name="footer"></slot>
			</div>

			<div class="card-list-item__read-more" v-if="readMore.length > 0">
				<span>{{readMore}}</span>
				<i class="icon-chevron-right"></i>
			</div>
		</router-link>
	</article>
</template>

<script>
	export default {
		name: 'CardListItem',
		props: {
			readMore: {
				type: String,
				default: 'Meer info'
			},
			routerLinkTo: {
				required: true
			},
			lineClamp: {
				type: Number,
				default: 0
			}
		},
		computed: {
			renderHeading() {
				return !!this.$slots.heading;
			},
			renderFooter() {
				return !!this.$slots.footer;
			},
			dynamicLineClampStylingOverwrite() {
				if (this.lineClamp < 1) {
					return null;
				}
				return `max-height: ${this.lineClamp * 2}em; -webkit-line-clamp: ${this.lineClamp}`;
			}
		}
	};
</script>


<style lang="scss">
	@use "sass:color";
	@import '../../assets/variables';

	$line-clamp: 2;

	.card-list-item {

		&:not(:first-child) {
			background: $color-card-bg;
			border-top: 1px solid $color-body-bg;
		}

		a {
			display: block;
			position: relative;
			background: $color-card-bg;
			padding: 14px 40px 14px 20px;

			&:focus,
			&:hover {
				transition: background-color .1s ease-in-out;
				background: $color-card-bg-hover;
			}

			&:active {
				background: $color-card-bg;
				transition-duration: 0s;
			}

			&, &:hover {
				color: inherit;
				text-decoration: none;
			}
		}

		&__heading {
			font-weight: normal;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $grey;
		}

		&__main {
			max-height: none;
			overflow: hidden;
			padding-right: 1em;
			margin: 0;

			@supports (-webkit-line-clamp: $line-clamp) {
				&[style*="-webkit-line-clamp"] {
					display: -webkit-box;
					-webkit-box-orient: vertical;
				}
			}

			p, ul, ol {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}


		&__read-more {
			position: absolute;
			bottom: 1em;
			right: 2em;
			color: $blue;

			span {
				display: inline-block;
				padding: 0 1em 0 3em;
				background: $color-card-bg-hover;
				background: linear-gradient(to right,
						color.change($color-card-bg-hover, $alpha: .0),
						$color-card-bg-hover 2em,
						$color-card-bg-hover 100%);
				opacity: 1;
				transition: opacity .1s ease-in-out, background-color .1s ease-in-out;
			}

			a:not(:hover):not(:focus) & {
				background: transparent;

				span {
					background: $color-card-bg;
					background: linear-gradient(to right,
							color.change($color-card-bg, $alpha: .0),
							$color-card-bg 2em,
							$color-card-bg 100%);
					opacity: 0;
				}
			}

			a:active & {
				span {
					background: $color-card-bg;
					background: linear-gradient(to right,
							color.change($color-card-bg, $alpha: .0),
							$color-card-bg 2em,
							$color-card-bg 100%);
					transition-duration: 0s, 0s;
				}
			}

		}
	}
</style>
