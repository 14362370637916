<template>
	<dialog-container :title="config.title ? config.title : defaultConfig.title"
	                  :show="show"
	                  :dialog-type="config.dialogType"
	                  @close="resolveConfirmDialog(false)">

		<div class="popup-content">
			<div v-if="config.htmlContent"
			     v-html="config.htmlContent"/>

			<p v-else-if="config.content">
				{{config.content}}
			</p>

			<div class="button-bar ">
				<button type="button"
				        v-if="config.confirmButton"
				        @click="resolveConfirmDialog(true)"
				        class="button button--primary js-button-confirm">
					{{config.confirmButton}}
				</button>
				<button type="button"
				        v-if="config.cancelButton"
				        @click="resolveConfirmDialog(false)"
				        class="button button--text">
					{{config.cancelButton}}
				</button>
			</div>
		</div>

	</dialog-container>
</template>

<script>

	import DialogContainer from './DialogContainer';
	import {defineComponent} from 'vue';

	export default defineComponent({
		name: 'confirmDialog',
		components: {DialogContainer},
		data: () => ({
			show: false,
			config: {},
			defaultConfig: {
				confirmButton: 'Oke',
				cancelButton: 'Annuleren',
				dialogType: '',
				title: 'Let op',
				content: '',
				htmlContent: ''
			}
		}),
		methods: {
			openConfirmDialog(options = {}) {
				this.config = {...this.defaultConfig, ...options};
				this.show = true;
			}
		},
		mounted() {
			this.config = {...this.defaultConfig};
		}
	});
</script>

