<template>
	<div class="order-list">

		<template v-if="items.length === 0">
			{{ noProductsText }}
		</template>

		<template v-else>
			<order-list-item v-for="item in items" :key="item.id" :item="item" :readonly="readonly" />
		</template>

	</div>
</template>

<script>
	import OrderListItem from '@/components/orders/OrderListItem';

	export default {
		name: 'OrderList',
		components: {OrderListItem},
		props: {
			items: {
				type: Array,
				default: () => []
			},
			readonly: {
				type: Boolean,
				default: false
			},
			noProductsText: {
				type: String,
				default: 'Geen producten'
			}
		}
	};
</script>
