<template>
	<div id="vijfxbeter-mijn">
		<improvement-check-header v-if="showImprovementCheckFullScreen" />

		<div class="header-sticky">
			<check-of5-header v-if="progressStep" :progress-step="progressStep" />

			<bread-crumbs v-if="!showImprovementCheckFullScreen" :items="breadcrumbItems" />
		</div>

		<div :class="progressStep ? 'content-check5-header' : ''"
		     class="content-container content-vijfxbeter-mijn">
			<!-- TODO: FIX RESPONSIVE VIEW -->
			<router-view />
		</div>

		<vijfxbeter-global-modals />

		<!-- FIXME: maybe move to a plugin instead of a component -->
		<build-info-console />
	</div>
</template>

<script>
	import BreadCrumbs from '@/components/decorations/BreadCrumbs';
	import VijfxbeterGlobalModals from '@/components/wrappers/VijfxbeterGlobalModals';
	import BuildInfoConsole from '@/components/generic/BuildInfoConsole';
	import CheckOf5Header from '@/components/decorations/CheckOf5Header';
	import ImprovementCheckHeader from '@/components/wrappers/ImprovementCheckHeader';
	import NumberUtils from '@/utils/numberUtils';
	import {pageHelper} from '@/utils/pageHelper';

	export default {
		name: 'vijfxbeter-mijn',
		components: {
			BuildInfoConsole,
			VijfxbeterGlobalModals,
			BreadCrumbs,
			CheckOf5Header,
			ImprovementCheckHeader
		},
		computed: {
			companyId() {
				return NumberUtils.stringToNumber(this.$route.params.companyId);
			},

			progressStep() {
				return this.$route.meta.progressStep;
			},

			showImprovementCheckFullScreen() {
				return this.$route.meta.improvementCheckFullScreen ?? false;
			},

			breadcrumbItems() {
				const pathArray = this.$route.path.split('/').filter(item => item.length > 0);

				const breadcrumbArray = [];

				pathArray.forEach((path, index) => {
					const breadcrumbItem = this.$route.matched[index]?.meta?.breadcrumb;

					if (!breadcrumbItem) {
						return;
					}

					let breadcrumbText = null;

					if (typeof breadcrumbItem === 'string') {
						breadcrumbText = breadcrumbItem;
					}

					if (typeof breadcrumbItem === 'function') {
						breadcrumbText = breadcrumbItem(this.$route);
					}

					breadcrumbArray.push({
						path,
						to: breadcrumbArray[index - 1] ? `${breadcrumbArray[index - 1].to}/${path}` : `/${path}`,
						text: breadcrumbText ?? '...'
					});
				});

				return breadcrumbArray;
			}
		},
		methods: {
			toggleHeader(value) {
				const showHeader = value ?? this.showImprovementCheckFullScreen;
				document.body.classList.toggle('checkQuestionFullscreen', showHeader);
			},
			async getCompanyData(companyId) {
				const isCompanyChecksPage = this.$route.name === pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW;

				if (!companyId || isCompanyChecksPage) {
					return;
				}

				await this.$store.dispatch('loadCompany', companyId);
			}
		},
		watch: {
			showImprovementCheckFullScreen() {
				this.toggleHeader();
			},

			companyId: {
				handler(newCompanyId) {
					this.getCompanyData(newCompanyId);
				}
			}
		},
		mounted() {
			this.toggleHeader();
		},
		unmounted() {
			this.toggleHeader(false);
		}
	};
</script>

<style>
	#vijfxbeter-mijn {
		padding-bottom: 2em;
		min-height: 75vh;
	}

	body.checkQuestionFullscreen #header-main,
	body.checkQuestionFullscreen footer {
		display: none;
	}

	@media screen and (min-width: 1024px) {
		.header-sticky {
			position: fixed;
			width: 100%;
			top: 50px;
			z-index: 4;
		}
	}
</style>
