<template>
	<base-grid page-title="Login" sidebar="login">

		<div class="content-form block-content">
			<div class="block-form">
				<div class="row">
					<div class="col-6 col-sm-12">
						<template v-if="!userIsLoggedIn">
							<div style="max-width: 260px;">
								<form-login formMode="inline" />
							</div>
						</template>
						<template v-if="userIsLoggedIn">
							<p>Je bent al ingelogd</p>
							<logout-button />
						</template>
					</div>
				</div>
			</div>
		</div>

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormLogin from './FormLogin';
	import LogoutButton from '../LogoutButton';

	export default {
		name: 'PageLogin',
		components: {LogoutButton, FormLogin, BaseGrid},
		data() {
			return {};
		},
		computed: {
			userIsLoggedIn() {
				return this.$store.state.userIsLoggedIn;
			}
		},
		methods: {
			async checkIfUserIsLoggedIn() {
				await this.$store.dispatch('checkLoginStatusWithAuthCookie');
			}
		},
		async mounted() {
			if (this.userIsLoggedIn) {
				await this.checkIfUserIsLoggedIn();
			}
		}
	};
</script>
