<template>
	<base-grid page-title="Gegevens aanpassen" sidebar="admin">

		<div class="block-form-title">
			<h2>Gebruikersgegevens</h2>
		</div>

		<form-user-detail />
	</base-grid>
</template>

<script>
	import FormUserDetail from './FormUserDetail';
	import BaseGrid from '../decorations/BaseGrid';

	export default {
		name: 'PageUserDetail',
		components: {FormUserDetail, BaseGrid}
	};
</script>
