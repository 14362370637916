<template>

	<div class="popup-content">

		<p v-if="isCoach && coachInvited">
			De Verbetercoach is al uitgenodigd om mee te kijken.
		</p>

		<vuelidate-form v-else
		                :is-submitting="isSubmitting"
		                :vuelidate="v$"
		                :warning-message="warningMessage"
		                class="popup-form"
		                @validSubmit="handleSubmit">

			<div class="row">
				<div class="col-6 col-sm-12">
					<label v-if="!isCoach">Gegevens {{ roleLabel }}: *</label>

					<form-input v-if="!isCoach"
					            v-model.trim="v$.form.name.$model"
					            :validation="v$.form.name"
					            hide-label
					            label="Naam"
					            required />

					<form-input v-if="!isCoach"
					            v-model.trim="v$.form.email.$model"
					            :validation="v$.form.email"
					            hide-label
					            label="E-mailadres"
					            required
					            type="email" />

					<form-textarea v-model.trim="v$.form.message.$model"
					               :label="`Bericht aan ${roleLabel}`"
					               :validation="v$.form.message"
					               hide-label
					               input-wrapper-class="input-inline"
					               required />

				</div>
				<div v-if="!isCoach" class="col-6 col-sm-12">
					<label>{{ rightsLabel }}: *</label>
					<div class="checkbox-list">

						<radio-list-item v-model="form.rights"
						                 :value="readonly"
						                 label="Meelezen"
						                 name="rechten" />
						<radio-list-item v-model="form.rights"
						                 :value="write"
						                 label="Meelezen en -werken"
						                 name="rechten" />
						<radio-list-item v-model="form.rights"
						                 :value="admin"
						                 label="Bedrijf administreren"
						                 name="rechten" />
					</div>

					<label>Bij de volgende checks: *</label>
					<div class="checkbox-list">
						<form-checkbox-list :options="checksArray"
						                    :validation="v$.form.checks"
						                    option-label="title"
											option-value="enum"
						                    required
						                    v-model="form.checks" />
					</div>

				</div>
			</div>

			<template v-slot:button-bar>
				<div class="row">
					<div class="col-6 col-sm-12">
						<div class="button-bar">
							<button :class="isSubmitting || v$.$invalid ? 'disabled' : ''"
							        :disabled="isSubmitting || v$.$invalid"
							        class="button"
							        type="submit">
								<span>Stuur uitnodiging</span>
							</button>
						</div>
					</div>
					<div class="col-6 col-sm-12" style="font-size: 1rem;">
						<p class="form-required-notice text-right">
							Velden met * zijn verplicht.
						</p>
					</div>
				</div>
			</template>

		</vuelidate-form>

	</div>

</template>

<script>
	import VuelidateForm from '../forms/VuelidateForm';
	import {email, required} from '@vuelidate/validators';
	import FormInput from '../forms/FormInput';
	import FormTextarea from '../forms/FormTextarea';
	import {checkHelper} from '@/utils/checkHelper';
	import RadioListItem from '../forms/RadioListItem';
	import {Api} from '@/utils/api';
	import {inviteeRole} from '@/types/InviteeRole';
	import {rights} from '@/types/Rights';
	import {useVuelidate} from '@vuelidate/core';
	import FormCheckboxList from '@/components/forms/FormCheckboxList.vue';


	export default {
		name: 'FormInvite',
		components: {FormCheckboxList, RadioListItem, FormTextarea, FormInput, VuelidateForm},

		props: {
			inviteRole: {
				type: String
			},
			coachInvited: {
				type: Boolean,
				default: false
			},
			companyId: {
				type: Number,
				required: true
			},
			isCompanyIdExternal: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			roleLabel() {
				if (this.inviteRole === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'externe adviseur';
				}
				if (this.isCoach) {
					return 'Verbetercoach';
				}

				return 'collega';
			},
			rightsLabel() {
				if (this.inviteRole === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'De externe adviseur mag';
				}
				if (this.isCoach) {
					return 'De verbetercoach mag';
				}

				return 'Je collega mag';
			},
			isCoach() {
				return this.inviteRole === inviteeRole.ROLE_COACH;
			},
			checksArray() {
				return checkHelper.getChecksArray();
			},

			readonly() {
				return rights.READONLY;
			},
			write() {
				return rights.WRITE;
			},
			admin() {
				return rights.ADMIN;
			}
		},

		setup() {
			return {
				v$: useVuelidate()
			};
		},

		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					companyId: null,
					role: '',
					name: '',
					email: '',
					message: '',
					rights: 'READONLY',
					checks: []
				},
				texts: {
					role: 'Gegevens collega',
					readWriteLabel: 'Je collega mag',
					checksLabel: 'Bij de volgende checks:'
				}
			};
		},

		validations() {
			if (this.isCoach) {
				return {
					form: {
						message: {
							required
						}
					}
				};
			}
			return {
				form: {
					name: {
						required
					},
					email: {
						required,
						email
					},
					message: {
						required
					},
					rights: {
						required
					},
					checks: {
						required
					}
				}
			};

		},
		methods: {
			async handleSubmit() {
				this.form.companyId = this.companyId;
				this.form.role = this.inviteRole;
				await this.doApiCall()
					.then(() => {
						this.$emit('close', true);
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Gegevens aanpassen is niet gelukt';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			doApiCall() {
				if (this.inviteRole === inviteeRole.ROLE_COACH) {
					return Api.inviteCoach(this.form, this.companyId, this.isCompanyIdExternal);
				}
				return Api.inviteColleagueOrExternalAdvisor(this.form, this.companyId, this.isCompanyIdExternal);
			}
		}
	};
</script>
