<template>
	<div :style="{width: `${length}ex`}"
	     aria-hidden="true"
	     class="skeleton-text-placeholder"></div>
</template>

<script>
	export default {
		name: 'SkeletonTextPlaceholder',
		props: {
			length: {
				type: [Number, String],
				default: 3
			}
		}
	};
</script>

<style lang="scss">
	.skeleton-text-placeholder {
		height: .8em;
		padding: .2em 0;
		display: inline-block;
		background: currentColor;
		width: 1em;
		border-radius: 2px;
		vertical-align: middle;
		max-width: 100%;

		&:not(:last-child) {
			margin-right: 4px;
		}
	}
</style>
