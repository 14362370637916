<template>
	<div class="block-search-field">
		<form @submit.prevent="">
			<input :aria-label="label"
			       :placeholder="label"
			       :value="modelValue"
			       @input="$emit('update:modelValue', $event.target.value)"
			       class="block-search-field_input"
			       enterkeyhint="search"
			       type="text"
			       ref="search"/>
			<button class="block-search-field_submit" type="submit">
				<i class="icon-search"></i>
			</button>

			<button @click.prevent="clearValue"
			        class="block-search-field_reset button button-no-border"
			        type="button">
				Wis zoekopdracht
			</button>
		</form>
	</div>
</template>

<script>
	export default {
		name: 'BlockSearchField',
		props: {
			modelValue: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				default: 'Zoeken'
			}
		},
		methods: {
			clearValue() {
				this.$emit('update:modelValue', '');
				this.$refs.search.focus();
			}
		}
	};
</script>
