<!-- This component is used as local mock to replace the actual header from the ImprovementCheck app -->
<template>
	<div id="header-main" class="header-fixed-container">
		<header>
			<div class="header-container">
				<button class="header-toggle-leftmenu js-toggle-leftmenu show-when-offcanvasmenu-is-active">
					<i class="icon-hamburger"></i>
					<i class="icon-close"></i>
				</button>

				<div class="header-logo">
					<a href="/site">
						<img alt="5xbeter"
						     src="/generic/assets/img/logo-5xbeter.png"
						     srcset="/generic/assets/img/logo-5xbeter.svg" />
					</a>
				</div>

				<button class="header-toggle-rightmenu js-toggle-rightmenu show-when-offcanvasmenu-is-active">
					<i class="icon-hamburger-user"></i>
					<i class="icon-close"></i>
				</button>

				<link-shoppingcart-header
						class="show-when-offcanvasmenu-is-active"
						style="float: right; padding: 6px; color: white;" />

				<div class="header-nav hide-when-offcanvasmenu-is-active">
					<ul>
						<!-- Menu items of cms should be shown here -->
					</ul>
				</div>

				<div class="header-nav right hide-when-offcanvasmenu-is-active">
					<vijfxbeter-account />
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	export default {
		name: 'vijfxbeter-header'
	};
</script>
