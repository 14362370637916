<template>
	<div>
		<div tabindex="0" v-if="show" vue-dialog>
			<div class="popup-overlay">
				<div class="popup-wrapper">
					<div :aria-label="title"
					     :id="dialogId"
					     class="popup-container"
					     role="dialog"
					     :style="popupContainerStyle"
					     v-show="show">
						<div :class="dialogType === 'warning' ? 'background_red' : ''" class="popup-header">
							<a @click.prevent="closeDialog()"
							   aria-label="dialoog sluiten"
							   class="popup-close"
							   href="#"
							   role="button"><i class="icon-cross"></i></a>
							<h1>{{title}}</h1>
						</div>
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const TAB_KEY = 9;
	const ESCAPE_KEY = 27;
	const FOCUSABLE_ELEMENT_SELECTOR = 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex="0"]:not([disabled])';

	export default {
		name: 'DialogContainer',
		props: {
			title: {
				type: String,
				required: true
			},
			dialogType: {
				type: String
			},
			show: {
				type: Boolean,
				default: false
			},
			dialogId: {
				type: String
			},
			width: {
				type: [Number, String]
			}
		},
		computed: {
			popupContainerStyle() {
				let style = '';

				if (this.width) {
					style = `width: ${this.width}px; max-width: 100%;`;
				}

				return style;
			}
		},
		methods: {
			closeDialog() {
				this.$emit('close');
			},
			bindKeyEvents(event) {
				if (!this.show) {
					return;
				}

				// If the dialog is shown and the ESCAPE key is being pressed, prevent any
				// further effects from the ESCAPE key and hide the dialog.
				if (event.which === ESCAPE_KEY) {
					this.$emit('close');
				}

				if (event.which === TAB_KEY) {
					const dialogElement = document.querySelector('[vue-dialog]');
					this.trapTabKey(dialogElement, event);
				}
			},
			trapTabKey(element, event) {
				const focusableChildren = Array.from(element.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR));
				const focusedItemIndex = focusableChildren.indexOf(document.activeElement);

				// If the SHIFT key is being pressed while tabbing (moving backwards) and
				// the currently focused item is the first one, move the focus to the last
				// focusable item from the dialog element
				if (event.shiftKey && focusedItemIndex === 0) {
					focusableChildren[focusableChildren.length - 1].focus();
					event.preventDefault();
					// If the SHIFT key is not being pressed (moving forwards) and the currently
					// focused item is the last one, move the focus to the first focusable item
					// from the dialog element
				} else if (
					!event.shiftKey &&
					focusedItemIndex === focusableChildren.length - 1
				) {
					focusableChildren[0].focus();
					event.preventDefault();
				}
			},
			setFocusToDialog() {
				// Vue.nextTick doens't seem to work, so setTimeout is used
				setTimeout(() => {
					const confirmButton = document.querySelector('[vue-dialog] .js-button-confirm');

					if (confirmButton) {
						document.querySelector('[vue-dialog] .js-button-confirm').focus();
					} else {
						document.querySelector('[vue-dialog]').querySelector(FOCUSABLE_ELEMENT_SELECTOR).focus();
					}
				}, 100);
			}
		},

		watch: {
			show(open) {
				if (open) {
					document.addEventListener('keydown', this.bindKeyEvents);
					this.setFocusToDialog();
				} else {
					document.removeEventListener('keydown', this.bindKeyEvents);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.popup-overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 8010;
		background: #d6d6d6;
		background: rgba(0, 159, 218, .5);
		overflow: auto;
		overflow-y: scroll;
	}

	.popup-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		max-width: 90%;
		max-height: 90vh;
	}

	.popup-container {
		background: #ffffff;
	}

	[vue-dialog] {
		outline: none;

		button, a {
			outline: unset;
		}
	}
</style>
