import store from '@/store';
import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper';
import physicalStrainRoutes from '@/routes/check/physicalStrainRoutes';
import {personRole} from '@/types/PersonRole';
import {rights} from '@/types/Rights';
import {inviteeRole} from '@/types/InviteeRole';
import {check} from '@/types/Check';
import {pageHelper} from '@/utils/pageHelper';
import PageCheckStart from '@/components/check/PageCheckStart.vue';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: '/check/start',
		name: pageHelper.PAGE_CHECK_START,
		component: PageCheckStart,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Start verbetercheck'
		}
	},
	{
		path: '/check/fysieke-belasting/:companyId',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			inviteeRoles: [
				inviteeRole.ROLE_COACH,
				inviteeRole.ROLE_COLLEAGUE,
				inviteeRole.ROLE_EXTERNAL_ADVISOR,
				inviteeRole.ROLE_COMPANY_OWNER
			],
			check: check.PHYSICAL_STRAIN,
			rights: rights.WRITE,
			breadcrumb(route) {
				const companyId = route.params.companyId;
				return store.getters.companyNameById(companyId);
			}
		},
		children: [
			...physicalStrainRoutes
		]
	}
];
