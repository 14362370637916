import {Api, RM_BASE, RM_COMPANY} from '@/utils/api';

export class PhysicalStrainApi {

	static downloadPdf(url) {
		const target = '_blank';
		const features = 'noopener noreferrer';
		window.open(url, target, features);
	}

	static getPhysicalStrainBaseUrl(companyId) {
		return `${RM_COMPANY}/${companyId}/physicalstrain/`;
	}

	static getPhysicalStrainFunctionGroupBaseUrl(companyId) {
		return this.getPhysicalStrainBaseUrl(companyId) + 'functiongroup';
	}

	static getPhysicalStrainFunctionGroupUrl(companyId, groupId) {
		return this.getPhysicalStrainFunctionGroupBaseUrl(companyId) + `/${groupId}`;
	}

	static getPhysicalStrainResultsForCompany(companyId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + 'result/matrix');
	}

	static getPhysicalStrainResultsForFunctionGroup(companyId, groupId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `functiongroup/${groupId}/result`);
	}

	static getPhysicalStrainPdfForFunctionGroupUrl(companyId, groupId) {
		return this.getPhysicalStrainBaseUrl(companyId) + `functiongroup/${groupId}/result/pdf`;
	}

	static getPhysicalStrainResultsForScript(companyId, scriptKey) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptKey}/result`);
	}

	static getPhysicalStrainPdfForScript(companyId, scriptKey) {
		return this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptKey}/result/pdf`;
	}

	static startPhysicalStrain(companyId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `start`);
	}

	static async getScriptFlow() {
		return Api.get(`${RM_BASE}/physicalstrain/scriptflow`);
	}

	static async getScript(scriptId, companyId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptId}`);
	}

	static async postAnswers(companyId, scriptKey, answers) {
		return Api.post(this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptKey}`, answers);
	}

	static async getCheckSectionAnswers(scriptKey, companyId, checkSectionId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptKey}/sectionanswers?checkSectionId=${checkSectionId}`);
	}

	static async getAnswers(scriptKey, companyId) {
		return Api.get(this.getPhysicalStrainBaseUrl(companyId) + `survey/${scriptKey}/sectionanswers`);
	}

	static async createFunctionGroup(companyId, name) {
		return Api.post(this.getPhysicalStrainFunctionGroupBaseUrl(companyId), {'functionGroupName': name});
	}

	static async getAllFunctionGroups(companyId) {
		return Api.get(this.getPhysicalStrainFunctionGroupBaseUrl(companyId));
	}

	static async deleteFunctionGroup(companyId, sectionId) {
		return Api.delete(this.getPhysicalStrainFunctionGroupUrl(companyId, sectionId));
	}

	static async updateFunctionGroup(companyId, sectionId, name) {
		return Api.put(this.getPhysicalStrainFunctionGroupUrl(companyId, sectionId), {
			'functionGroupName': name
		});
	}
}
