<template>
	<div :class="hasCheck5Header ? '' : 'no-check5-header'">
		<invited-user-notice v-if="invitedRole && invitedRights"
		                     :rights="invitedRights"
		                     :role="invitedRole" />

		<div class="row">
			<slot name="sidebar">
				<sidebar-login v-if="sidebar === 'login'" />
				<sidebar-account v-else-if="sidebar === 'account'" />
				<sidebar-account v-else-if="sidebar === 'admin'" admin-only />
				<sidebar-company v-else-if="sidebar === 'company'" />
				<div v-else class="col-submenu">
					<h2 class="submenu-header">Mijn 5xbeter</h2>
					<ul class="submenu">
						<li>
							<a href="#" @click="$router.go(-1)">
								Terug
							</a>
						</li>
					</ul>
				</div>
			</slot>

			<div class="col-main">
				<block-page-header v-if="pageTitle">{{ pageTitle }}</block-page-header>
				<CheckErrorMessage v-if="error.position === errorPosition.TOP" :error="error" />
				<slot></slot>
				<CheckErrorMessage v-if="error.position === errorPosition.BOTTOM" :error="error" />
			</div>
		</div>
	</div>
</template>

<script>
	import SidebarLogin from './SidebarLogin';
	import SidebarAccount from './SidebarAccount';
	import SidebarCompany from './SidebarCompany';
	import BlockPageHeader from './BlockPageHeader';
	import InvitedUserNotice from '../company/InvitedUserNotice';
	import {checkStoreMethod, errorPosition} from '@/store/check/checkStore';
	import CheckErrorMessage from '@/components/check/CheckErrorMessage';

	export default {
		name: 'BaseGrid',
		components: {
			InvitedUserNotice,
			BlockPageHeader,
			SidebarCompany,
			SidebarAccount,
			SidebarLogin,
			CheckErrorMessage
		},
		props: {
			pageTitle: {
				type: String,
				default: null
			},
			sidebar: {
				type: String,
				// eslint-disable-next-line
				validator: (value) => {
					// The value must match one of these strings
					return ['login', 'account', 'company', 'admin', 'check'].indexOf(value) !== -1;
				}
			}
		},
		computed: {
			errorPosition() {
				return errorPosition;
			},
			invitedRole() {
				return this.$store.getters.invitedRoleByCompanyId(this.companyId);
			},
			invitedRights() {
				return this.$store.getters.readWriteAccess(this.companyId);
			},
			companyId() {
				return this.$route.params.companyId;
			},
			hasCheck5Header() {
				return this.$route.meta.progressStep;
			},
			error() {
				return this.$store.getters[checkStoreMethod.GET_ERROR];
			}
		}
	};
</script>
