<template>
	<div class="warning-block" v-text="message"></div>
</template>

<script>
	export default {
		props: {
			message: {
				type: String,
				default: null
			}
		}
	};
</script>
