<template>
	<div>
		<div v-if="showOrderButton" class="hulpmiddel-item_select-container">
			<label :for="`aantal-select-${id}`">Aantal:</label>
			<div class="custom-select custom-select-blue">
				<select :id="`aantal-select-${id}`"
				        v-model="quantity"
				        :aria-label="`hoeveelheid van ${title}`">
					<option v-for="i in 10"
					        :key="i"
					        :value="`${i}`">{{ i }}
					</option>
				</select>
			</div>
		</div>

		<slot></slot>

		<div class="button-bar">
			<button v-if="showOrderButton"
			        :id="`orderbutton-product-${id}`"
			        :data-product-id="id"
			        class="button"
			        type="button"
			        @click.prevent="addProductToShoppingCart"><i class="icon-shoppingcart2"></i>Bestel
			</button>
			<slot name="buttons"></slot>
		</div>
	</div>
</template>

<script>
import {trackEvent} from '@/utils/googleAnalytics';

export default {
	name: 'ProductWrapper',
	props: {
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		showOrderButton: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			quantity: '1'
		};
	},
	methods: {
		addProductToShoppingCart() {
			let productTitle = this.title;
			let productId = this.id;
			const downloadSelectElement = this.$el.querySelector('.js-download-select');

			if (downloadSelectElement) {
				const selectedOption = downloadSelectElement.options[downloadSelectElement.selectedIndex];

				productTitle += ` - ${selectedOption.text}`;
				productId = selectedOption.getAttribute('data-download-file-id');
			}

			this.$store.commit('shoppingCart/addProduct', {
				id: productId,
				title: productTitle,
				quantity: this.quantity
			});

			// Show the fixed header
			const bodyEl = document.querySelector('body');
			bodyEl.classList.add('show-fixed-header');
			bodyEl.classList.remove('hide-fixed-header');

			// Track order in Google Analytics
			trackEvent('', 'Bestel', productTitle);
		}
	}
};
</script>
