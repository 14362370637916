const addAction = {
	namespaced: true,
	state: () => ({
		show: false,
		companyId: null,
		adviceId: null,
		theme: null
	}),
	mutations: {
		open(state) {
			state.show = true;
		},
		close(state) {
			state.show = false;
		},
		setCompany(state, companyId = null) {
			state.companyId = companyId;
		},
		setAdviceId(state, adviceId = null) {
			state.adviceId = adviceId;
		},
		setTheme(state, theme = null) {
			state.theme = theme;
		}
	},
	actions: {
		openDialog({commit}, customConfig) {
			const config = {
				companyId: null,
				adviceId: null,
				theme: null,
				...customConfig
			};
			this.companyId = config.companyId;
			this.adviceId = config.adviceId;
			this.theme = config.theme;
			commit('setCompany', config.companyId);
			commit('setAdviceId', config.adviceId);
			commit('setTheme', config.theme);
			commit('open');
		},
		closeDialog({commit}) {
			commit('close');
			commit('setCompany', null);
			commit('setAdviceId', null);
			commit('setTheme', null);
		}
	}
};

export default addAction;
