<template>
	<div class="error-wrapper"
	     :class="showTooltip ? 'show-label' : ''">
		<i class="icon-error-notice"
		   tabindex="0"
		   @focusout="hideError"
		   @focusin="showError"></i>
		<label id="username-error"
		       class="error"
		       style=""
		       :for="this.for">
			<slot></slot>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'ErrorWrapper',
		props: {
			for: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				showTooltip: false
			};
		},

		methods: {
			hideError() {
				this.showTooltip = false;
			},
			showError() {
				this.showTooltip = true;
			}
		}
	};
</script>
