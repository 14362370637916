<template>
	<div :style="inlineBlock ? 'display: inline-block;' : ''">
		<label :for="inputId"
		       v-if="label && !hideLabel">
			{{label}}
			<span v-if="required">*</span>
		</label>
		<div class="input-wrapper">
			<input :aria-label="hideLabel ? label : null"
			       :autocomplete="autocomplete"
			       :class="inputClass"
			       :disabled="disabled"
			       :id="inputId"
			       :max="max"
			       :min="min"
			       :name="name"
			       :pattern="datePattern"
			       :placeholder="datePlaceholder"
			       :readonly="readonly"
			       :required="required"
			       :step="step"
			       :value="modelValue"
			       @input="updateValue"
			       ref="inputElement"
			       type="date">

			<error-wrapper :for="inputId" v-if="validation && validation.$error">
				{{validationMessage}}
			</error-wrapper>

			<slot name="after-input"></slot>
		</div>
	</div>
</template>

<script>
	import ErrorWrapper from './ErrorWrapper';
	import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
	import {supportsInputTypeDate} from '@/utils/featureDetection';
	import {getDateWithoutTime} from '@/utils/date';

	import Pikaday from 'pikaday';
	import {createUniqueId} from '@/utils/uid';

	export default {
		name: 'FormDatepicker',
		components: {ErrorWrapper},
		props: {
			modelValue: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			readonly: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			validation: {
				type: Object
			},
			id: {
				type: String
			},
			placeholder: {
				type: String
			},
			name: {
				type: String
			},
			min: {
				type: [String]
			},
			max: {
				type: [String]
			},
			step: {
				type: [Number, String]
			},
			inputClass: {
				type: String
			},
			hideLabel: {
				type: Boolean,
				default: false
			},
			autocomplete: {
				type: String
			},
			inlineBlock: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				showTooltip: false,
				inputTypeDateSupported: true,
				uid: createUniqueId()
			};
		},

		computed: {
			inputId() {
				return this.id ?? `input-id-${this.uid}`;
			},

			inputPlaceholder() {
				if (this.hideLabel) {
					return this.placeholder ?? this.label;
				}

				return this.placeholder;
			},

			validationMessage() {
				const validationObject = this.validation;
				if (validationObject === null) {
					return null;
				}
				return getFirstInvalidValidationMessage(validationObject);
			},
			datePattern() {
				if (this.inputTypeDateSupported) {
					return null;
				}

				return '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])';
			},
			datePlaceholder() {
				if (this.inputTypeDateSupported) {
					return this.inputPlaceholder;
				}
				return 'jjjj-mm-dd';
			}
		},

		methods: {
			hideError() {
				this.showTooltip = false;
			},
			showError() {
				this.showTooltip = true;
			},
			initPikaday() {
				/* Datepicker is only used for browsers that don't have a native Datepicker */
				if (this.inputTypeDateSupported) {
					return;
				}
				const datePicker = new Pikaday({
					field: this.$refs.inputElement,
					format: 'DD-MM-YYYY',
					keyboardInput: false,
					i18n: {
						previousMonth: 'Vorige maand',
						nextMonth: 'Volgende maand',
						months: [
							'Januari',
							'Februari',
							'Maart',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Augustus',
							'September',
							'Oktober',
							'November',
							'December'
						],
						weekdays: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
						weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']
					},
					toString(date) {
						let day = date.getDate();
						let month = date.getMonth() + 1;
						day = day < 10 ? `0${day}` : day;
						month = month < 10 ? `0${month}` : month;
						const year = date.getFullYear();
						return `${day}-${month}-${year}`;
					},
					parse(dateString) {
						const parts = dateString.split('-');
						const day = parseInt(parts[0], 10);
						const month = parseInt(parts[1], 10) - 1;
						const year = parseInt(parts[2], 10);
						return new Date(year, month, day);
					},
					onSelect: () => {
						this.$emit('input', this.getDateWithoutTime(datePicker.getDate()));
					}
				});

				if (this.min) {
					datePicker.setMinDate(new Date(this.min));
				}
				if (this.max) {
					datePicker.setMaxDate(new Date(this.max));
				}
			},
			updateValue(event) {
				if (this.inputTypeDateSupported) {
					this.$emit('update:modelValue', event.target.value);
				}
			},
			getDateWithoutTime
		},
		mounted() {
			this.inputTypeDateSupported = supportsInputTypeDate();
			this.initPikaday();
		}
	};
</script>

<style lang="scss">
	@import '~pikaday/css/pikaday.css';
</style>
