import {PhysicalStrainApi} from '@/components/check/physicalstrain/physicalApi';

export const checkType = Object.freeze({
	CHECK: 'CHECK',
	REGISTER: 'REGISTER'
});

// These are the same constants and names as used in the java enum CheckStatus.
export const checkStatus = Object.freeze({
	RED: {
		enum: 'RED'
	},
	ORANGE: {
		enum: 'ORANGE'
	},
	GREEN: {
		enum: 'GREEN'
	},
	GREY: {
		enum: 'GREY'
	},
	WHITE: {
		enum: 'WHITE'
	}
});

export const checkStatusArray = [
	checkStatus.RED.enum,
	checkStatus.ORANGE.enum,
	checkStatus.GREY.enum,
	checkStatus.GREEN.enum,
	checkStatus.WHITE.enum
];

export const isValidCheckStatus = status => checkStatusArray.indexOf(status) !== -1;

export const checkScriptType = Object.freeze({
	INFORMATION: 'INFORMATION',
	SURVEY: 'SURVEY'
});

export const checkHelper = Object.freeze({
	// These are the same constants and names as used in the java enum Check.
	WELDING_SMOKE: {
		enum: 'WELDING_SMOKE',
		title: 'Lasrook',
		type: checkType.CHECK
	},
	MACHINE_SAFETY: {
		enum: 'MACHINE_SAFETY',
		title: 'Machineveiligheid',
		type: checkType.CHECK
	},
	NOISE_HAZARD: {
		enum: 'NOISE_HAZARD',
		title: 'Schadelijk geluid',
		type: checkType.CHECK
	},
	PHYSICAL_STRAIN: {
		enum: 'PHYSICAL_STRAIN',
		title: 'Fysieke belasting',
		type: checkType.CHECK
	},
	TEMP_WORK_AT_HEIGHT: {
		enum: 'TEMP_WORK_AT_HEIGHT',
		title: 'Tijdelijk werken op hoogte',
		type: checkType.CHECK
	},
	HAZARDOUS_SUBSTANCES: {
		enum: 'HAZARDOUS_SUBSTANCES',
		title: 'Gevaarlijke stoffen',
		type: checkType.REGISTER
	},
	getChecksArray() {
		return [
			this.WELDING_SMOKE,
			this.MACHINE_SAFETY,
			this.NOISE_HAZARD,
			this.PHYSICAL_STRAIN,
			this.TEMP_WORK_AT_HEIGHT,
			this.HAZARDOUS_SUBSTANCES
		];
	},
	getTitle(checkEnum) {
		return this[checkEnum].title;
	},
	getType(checkEnum) {
		return this[checkEnum].type;
	},
	isPhysicalStrain(checkEnum) {
		return this.PHYSICAL_STRAIN.enum === checkEnum;
	},
	isWeldingSmoke(checkEnum) {
		return this.WELDING_SMOKE.enum === checkEnum;
	},
	isTempWorkAtHeight(checkEnum) {
		return this.TEMP_WORK_AT_HEIGHT.enum === checkEnum;
	},
	isValidCheck(checkEnum) {
		return [
			checkHelper.WELDING_SMOKE.enum,
			checkHelper.MACHINE_SAFETY.enum,
			checkHelper.NOISE_HAZARD.enum,
			checkHelper.PHYSICAL_STRAIN.enum,
			checkHelper.TEMP_WORK_AT_HEIGHT.enum,
			checkHelper.HAZARDOUS_SUBSTANCES.enum
		].indexOf(checkEnum) !== -1;
	},

	async startCheck(companyId, checkEnum) {
		if (this.PHYSICAL_STRAIN.enum === checkEnum) {
			await PhysicalStrainApi.startPhysicalStrain(companyId);
		}
	}
});

/**
 * When combining different statuses, determine if it should be applied
 * @param {string} appliedStatus
 * @param {string} newStatus
 * @returns {boolean}
 */
export const shouldUpdateStatus = (appliedStatus, newStatus) => {
	if (newStatus === checkStatus.GREY.enum) {
		return true;
	}

	if (newStatus === checkStatus.WHITE.enum && !appliedStatus) {
		return true;
	}

	if (newStatus === checkStatus.RED.enum && appliedStatus !== checkStatus.GREY.enum) {
		return true;
	}

	return newStatus === checkStatus.GREEN.enum && appliedStatus === checkStatus.WHITE.enum;
};
