/**
 * Checks if input[type=date] is supported by the browser
 * @return {boolean}
 */
export const supportsInputTypeDate = () => {
	const input = document.createElement('input');
	input.setAttribute('type', 'date');

	const notADateValue = 'not-a-date';
	input.setAttribute('value', notADateValue);

	return (input.value !== notADateValue);
};
