<template>
	<table class="admin-user-overview">
		<caption>Gebruikersoverzicht</caption>
		<thead>
			<tr>
				<th scope="col" style="width: 14em;">Voornaam</th>
				<th scope="col" style="">Achternaam</th>
				<th scope="col" style="width: 12em;">E-mailadres</th>
				<th scope="col" style="width: 12em;">Rollen</th>
				<th scope="col" style="width: 6em;">Nieuwsbrief</th>
			</tr>
		</thead>
		<tbody>
			<tr :key="user.id"
			    @click="goToUserDetail(user.id)"
			    v-for="user in users">
				<td>
					{{ user.firstName }}
				</td>
				<td>
					{{ user.lastName }}
					<span v-if="user.middleName">({{ user.middleName }})</span>
				</td>
				<td>
					<router-link :title="user.emailAddress"
					             :to="linkUserDetail(user.id)"
					             class="admin-user-overview__email">
						{{ user.emailAddress }}
					</router-link>
				</td>
				<td>
					<div :key="role" v-for="role in user.roles">
						{{ getPersonRoleFromEnum(role) }}
					</div>
				</td>
				<td>
					{{ user.receivesNewsletter ? 'Ja' : 'Nee' }}
				</td>
			</tr>

			<tr v-if="users.length === 0">
				<td colspan="5">Geen gebruikers gevonden.</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import {getPersonRoleFromEnum} from '@/utils/personRoleHelper';

	export default {
		name: 'UserListTable',
		props: {
			users: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			linkUserDetail(id) {
				return {
					name: pageHelper.PAGE_ADMIN_USER_DETAIL,
					params: {
						userId: id
					}
				};
			},
			goToUserDetail(id) {
				this.$router.push(this.linkUserDetail(id));
			},
			getPersonRoleFromEnum
		}
	};
</script>

<style lang="scss">
	.admin-user-overview {
		background: #f7f7f7;
		margin: 0 0 20px;
		width: 100%;
		table-layout: fixed;

		th, td {
			padding-left: 0;
			padding-right: 1em;
			font-size: 12px;

			&:first-child {
				padding-left: 20px;
			}
		}

		thead {
			th {
				font-size: 12px;
			}
		}

		tbody {
			td, th {
				border-top: 2px solid #eeeeee;
				word-break: break-word;
			}

			tr {
				cursor: pointer;

				&:hover {
					background: #e3e3e3;
				}
			}
		}

		caption {
			display: none;
		}

		&__email {
			max-width: 14em;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			text-decoration: none;
			color: inherit;
			display:block
		}
	}
</style>
