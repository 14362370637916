<template>
	<skeleton-block>
		<div class="block-company">
			<div class="block-company_title">
				<h2><strong>
					<skeleton-text-placeholder length="13" />
				</strong></h2>
			</div>

			<div :key="i" class="block-company_check-status" v-for="i in 6">
				<svg height="30" viewBox="0 0 48 30" width="48">
					<circle cx="30" cy="15" fill="#aaa" r="15" />
					<circle cx="15" cy="15" fill="#000" r="15" />
				</svg>
			</div>
		</div>
	</skeleton-block>
</template>

<script>
	import SkeletonBlock from '../generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '../generic/SkeletonTextPlaceholder';

	export default {
		name: 'SkeletonCompanyListItem',
		components: {SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
