<template>
	<base-grid :page-title="pageTitle" sidebar="account">

		<block-search-field v-model="searchValue" label="Zoek op naam of emailadres" />

		<skeleton-card-item v-if="loading" :lines="5" :lines-width="[90]" />

		<template v-if="!loading">
			<p v-if="showNoResults">
				Geen gebruikers gevonden.
			</p>
			<page-pagination v-slot:="slotProps" :items="filteredUsers" :items-per-page="20">
				<user-list-table :users="slotProps.itemsOnPage" />
			</page-pagination>
		</template>

		<div v-if="warningMessage">
			{{ warningMessage }}
		</div>

	</base-grid>
</template>

<script>
import BaseGrid from '../decorations/BaseGrid';
import {Api} from '@/utils/api';
import UserListTable from './UserListTable';
import {getFilteredArray, includesTextInKeys} from '@/utils/filter';
import BlockSearchField from '../filters/BlockSearchField';
import PagePagination from '../generic/PagePagination';
import SkeletonCardItem from '@/components/generic/SkeletonCardItem';

export default {
	name: 'PageUsersOverview',
	components: {SkeletonCardItem, BlockSearchField, UserListTable, BaseGrid, PagePagination},
	data() {
		return {
			loading: true,
			warningMessage: '',
			users: [],
			searchValue: ''
		};
	},

	computed: {
		filteredUsers() {
			const filters = [
				...includesTextInKeys(this.searchValue, ['emailAddress', 'fullName'])
			];
			return getFilteredArray(this.users, filters);
		},
		showNoResults() {
			return this.filteredUsers.length === 0 && this.users.length > 0;
		},
		pageTitle() {
			const title = 'Gebruikersoverzicht';
			if (this.loading) {
				return title;
			}
			if (this.searchValue.length === 0) {
				return `${title} (${this.users.length})`;
			}
			return `${title} (${this.filteredUsers.length} / ${this.users.length})`;
		}
	},

	methods: {
		async loadUsers() {
			this.warningMessage = '';
			await Api.getAllUsers()
				.then((response) => {
					this.users = response.responseObject;
				})
				.catch((error) => {
					if (error.message && error.message.length > 0) {
						this.warningMessage = error.message;
						return;
					}
					this.warningMessage = 'Overzicht van gebruikers kan niet geladen worden.';
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	mounted() {
		this.loadUsers();
	}
};
</script>
