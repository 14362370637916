<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{warningMessage}}
		</p>

		<div class="block-container" title="Overzicht wordt geladen" v-if="loading">
			<skeleton-card-item :key="i" :lines="4" v-for="i in 3" />
		</div>

		<actions-list :actions="actions" v-if="!loading" />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {Api} from '@/utils/api';
	import ActionsList from './ActionsList';
	import SkeletonCardItem from '../generic/SkeletonCardItem';

	export default {
		name: 'PageActionOverview',
		components: {SkeletonCardItem, ActionsList, BaseGrid},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				actions: []
			};
		},
		computed: {
			pageTitle() {
				return `Actiepunten voor ${this.$store.state.company.current.name}`;
			}
		},
		methods: {
			async loadCompanyActionsData() {
				await Api.getCompanyActions(this.companyId)
					.then((response) => {
						this.actions = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Actiepunten konden niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadCompanyActionsData();
			},
			resetPage() {
				this.actions = [];
			}
		},
		watch: {
			$route() {
				this.resetPage();
				this.initPage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
