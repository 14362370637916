<template>
	<base-grid page-title="Nieuw bedrijfsdossier item aanmaken" sidebar="company">
		<card-block>
			<form-company-record :company-id="companyId"
			                     is-new-company-record
			                     @cancel="goToOverview" />
		</card-block>
	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {pageHelper} from '@/utils/pageHelper';
	import CardBlock from '@/components/generic/CardBlock';
	import FormCompanyRecord from '@/components/companyrecords/FormCompanyRecord';

	export default {
		name: 'PageAddCompanyRecord',
		components: {
			FormCompanyRecord,
			CardBlock,
			BaseGrid
		},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		methods: {
			goToOverview() {
				this.$router.push({
					name: pageHelper.PAGE_COMPANY_RECORDS_OVERVIEW,
					params: {
						companyId: this.companyId
					}
				});
			}
		}
	};
</script>
