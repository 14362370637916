import {createApp} from 'vue';
import ConfirmDialog from '@/plugins/confirmDialog/ConfirmDialog.vue';

const Plugin = {};

Plugin.install = (app) => {

	const $el = document.createElement('div');
	$el.innerHTML = `<confirm-dialog ref="instance"/>`;
	document.body.appendChild($el);

	const dialogApp = createApp({});
	dialogApp.component('confirm-dialog', ConfirmDialog);
	const instance = dialogApp.mount($el).$refs.instance;

	app.config.globalProperties.$confirmDialog = (options = {}) => new Promise((resolve, reject) => {
		const defaultConfirmSettings = {
			confirmButton: 'Ja, ik weet het zeker',
			title: 'Weet je het zeker?'
		};
		instance.openConfirmDialog({...defaultConfirmSettings, ...options});
		instance.resolveConfirmDialog = (confirmed) => {
			instance.show = false;
			return confirmed ? resolve(true) : reject(false);
		};
	});

	app.config.globalProperties.$alertDialog = (options = {}) => new Promise((resolve) => {
		const defaultAlertSettings = {
			confirmButton: 'Oke',
			cancelButton: ''
		};
		instance.openConfirmDialog({...defaultAlertSettings, ...options});
		instance.resolveConfirmDialog = () => {
			instance.show = false;
			return resolve(true);
		};
	});
};

export default Plugin;
