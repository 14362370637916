<template>
	<div :style="inlineBlock ? 'display: inline-block;' : ''">
		<label v-if="label && !hideLabel"
		       :for="inputId">
			{{ label }}
			<span v-if="required">*</span>
		</label>
		<div class="input-wrapper">
			<input :id="inputId"
			       :aria-label="hideLabel ? label : null"
			       :autocomplete="autocomplete"
			       :class="inputClass"
			       :disabled="disabled"
			       :inputmode="inputmode"
			       :max="max"
			       :maxlength="maxlength"
			       :min="min"
			       :minlength="minlength"
			       :name="name"
			       :placeholder="inputPlaceholder"
			       :readonly="readonly"
			       :required="required"
			       :step="step"
			       :type="type"
			       :value="modelValue"
			       @input="$emit('update:modelValue', $event.target.value)">

			<error-wrapper v-if="validation && validation.$error" :for="inputId">
				{{ validationMessage }}
			</error-wrapper>

			<slot name="after-input"></slot>
		</div>
	</div>
</template>

<script>
import ErrorWrapper from './ErrorWrapper';
import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
import {createUniqueId} from '@/utils/uid';

export default {
	name: 'FormInput',
	components: {ErrorWrapper},
	props: {
		modelValue: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		validation: {
			type: Object
		},
		id: {
			type: String
		},
		placeholder: {
			type: String
		},
		type: {
			type: String,
			default: 'text'
		},
		name: {
			type: String
		},
		minlength: {
			type: [Number, String]
		},
		maxlength: {
			type: [Number, String]
		},
		min: {
			type: [Number, String]
		},
		max: {
			type: [Number, String]
		},
		step: {
			type: [Number, String]
		},
		inputmode: {
			type: String
		},
		inputClass: {
			type: String
		},
		hideLabel: {
			type: Boolean,
			default: false
		},
		autocomplete: {
			type: String
		},
		inlineBlock: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		inputId() {
			return this.id ?? `input-id-${this.uid}`;
		},

		inputPlaceholder() {
			if (this.hideLabel) {
				return this.placeholder ?? this.label;
			}

			return this.placeholder;
		},

		validationMessage() {
			const validationObject = this.validation;
			if (validationObject === null) {
				return null;
			}
			return getFirstInvalidValidationMessage(validationObject);
		}
	},
	data() {
		return {
			showTooltip: false,
			uid: createUniqueId()
		};
	},

	methods: {
		hideError() {
			this.showTooltip = false;
		},
		showError() {
			this.showTooltip = true;
		}
	}
};
</script>
