<template>
	<div class="block-container">
		<transition-group name="block-company-list" tag="div">
			<company-list-item v-for="company in companies"
			                   :company="company"
			                   :key="company.id" />
		</transition-group>

		<p v-if="companies.length === 0">
			Geen bedrijven.
		</p>
	</div>
</template>

<script>
	import CompanyListItem from './CompanyListItem';

	export default {
		name: 'CompanyList',
		components: {CompanyListItem},
		props: {
			companies: {
				type: Array,
				default: () => []
			}
		}
	};
</script>

<style>
	.block-company-list-enter-active,
	.block-company-list-leave-active {
		transition: all .15s;
	}
	.block-company-list-enter,
	.block-company-list-leave-to{
		opacity: 0;
		transform: scale(.99);
	}
</style>
