<template>
	<div>
		<div class="submenu_company-container">
			<div class="submenu_company-header">
				<div class="submenu_company-icon">
					Verbeter
					<icon-check-of5-check :height="22" :width="27" color="#FFFFFF" />
				</div>
			</div>
			<ul class="submenu">
				<li>
					<router-link :to="{ name: pageHelper.PAGE_COMPANY_IMPROVE}">
						Stap 5: Verbeter
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_COMPANY_ADVICE_OVERVIEW, params: { companyId: companyId }}">
						Adviezen
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_COMPANY_ACTION_OVERVIEW, params: { companyId: companyId }}">
						Actiepunten
					</router-link>
				</li>
				<li v-if="showCompanyRecordsMenu">
					<router-link :to="{ name: pageHelper.PAGE_COMPANY_RECORDS_OVERVIEW, params: { companyId: companyId }}">
						Bedrijfsdossier
					</router-link>
				</li>
				<li v-if="showVisitReportMenu">
					<router-link :to="{ name: pageHelper.PAGE_COMPANY_VISIT_REPORT_OVERVIEW, params: { companyId: companyId }}">
						Bezoekverslagen
					</router-link>
				</li>
			</ul>
		</div>

		<ul v-if="showCompanyActionSubmenu" class="submenu">
			<li class="submenu-transparent-link">
				<br>&nbsp;
			</li>
			<li v-if="showAddCompanyRecordButton" class="submenu-transparent-link">
				<router-link :to="{ name: pageHelper.PAGE_COMPANY_RECORDS_NEW, params: { companyId: companyId}}">
					<em class="icon-chevron-right"></em>
					Dossier item toevoegen
				</router-link>
			</li>
			<li v-if="showAddAdviceButton" class="submenu-transparent-link">
				<a href="#" @click.prevent="openAddAdviceModal">
					<em class="icon-chevron-right"></em>
					Advies toevoegen
				</a>
			</li>
			<li v-if="showAddActionButton" class="submenu-transparent-link">
				<a href="#" @click.prevent="openAddActionModal">
					<em class="icon-chevron-right"></em>
					Actiepunt toevoegen
				</a>
			</li>
			<li v-if="showAddVisitReportButton" class="submenu-transparent-link">
				<router-link :to="{ name: pageHelper.PAGE_COMPANY_VISIT_REPORT_NEW, params: { companyId: companyId}}">
					<em class="icon-chevron-right"></em>
					Bezoekverslag toevoegen
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import IconCheckOf5Check from '@/components/icons/IconCheckOf5Check';

	export default {
		name: 'SidebarStep5Menu',
		components: {IconCheckOf5Check},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			},
			pageHelper() {
				return pageHelper;
			},
			showAddCompanyRecordButton() {
				return (this.$route.name === pageHelper.PAGE_COMPANY_RECORDS_OVERVIEW
					&& (this.userIsOwnerOrAdminForCompany || this.isCoachAndCoachInvited));
			},
			showAddAdviceButton() {
				return ((
						this.$route.name === pageHelper.PAGE_COMPANY_ADVICE_OVERVIEW ||
						this.$route.name === pageHelper.PAGE_COMPANY_ADVICE_DETAIL
					)
					&& (this.userIsOwnerOrAdminForCompany || this.isCoachAndCoachInvited));
			},
			showAddActionButton() {
				return ((
						this.$route.name === pageHelper.PAGE_COMPANY_ACTION_OVERVIEW ||
						this.$route.name === pageHelper.PAGE_COMPANY_ACTION_DETAIL
					)
					&& (this.userIsOwnerOrAdminForCompany || this.isCoachAndCoachInvited));
			},
			isCoachAndCoachInvited() {
				return this.$store.getters.isUserInvitedAsCoachByCompany(this.companyId) && this.$store.getters.userHasRoleCoach();
			},
			showAddVisitReportButton() {
				const visitReportPage = (
					this.$route.name === pageHelper.PAGE_COMPANY_VISIT_REPORT_DETAIL ||
					this.$route.name === pageHelper.PAGE_COMPANY_VISIT_REPORT_OVERVIEW
				);
				return visitReportPage && this.isCoachAndCoachInvited;
			},
			showVisitReportMenu() {
				return this.userIsOwnerOrAdminForCompany || this.isCoachAndCoachInvited;
			},
			showCompanyRecordsMenu() {
				return this.userIsOwnerOrAdminForCompany || this.isCoachAndCoachInvited;
			},
			showCompanyDetailsMenu() {
				return this.userIsOwnerForCompany;
			},
			userIsOwnerForCompany() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			userIsOwnerOrAdminForCompany() {
				return this.$store.getters.isUserCompanyOwnerOrAdmin(this.companyId);
			},
			showCompanyActionSubmenu() {
				return this.showAddCompanyRecordButton || this.showAddAdviceButton || this.showAddActionButton || this.showAddActionButton || this.showAddVisitReportButton;
			}
		},
		methods: {
			openAddAdviceModal() {
				this.$store.dispatch('modals/addAdvice/openDialog', {
					companyId: this.companyId
				});
			},
			openAddActionModal() {
				this.$store.dispatch('modals/addAction/openDialog', {
					companyId: this.companyId
				});
			}
		}
	};
</script>
