<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{warningMessage}}
		</p>

		<card-block>
			<form-visit-report @cancel="goToOverview()"
			                   @submitted="goToOverview()"
			                   new-visit-report />
		</card-block>

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import CardBlock from '../generic/CardBlock';
	import FormVisitReport from './FormVisitReport';
	import {pageHelper} from '../../utils/pageHelper';

	export default {
		name: 'PageVisitReportNew',
		components: {FormVisitReport, CardBlock, BaseGrid},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: ''
			};
		},
		computed: {
			pageTitle() {
				return `Nieuw bezoekverslag voor ${this.$store.state.company.current.name}`;
			}
		},
		methods: {
			goToOverview() {
				this.$router.push({
					name: pageHelper.PAGE_COMPANY_VISIT_REPORT_OVERVIEW,
					params: {
						companyId: this.companyId
					}
				});
			}
		}
	};
</script>
