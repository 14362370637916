/**
 * Function to get the text, or a fallback if the text is empty
 * @param {string} text - Text to be shown (if not empty)
 * @param {string} [fallback=—] - What to return if 'text' is empty
 * @return {string}
 */
export const getTextOrFallback = (text, fallback = '—') => {
	if (text && text.length > 0) {
		return text;
	}

	return fallback;
};

/**
 * Function to prevent xss.
 * @param {string} text Text to be encoded
 * @returns {string}
 */
export const encodeWithHtmlEntities = (text) => {
	const tagsToReplace = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'\'': '&apos;',
		'"': '&quot;'
	};
	return text.replace(/[&<>'"]/g, tag => tagsToReplace[tag]);
};

/**
 * Function to replace a key such as ${functiongroup} with the correct value.
 * @param {string} text - Text to be shown
 * @param {string} key - Key to be replaced
 * @param {string} value - Value the key needs to be replaced with
 * @param {string?} prefix - Prefix for the replacement value (without xss prevention)
 * @param {string?} postfix - Postfix for the replacement value (without xss prevention)
 * @returns {string}
 */
export const replaceTag = (text, key, value, prefix, postfix) => {
	const valueWithHtmlEntities = encodeWithHtmlEntities(value);
	const replacementString = (prefix ?? '') + valueWithHtmlEntities + (postfix ?? '');
	return text.replace(key, replacementString);
};

/**
 * Get readable text from a boolean value
 * @param {boolean} variable - Value to check if true or false
 * @param {string} [fallback=—] - What to return if not a variable is not a boolean
 * @return {string}
 */
export const getTextForBoolean = (variable, fallback = '-') => {
	if (typeof variable !== 'boolean') {
		return fallback;
	}

	return variable ? 'Ja' : 'Nee';
};

/**
 * Turn an array of strings to a string where each item is comma seperated and the last item is prefixed with 'and'
 * @param {string[]} array - Arreay of strings as input
 * @param {string} [textBeforeLastItem=] - Word to be used before the last item (something like 'and'), doesn't do anything if string is empty
 * @return {string}
 */
export const arrayToCommaSeparatedString = (array, textBeforeLastItem = '') => {
	const showAndBeforeLastItem = textBeforeLastItem && textBeforeLastItem.length > 0;
	const hideCommaForIndex = showAndBeforeLastItem ? array.length - 2 : array.length - 1;
	const addPrefixForIndex = array.length - 1;

	let text = '';
	array.forEach((item, index) => {
		const showComma = index < hideCommaForIndex;
		const addPrefix = index === addPrefixForIndex && array.length > 1;

		if (showAndBeforeLastItem && addPrefix) {
			text += `${textBeforeLastItem} `;
		}

		text += `${item}${showComma ? ',' : ''} `;
	});

	return text.trim();
};
