<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                @cancel="$emit('cancel')"
	                @validSubmit="handleSubmit"
	                cancel-button-text="Annuleren"
	                class="content-form content-form--edit-action"
	                submit-button-text="Actiepunt opslaan">

		<form-checkbox-list :options="themeOptions"
		                    :validation="v$.form.themes"
		                    label="Thema's"
		                    multicolumn
		                    option-label="title"
		                    option-value="enum"
		                    required
		                    v-model="v$.form.themes.$model" />

		<form-textarea :validation="v$.form.description"
		               label="Actiepunt"
		               required
		               v-model.trim="v$.form.description.$model" />

		<form-input label="Actiehouder"
		            v-model.trim="form.actionHolder" />

		<form-datepicker :validation="v$.form.deadline"
		                 label="Deadline"
		                 v-model.trim="v$.form.deadline.$model" />

	</vuelidate-form>

</template>

<script>
	import {Api} from '@/utils/api';
	import VuelidateForm from '../forms/VuelidateForm';
	import {themes} from '@/utils/themeHelper';
	import FormTextarea from '../forms/FormTextarea';
	import FormCheckboxList from '../forms/FormCheckboxList';
	import {pageHelper} from '@/utils/pageHelper';
	import {required} from '@vuelidate/validators';
	import {validDate} from '@/utils/customValidators';
	import FormDatepicker from '@/components/forms/FormDatepicker';
	import FormInput from '@/components/forms/FormInput';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormAction',
		components: {FormCheckboxList, FormTextarea, VuelidateForm, FormDatepicker, FormInput},
		props: {
			action: {
				type: Object,
				default: () => ({})
			},
			newAction: {
				type: Boolean,
				default: false
			},
			adviceId: {
				type: String,
				default: null
			}
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					themes: [],
					description: null,
					actionHolder: '',
					deadline: null
				}
			};
		},
		validations: {
			form: {
				themes: {
					required
				},
				description: {
					required
				},
				deadline: {
					validDate
				}
			}
		},
		computed: {
			themeOptions() {
				return themes;
			},
			companyId() {
				return this.$route.params.companyId;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await this.doApiCall()
					.then(() => {
						this.$emit('submitted');
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Actiepunt kon niet worden opgeslagen';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			initFormObject() {
				let actionNew = {};

				if (this.newAction) {
					actionNew = {
						themes: [],
						companyId: this.companyId,
						adviceId: this.adviceId
					};
				}

				this.form = {...this.form, ...this.action, ...actionNew};
			},
			doApiCall() {
				if (this.newAction) {
					return Api.postAddAction(this.companyId, this.form)
						.then((response) => {
							if (response.responseObject.id) {
								this.$router.push({
									name: pageHelper.PAGE_COMPANY_ACTION_DETAIL,
									params: {
										actionId: response.responseObject.id
									}
								});
							}
						});
				}
				return Api.postUpdateAction(this.companyId, this.action.id, this.form);
			}
		},
		mounted() {
			this.initFormObject();
		}
	};
</script>
