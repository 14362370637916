<script>
	export default {
		name: 'BuildInfoConsole',
		mounted() {
			if (process.env.VUE_APP_ADD_BUILD_INFO) {
				const buildInfo = {
					env: process.env.NODE_ENV,
					mode: process.env.VUE_APP_MODE,
					branche: process.env.VUE_APP_BUILD_INFO_BRANCHE,
					lastCommitHash: process.env.VUE_APP_BUILD_INFO_COMMIT_HASH,
					lastCommitTime: process.env.VUE_APP_BUILD_INFO_COMMIT_TIME,
					buildTimestamp: process.env.VUE_APP_BUILD_INFO_TIME
				};

				if (typeof console.table === 'function') {
					console.table(buildInfo);
				} else {
					console.log(buildInfo);
				}
			}
		},
		render: () => ''
	};
</script>
