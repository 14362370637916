<template>
	<div class="col-submenu">
		<h2 class="submenu-header">Mijn 5xbeter</h2>
		<ul class="submenu">
			<template v-if="isVijfxbeterAdmin">
				<li>
					<router-link :to="{ name: pageHelper.PAGE_ADMIN_USERS_OVERVIEW}">
						Gebruikersbeheer
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_ADMIN_COMPANIES_OVERVIEW}">
						Bedrijvenbeheer
					</router-link>
				</li>
			</template>
			<template v-if="!adminOnly">
				<li class="submenu-transparent-link">
					<br>&nbsp;
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_MY_COMPANIES}">
						Mijn Bedrijven
					</router-link>
				</li>
				<li class="submenu-transparent-link">
					<router-link :to="{ name: pageHelper.PAGE_ADD_COMPANY}" active-class="">
						<i class="icon-chevron-right"></i>
						Bedrijf toevoegen
					</router-link>
				</li>
				<li class="submenu-transparent-link">
					<br>&nbsp;
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_MY_ORDERS}">
						Mijn bestellingen
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: pageHelper.PAGE_MY_ACCOUNT}">
						Mijn gegevens
					</router-link>
				</li>
			</template>

		</ul>
	</div>
</template>

<script>
import {pageHelper} from '../../utils/pageHelper';
import {personRole} from '@/types/PersonRole';

export default {
	name: 'SidebarAccount',
	props: {
		adminOnly: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		pageHelper() {
			return pageHelper;
		},
		isVijfxbeterAdmin() {
			return this.$store.getters.userHasRole(personRole.ROLE_VIJFXBETER_ADMIN);
		}
	}
};
</script>
