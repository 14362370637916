<template>
	<svg :height="height" :viewBox="'0 0 ' + width + ' ' + height" :width="width" xmlns="http://www.w3.org/2000/svg">
		<path :fill="color"
		      d="M0 11.231L7.503 19 23.41 3 21.343.892 7.503 14.754 2.04 9.108z"
		      fill-rule="nonzero" />
	</svg>
</template>

<script>
	export default {
		name: 'IconCheckOf5Check.vue',
		props: {
			height: {
				type: Number,
				default: 19
			},
			width: {
				type: Number,
				default: 24
			},
			color: {
				type: String,
				default: '#3ccaff'
			}
		}
	};
</script>
