export const filterType = Object.freeze({
	INCLUDES_TEXT: 'includesText',
	MATCHES_VALUE: 'matchValueToKeys'
});

/**
 * Normalize text by making it lowercase and trim whitespace
 * @param {String} text - Text to be normalized
 * @return {String}
 */
const normalizeStringForSearch = (text = '') => text?.toLowerCase().trim() ?? '';

/**
 * Checks if all filters are empty, or don't have a value that's usable
 * @param {Array.<{value: *}>} filters - Array of filters to be applied
 * @return {Boolean}
 */
const allFiltersAreEmpty = (filters = []) => {
	if (filters.length === 0) {
		return true;
	}
	return filters.every(filter => filter.value === null || filter.value.length === 0);
};

/**
 * returns an filterObject for searching in text to be used in getFilteredArray
 * @param {String} text - Value to be used in the filter
 * @param {string[]} keys - Array of keys where the filter should be applied to
 * @return {Array}
 */
export const includesTextInKeys = (text = '', keys = []) => {
	if (!Array.isArray(keys) || keys.length === 0 || text === null) {
		return [];
	}

	return [
		{
			type: filterType.INCLUDES_TEXT,
			value: normalizeStringForSearch(text),
			keys
		}
	];
};

/**
 * returns an filterObject which can be used in getFilteredArray
 * @param {*} value - Value to be used in the filter
 * @param {string[]} keys - Array of keys where the filter should be applied to
 * @return {Array}
 */
export const matchValueToKeys = (value, keys = []) => {
	if (!Array.isArray(keys) || keys.length === 0) {
		return [];
	}

	return [
		{
			type: filterType.MATCHES_VALUE,
			value,
			keys
		}
	];
};

/**
 * Returns a subset of an Array that matches the filtersObjects
 * @param {Array} arrayToFilter - Array of Objects to be filters
 * @param {Array.<{type: String, value: *, keys: string[]}>} filters - Array of filters to be applied
 * @return {Array}
 */
export const getFilteredArray = (arrayToFilter, filters = []) => {
	if (!Array.isArray(filters) || allFiltersAreEmpty(filters) || !Array.isArray(arrayToFilter)) {
		return arrayToFilter;
	}

	return arrayToFilter.filter(item => filters.every((filter) => {
		if (filter.keys.length === 0) {
			return true;
		}

		if (filter.type === filterType.INCLUDES_TEXT) {
			// Only one key has to contain the text
			return filter.keys.some(key => normalizeStringForSearch(item[key]).includes(filter.value));
		}

		if (filter.type === filterType.MATCHES_VALUE) {
			// Only one key has to have an exact match
			return filter.keys.some(key => item[key] === filter.value);
		}

		return true;
	}));
};




