import {STORE_NAMES} from '@/store/check/physicalstrain/physicalStrainMethods';
import {PhysicalStrainApi} from '@/components/check/physicalstrain/physicalApi';

const saveAnswer = async ({commit}, payload) => {
	const {companyId, scriptKey, functionGroupId, questionId, answers} = payload;

	const answerObject = {
		checkSectionId: functionGroupId,
		questionId,
		answers: answers.map(answer => ({answerId: answer.id, value: answer.value}))
	};

	return PhysicalStrainApi.postAnswers(companyId, scriptKey, answerObject)
		.then(response => commit(STORE_NAMES.SET_CLIENT_ANSWERS, response.responseObject));
};

const loadScriptFlow = async ({commit, getters}) => {
	if (getters.scriptFlow) {
		return;
	}

	await PhysicalStrainApi.getScriptFlow()
		.then(response => commit(STORE_NAMES.SET_SCRIPT_FLOW, response.responseObject));
};

const loadScript = async ({commit, getters}, payload) => {
	const {scriptKey, companyId} = payload;

	if (!getters.script(scriptKey)) {
		await PhysicalStrainApi.getScript(scriptKey, companyId)
			.then(response => commit(STORE_NAMES.SET_SCRIPT, response.responseObject));
	}

	return getters.script(scriptKey);
};

const loadFunctionGroups = async ({commit}, companyId) => {
	await PhysicalStrainApi.getAllFunctionGroups(companyId)
		.then(response => commit(STORE_NAMES.SET_FUNCTION_GROUPS, response.responseObject));
};

const loadClientAnswers = async ({commit}, payload) => {
	const {scriptKey, companyId, functionGroupId} = payload;

	// Clear current answers for script.
	commit(STORE_NAMES.CLEAR_CLIENT_SCRIPT_ANSWERS, scriptKey);

	if (functionGroupId) {
		await PhysicalStrainApi.getCheckSectionAnswers(scriptKey, companyId, functionGroupId)
			.then(response => commit(STORE_NAMES.SET_CLIENT_ANSWERS, response.responseObject));
	} else {
		await PhysicalStrainApi.getAnswers(scriptKey, companyId)
			.then(response => commit(STORE_NAMES.SET_CLIENT_ANSWERS, response.responseObject));
	}
};

const getResults = async ({commit}, companyId) => {
	const response = await PhysicalStrainApi.getPhysicalStrainResultsForCompany(companyId);
	const results = response.responseObject;

	const functionGroups = results.checkSections.map(functionGroup => (
		{
			id: functionGroup.id,
			name: functionGroup.name
		}
	));

	commit(STORE_NAMES.SET_FUNCTION_GROUPS, functionGroups);

	return results;
};

const startCheck = async ({commit, dispatch}, companyId) => {
	commit(STORE_NAMES.CLEAR_STORE);
	await Promise.all([
		dispatch(STORE_NAMES.LOAD_SCRIPT_FLOW),
		dispatch(STORE_NAMES.LOAD_FUNCTION_GROUPS, companyId)
	]);
};

export {
	saveAnswer,
	loadScriptFlow,
	loadScript,
	loadFunctionGroups,
	loadClientAnswers,
	startCheck,
	getResults
};
