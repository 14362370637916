<template>

	<vuelidate-form :vuelidate="v$"
	                :is-submitting="isSubmitting"
	                :warning-message="warningMessage"
	                class="content-form"
	                @validSubmit="handleSubmit">
		<p>
			<strong>Ben je jouw wachtwoord vergeten?</strong>
			<br>
			Vul het e-mailadres in van het Mijn 5xbeter account waarvan je het wachtwoord vergeten bent.
			Je ontvangt dan een e-mail waarmee je een nieuw wachtwoord kunt instellen.
		</p>

		<form-input :validation="v$.form.email"
		            autocomplete="username"
		            hide-label
		            label="E-mailadres"
		            name="email"
		            required
		            type="email"
		            v-model.trim="v$.form.email.$model" />

	</vuelidate-form>

</template>

<script>
	import {required, email} from '@vuelidate/validators';
	import {pageHelper} from '../../utils/pageHelper';
	import FormInput from '../forms/FormInput';
	import VuelidateForm from '../forms/VuelidateForm';
	import {Api} from '../../utils/api';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormLogin',
		components: {VuelidateForm, FormInput},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					email: ''
				},
				showSuccessMessage: false
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations: {
			form: {
				email: {
					required,
					email
				}
			}
		},
		computed: {
			pageHelper() {
				return pageHelper;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;

				this.warningMessage = '';
				await Api.postForgotPassword(this.form)
					.then(() => {
						this.$emit('successfullSubmit', this.form.email);
					})
					.catch((result) => {
						if (result.message && result.message.length > 0) {
							this.warningMessage = result.message;
							return;
						}
						this.warningMessage = 'Wachtwoord resetten is niet gelukt.';
					})
					.finally(() => {
						this.isSubmitting = false;
					});
			}
		}
	};
</script>
