<template>
	<div v-if="loading" title="Gegevens worden geladen">
		<skeleton-content-form title="Bedrijfsgegevens" />
		<skeleton-content-form :rows="3" title="Contactgegevens" />
	</div>

	<p v-else-if="loadingFailed && warningMessage">
		{{ warningMessage }}
	</p>

	<vuelidate-form v-else
	                :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                class="content-form"
	                hide-button-bar
	                @validSubmit="handleSubmit">

		<div class="block-form-title">
			<h2>Bedrijfsgegevens</h2>
		</div>

		<div class="block-form">
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input v-model.trim="v$.form.name.$model"
					            :validation="v$.form.name"
					            autocomplete="organization"
					            label="Bedrijfsnaam"
					            maxlength="255"
					            name="username"
					            placeholder="Bedrijfsnaam"
					            required />
				</div>

				<div class="col-6 col-sm-12">
					<form-select v-model="v$.form.numberOfEmployees.$model"
					             :options="numberOfEmployeesOptions"
					             :validation="v$.form.numberOfEmployees"
					             label="Bedrijfsgrootte"
					             required />
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-sm-12">
					<form-select v-model="v$.form.branch.$model"
					             :options="brancheOptions"
					             :validation="v$.form.branch"
					             label="Branche"
					             required />
				</div>
				<div class="col-6 col-sm-12">
					<form-select v-model="v$.form.membership.$model"
					             :options="membershipOptions"
					             :validation="v$.form.membership"
					             label="Lidmaatschap partners 5xbeter"
					             required />
				</div>
			</div>
		</div>

		<div class="block-form-title">
			<h2>Contactgegevens</h2>
		</div>
		<div class="block-form">

			<div class="row">
				<div class="col-6 col-sm-12">

					<label>
						Straatnaam en huisnummer
					</label>

					<div>
						<form-input v-model.trim="v$.form.contactInfo.street.$model"
						            :validation="v$.form.contactInfo.street"
						            hide-label
						            inline-block
						            input-class="input-medium"
						            label="Straatnaam"
						            maxlength="255"
						            placeholder="Straatnaam" />
						<!-- Vue strips all whitespace, old css depends on it, so that's why there's a &nbsp;-->
						&nbsp;
						<form-input v-model.trim="v$.form.contactInfo.houseNumber.$model"
						            :validation="v$.form.contactInfo.houseNumber"
						            hide-label
						            inline-block
						            input-class="input-small"
						            label="Huisnummer"
						            maxlength="255"
						            placeholder="Nr." />
					</div>

					<form-input v-model.trim="v$.form.contactInfo.zipCode.$model"
					            :validation="v$.form.contactInfo.zipCode"
					            label="Postcode"
					            maxlength="255"
					            placeholder="Postcode" />

					<form-input v-model.trim="v$.form.contactInfo.city.$model"
					            :validation="v$.form.contactInfo.city"
					            label="Plaats"
					            maxlength="255"
					            placeholder="Plaats" />

				</div>

				<div class="col-6 col-sm-12">

					<form-input v-model.trim="v$.form.contactInfo.poBox.$model"
					            :validation="v$.form.contactInfo.poBox"
					            inputmode="number"
					            label="Postbus"
					            maxlength="255"
					            placeholder="Postbus" />

					<form-input v-model.trim="v$.form.contactInfo.poBoxZipCode.$model"
					            :validation="v$.form.contactInfo.poBoxZipCode"
					            label="Postcode"
					            maxlength="7"
					            placeholder="Postcode van je postbus" />

					<form-input v-model.trim="v$.form.contactInfo.poBoxCity.$model"
					            :validation="v$.form.contactInfo.poBoxCity"
					            label="Plaats"
					            maxlength="255"
					            placeholder="Plaats van je postbus" />

				</div>
			</div>

			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input v-model.trim="v$.form.contactInfo.phoneNumber.$model"
					            :validation="v$.form.contactInfo.phoneNumber"
					            inputmode="tel"
					            label="Telefoonnummer"
					            maxlength="255"
					            placeholder="Telefoonnummer" />

					<form-input v-model.trim="v$.form.contactInfo.email.$model"
					            :validation="v$.form.contactInfo.email"
					            label="E-mailadres"
					            maxlength="255"
					            placeholder="E-mailadres"
					            type="email" />
				</div>
			</div>
		</div>

		<form-warning-block v-if="warningMessage" :message="warningMessage" />

		<div class="block-form-buttons">
			<div class="row">
				<div class="col-12 col-sm-12">
					<button :disabled="isSubmitting"
					        class="button"
					        type="submit">Opslaan
					</button>

					<button v-if="form.permission.companyOwner"
					        class="button button-no-border"
					        type="button"
					        @click="$emit('delete-company', companyId, form.name)">
						<i class="icon-delete" />Verwijder bedrijf
					</button>
				</div>
			</div>
		</div>

	</vuelidate-form>

</template>

<script>
	import {alphaNum, email, maxLength, required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import {pageHelper} from '@/utils/pageHelper';
	import FormInput from '../forms/FormInput';
	import VuelidateForm from '../forms/VuelidateForm';
	import FormSelect from '../forms/FormSelect';
	import FormWarningBlock from '../forms/FormWarningBlock';
	import {brancheOptions, membershipOptions, numberOfEmployeesOptions} from '@/utils/companyHelper';
	import {dutchPhoneNumber, dutchZipCode} from '@/utils/customValidators';
	import SkeletonContentForm from '../generic/SkeletonContentForm';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormUpdateCompanyDetails',
		components: {SkeletonContentForm, FormWarningBlock, FormSelect, VuelidateForm, FormInput},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: true,
				loadingFailed: false,
				isSubmitting: false,
				warningMessage: '',
				showSticky: false,
				form: {
					name: '',
					numberOfEmployees: '',
					branch: '',
					membership: '',
					contactInfo: {
						street: '',
						houseNumber: '',
						zipCode: '',
						city: '',
						poBox: '',
						poBoxZipCode: '',
						poBoxCity: '',
						phoneNumber: '',
						email: ''
					},
					permission: {
						companyOwner: false
					}
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		computed: {
			numberOfEmployeesOptions() {
				return numberOfEmployeesOptions;
			},
			brancheOptions() {
				return brancheOptions;
			},
			membershipOptions() {
				return membershipOptions;
			}
		},
		watch: {
			companyId() {
				this.resetPage();
				this.initPage();
			}
		},
		validations() {
			return {
				form: {
					name: {
						required,
						maxLength: maxLength(255)
					},
					numberOfEmployees: {
						required
					},
					branch: {
						required
					},
					membership: {
						required
					},
					contactInfo: {
						street: {
							maxLength: maxLength(255)
						},
						houseNumber: {
							alphaNum,
							maxLength: maxLength(255)
						},
						zipCode: {
							dutchZipCode
						},
						city: {
							maxLength: maxLength(255)
						},
						poBox: {
							maxLength: maxLength(255)
						},
						poBoxZipCode: {
							dutchZipCode
						},
						poBoxCity: {
							maxLength: maxLength(255)
						},
						phoneNumber: {
							dutchPhoneNumber
						},
						email: {
							email,
							maxLength: maxLength(255)
						}
					}
				}
			};
		},
		methods: {
			initPage() {
				this.loadCompanyData();
			},
			resetPage() {
				this.form = {};
			},
			async loadCompanyData() {
				this.loading = true;
				await Api.getCompanyDetail(this.companyId).then((response) => {
					const companyData = response.responseObject;
					this.form = companyData;
					this.$store.dispatch('updateCompany', {
						id: companyData.id,
						externalId: companyData.externalId,
						name: companyData.name,
						permission: {...companyData.permission}
					});
				}).catch(() => {
					this.warningMessage = 'Gegevens ophalen is niet gelukt';
					this.loadingFailed = true;
				}).finally(() => {
					this.loading = false;
				});
			},
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await Api.postUpdateCompany(this.companyId, this.form)
					.then((response) => {
						if (response.responseObject.id) {
							this.$router.push({
								name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW,
								params: {
									companyId: response.responseObject.id
								}
							});
						} else {
							this.$router.push({
								name: pageHelper.PAGE_MY_COMPANIES
							});
						}
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Gegevens aanpassen is niet gelukt';
					}).finally(() => {
						this.isSubmitting = false;
					});
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
