<template>
	<div class="company-record-list">
		<details>
			<summary>
				<i class="icon-chrevron-up"></i>
				{{ themeTitle }} ({{ this.itemsFiltered.length }})
			</summary>

			<article v-for="item in itemsFiltered"
			         :key="item.id"
			         class="company-record-list-item">
				<p v-if="item.createdDate !== item.lastUpdatedDate"
				   :title="`Toegevoegd op ${getFormattedDate(item.createdDate)} door ${ item.createdBy.name }`"
				   class="text-grey">
					Laatst gewijzigd op {{ getFormattedDate(item.lastUpdatedDate) }} door
					{{ item.lastUpdatedBy.name }}
				</p>
				<p v-else class="text-grey">
					Toegevoegd op {{ getFormattedDate(item.createdDate) }} door
					{{ item.createdBy.name }}
				</p>
				<h4>{{ item.subject }}</h4>
				<ul>
					<li v-for="file in item.files" :key="'file' + file.id">
						<a :download="file.name"
						   :href="prefixWithBackendDomain(file.url)"
						   rel="noopener noreferrer"
						   target="_blank">
							<i class="icon-download"></i>
							{{ file.name }}
						</a>
					</li>
					<li v-for="url in item.urls" :key="'url' + url.id">
						<a :href="url.link"
						   rel="noopener noreferrer"
						   target="_blank">{{ url.name }} </a>
					</li>
				</ul>

				<div v-if="userCanEdit(item)"
				     class="company-record-list-item__buttons">
					<router-link :to="detailPageRouterObject(item)"
					             class="button button-no-border"
					             title="Bewerken">
						<i class="icon-edit"></i>
					</router-link>

					<button class="button button-no-border"
					        title="Verwijderen"
					        type="button"
					        @click="confirmDeleteCompanyRecord(item)">
						<i class="icon-delete"></i>
					</button>
				</div>
			</article>

			<article v-if="itemsFiltered.length === 0">
				<p class="text-grey">Niks toegevoegd aan {{ themeTitle }}</p>
			</article>
		</details>
	</div>

</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import {getFormattedDate} from '@/utils/date';
	import {getThemeTitle} from '@/utils/themeHelper';
	import {Api, prefixWithBackendDomain} from '@/utils/api';

	export default {
		name: 'CompanyRecordList',
		components: {},
		props: {
			theme: {
				type: String
			},
			items: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			},
			themeTitle() {
				return getThemeTitle(this.theme);
			},
			itemsFiltered() {
				return this.items.filter(item => item.theme === this.theme);
			},
			isUserCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			isUserCompanyAdmin() {
				return this.$store.getters.isUserCompanyAdmin(this.companyId);
			},
			isCoachAndCoachInvited() {
				return this.$store.getters.isUserInvitedAsCoachByCompany(this.companyId) && this.$store.getters.userHasRoleCoach();
			}
		},
		methods: {
			userCanEdit(item) {
				return this.isUserCompanyOwner
					|| ((this.isUserCompanyAdmin || this.isCoachAndCoachInvited) && this.isItemCreatedByUser(item));
			},
			isItemCreatedByUser(item) {
				return this.$store.getters.userHasId(item.createdBy.id);
			},
			confirmDeleteCompanyRecord(item) {
				this.$confirmDialog({
						title: 'Item verwijderen',
						content: `Weet je zeker dat je het item wilt verwijderen?`,
						confirmButton: 'Ja',
						dialogType: 'warning'
					})
					.then(async () => {
						await this.handleDelete(item);
					})
					.catch(() => {
						// Canceled, do nothing
					});
			},
			async handleDelete(item) {
				await Api.deleteCompanyRecord(this.companyId, item.id);
				this.$emit('onItemDelete');
			},

			detailPageRouterObject(item) {
				return {
					name: pageHelper.PAGE_COMPANY_RECORDS_EDIT,
					params: {
						companyRecordId: item.id,
						companyId: this.companyId,
						companyRecord: item
					}
				};
			},
			getFormattedDate,
			prefixWithBackendDomain
		}
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/variables';

	.company-record-list {
		background: $color-card-bg;
		margin: 0 0 20px;

		summary {
			margin: 0 0 2px;
			padding: 8px 20px;
			font-size: 20px;
			line-height: 1.2em;

			font-weight: 200;
			font-family: $font-stack-header;

			cursor: pointer;

			padding-right: 40px;
			position: relative;

			.icon-chrevron-up {
				position: absolute;
				right: 20px;
				top: 16px;
				color: $blue;
				transform: scale(-1);
			}
		}

		[open] summary .icon-chrevron-up {
			transform: scale(1);
		}

		article {
			border-top: 2px solid #eeeeee;
			padding: 1em 20px;

			&:hover {
				background: $color-card-bg-hover;
			}

			p {
				margin: 0;
			}

			ul {
				margin: 0;
			}
		}

		.button-bar {
			text-align: right;
			margin-bottom: 0;
		}
	}

	.company-record-list-item {
		position: relative;

	}

	.company-record-list-item__buttons {
		float: right;
		position: absolute;
		top: 0;
		right: 0;

		button, a {
			min-width: 40px;
			width: auto;
			padding: 0 calc($gutter / 2);
			height: 40px;
			line-height: 40px;
			text-align: center;
		}
	}
</style>
