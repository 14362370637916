<template>
	<div class="block-last-header-icon">
		<div class="result-title-icon">
			<icon-check :check="check" />
		</div>
		<icon-status :color="status" />
	</div>
</template>
<script>
	import IconCheck from '@/components/icons/IconCheck';
	import IconStatus from '@/components/icons/IconStatus';
	import {checkHelper, checkStatus, isValidCheckStatus} from '@/utils/checkHelper';

	export default {
		components: {IconStatus, IconCheck},
		props: {
			check: {
				type: String,
				required: true,
				validator: value => checkHelper.isValidCheck(value)
			},
			status: {
				type: String,
				validator: value => isValidCheckStatus(value),
				default: checkStatus.GREY.enum
			}
		}
	};
</script>
