<template>
	<div>

		<skeleton-block>
			<div class="block-container">
				<div class="block-result block-result-grey block-result_no-padding block-result_no-border">
					<div class="block-result_title">
						<h2>
							<skeleton-text-placeholder length="20" />
						</h2>
					</div>
					<br />
					<br />
					<div class="block-result_content">
						<div v-for="i in 2" :key="i" class="riskmatrix-skeleton-row">
							<skeleton-text-placeholder length="20" style="margin-right: auto;" />
							<div v-for="i in 5" :key="i" style="flex-basis: 75px; text-align: center;">
								<icon-status />
							</div>
						</div>
					</div>
				</div>
			</div>
		</skeleton-block>

		<br />
		<skeleton-card-block-result small />
	</div>

</template>

<script>
	import SkeletonTextPlaceholder from '@/components/generic/SkeletonTextPlaceholder';
	import SkeletonBlock from '@/components/generic/SkeletonBlock';
	import IconStatus from '@/components/icons/IconStatus';
	import SkeletonCardBlockResult from '@/components/check/results/SkeletonCardBlockResult';

	export default {
		name: 'SkeletonCheckPhysical',
		components: {SkeletonCardBlockResult, IconStatus, SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
<style scoped>
	.riskmatrix-skeleton-row {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.block-result_no-padding .block-result_content {
		padding-left: 20px;
	}
</style>
