import {checkHelper} from './checkHelper';
import {arrayToCommaSeparatedString} from './text';

// These are the same constants and names as used in the java enum Check and Theme
export const themes = Object.freeze({
	GENERAL: {
		enum: 'GENERAL',
		title: 'Algemeen'
	},
	WELDING_SMOKE: checkHelper.WELDING_SMOKE,
	MACHINE_SAFETY: checkHelper.MACHINE_SAFETY,
	NOISE_HAZARD: checkHelper.NOISE_HAZARD,
	PHYSICAL_STRAIN: checkHelper.PHYSICAL_STRAIN,
	TEMP_WORK_AT_HEIGHT: checkHelper.TEMP_WORK_AT_HEIGHT,
	HAZARDOUS_SUBSTANCES: checkHelper.HAZARDOUS_SUBSTANCES,
	NEWCOMERS: {
		enum: 'NEWCOMERS',
		title: 'Nieuwkomers'
	},
	CONFINED_SPACE: {
		enum: 'CONFINED_SPACE',
		title: 'Besloten ruimte'
	},
	OTHER: {
		enum: 'OTHER',
		title: 'Overig'
	}
});

export const getThemeTitle = themeEnum => themes[themeEnum].title;

export const getCombinedThemeTitles = (arrayOfThemeEnums) => {
	const themeTitles = arrayOfThemeEnums.map(theme => getThemeTitle(theme));
	return arrayToCommaSeparatedString(themeTitles, 'en');
};
