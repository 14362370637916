<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{warningMessage}}
		</p>

		<div class="block-container" title="Overzicht wordt geladen" v-if="loading">
			<skeleton-card-item :key="i" :lines="3" v-for="i in 3" />
		</div>

		<div class="checkbox-list">
			<checkbox-list-item
					id="showArchived"
					label="Toon gearchiveerde adviezen"
					name="showArchived"
					v-model="showArchived" />
		</div>

		<advices-list :advices="filteredAdvices" v-if="!loading" />

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {Api} from '../../utils/api';
	import AdvicesList from './AdvicesList';
	import SkeletonCardItem from '../generic/SkeletonCardItem';
	import CheckboxListItem from '@/components/forms/CheckboxListItem';

	export default {
		name: 'PageAdviceOverview',
		components: {SkeletonCardItem, AdvicesList, BaseGrid, CheckboxListItem},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				advices: [],
				showArchived: false
			};
		},
		computed: {
			pageTitle() {
				return `Advies voor ${this.$store.state.company.current.name}`;
			},
			filteredAdvices() {
				if (this.showArchived) {
					return this.advices;
				}

				return this.advices.filter(advice => !advice.archived);
			}
		},
		methods: {
			async loadCompanyAdvicesData() {
				await Api.getCompanyAdvices(this.companyId)
					.then((response) => {
						this.advices = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Adviezen konden niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadCompanyAdvicesData();
			},
			resetPage() {
				this.advices = [];
			}
		},
		watch: {
			$route() {
				this.resetPage();
				this.initPage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
