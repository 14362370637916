export const numberOfEmployeesOptions = Object.freeze([
	'<10',
	'10-100',
	'100-500',
	'>500'
]);

export const brancheOptions = Object.freeze([
	'Metaalbewerking',
	'Metalektro',
	'anders'
]);

export const membershipOptions = Object.freeze([
	'Geen van beide',
	'Koninklijke Metaalunie / CAO Metaalbewerking',
	'Vereniging FME-CWM / CAO Metalektro',
	'Weet ik niet'
]);
