<template>
	<base-grid :page-title="pageTitle" sidebar="company">
		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<div class="block-container" title="Overzicht wordt geladen" v-if="loading">
			<skeleton-company-record-list />
		</div>


		<template v-if="!loading">
			<company-record-list :items="companyRecords"
			                     :key="theme.enum"
			                     :theme="theme.enum"
			                     @onItemDelete="initPage"
			                     v-for="theme in themes" />

		</template>
	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import {Api} from '@/utils/api';
	import CompanyRecordList from '@/components/companyrecords/CompanyRecordList';
	import {themes} from '@/utils/themeHelper';
	import SkeletonCompanyRecordList from '@/components/companyrecords/SkeletonCompanyRecordList';

	export default {
		name: 'PageCompanyRecordsOverview',
		components: {
			SkeletonCompanyRecordList,
			CompanyRecordList,
			BaseGrid
		},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				companyRecords: []
			};
		},
		computed: {
			pageTitle() {
				return `Bedrijfsdossier voor ${this.$store.state.company.current.name}`;
			},
			themes() {
				return themes;
			}
		},
		methods: {
			async loadCompanyRecordsData() {
				await Api.getCompanyRecords(this.companyId)
					.then((response) => {
						this.companyRecords = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Bedrijfsdossier kon niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			initPage() {
				this.loading = true;
				this.loadCompanyRecordsData();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
