<template>
	<dl v-if="visitReport !== null" class="report-visit-summary">
		<dt>Datum bezoek</dt>
		<dd>
			{{ getFormattedDate(visitReport.dateAppointment) }}
			<em v-if="visitReport.firstVisit">(eerste bezoek)</em>
		</dd>

		<dt>Aangemaakt door</dt>
		<dd>
			{{ visitReport.createdBy.name }}
		</dd>

		<dt :class="compactMode ? '' : 'wide'">Aanleiding bezoek</dt>
		<dd :class="compactMode ? '' : 'wide'">
			<p class="line-clamp-2">
				{{ visitReport.reason }}
			</p>
		</dd>

		<dt :class="compactMode ? '' : 'wide'">Thema's/Verbeterchecks</dt>
		<dd :class="compactMode ? '' : 'wide'">
			<p>
				{{ getCombinedThemeTitles(visitReport.themes) }}
			</p>
		</dd>

		<template v-if="!compactMode">

			<dt class="wide">Samenvatting</dt>
			<dd class="wide">
				<p>
					{{ visitReport.recap }}
				</p>
			</dd>

			<dt>Vervolgafspraak</dt>
			<dd>
				{{ getTextOrFallback(getFormattedDate(visitReport.dateNextAppointment)) }}
			</dd>

			<dt>Beoordeling</dt>
			<dd>
				{{ getRating() }}
			</dd>

			<template v-if="getRatingComment().length > 0">
				<dt>Toelichting</dt>
				<dd class="wide">
					<p>
						{{ getRatingComment() }}
					</p>
				</dd>
			</template>

			<dt>Gearchiveerd</dt>
			<dd>
				{{ getTextForBoolean(visitReport.archived) }}
			</dd>

		</template>
	</dl>

</template>

<script>
	import {getFormattedDate} from '../../utils/date';
	import {getCombinedThemeTitles} from '../../utils/themeHelper';
	import {getTextForBoolean, getTextOrFallback} from '../../utils/text';

	export default {
		name: 'VisitReportOverview',
		props: {
			visitReport: {
				type: Object,
				required: true
			},
			compactMode: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			getRating() {
				return this.visitReport.rating?.rating ?? 'Niet beoordeeld';
			},
			getRatingComment() {
				return this.visitReport.rating?.comment ?? '';
			},
			getCombinedThemeTitles,
			getFormattedDate,
			getTextOrFallback,
			getTextForBoolean
		}
	};
</script>

<style lang="scss">
	.report-visit-summary {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: 2em;
		}

		dt, dd {
			display: block;
			margin: 0;
			padding: 0;
		}

		dt {
			font-weight: bold;
		}

		p {
			white-space: pre-line;
		}

		@media screen and (min-width: 641px) {
			@supports (display: grid) {
				display: grid;
				grid-gap: 0 20px;

				grid-template-columns: auto 1fr;

				dt {
					grid-column: 1;

					&:after {
						content: ':'
					}

				}

				dd {
					grid-column: 2;
				}


				.wide {
					grid-column: 1/3;
				}
			}
		}
	}
</style>
