import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN ?? '';
const SENTRY_ENVIRONMENT = process.env.VUE_APP_SENTRY_ENVIRONMENT ?? '';
export const sentryEnabled = () => typeof SENTRY_DSN === 'string' && SENTRY_DSN.length > 0;

export const initSentry = (Vue, router) => {
	if (!sentryEnabled()) {
		console.warn('No Sentry DSN defined');
		return;
	}

	Sentry.init({
		Vue,
		dsn: SENTRY_DSN,
		environment: SENTRY_ENVIRONMENT,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: []
			})
		],
		tracesSampleRate: 1.0
	});
};

/**
 * Send error to Sentry
 * @param {Error} error - Error event to sent to log
 */
export const logToSentry = (error) => {
	Sentry.captureException(error);
};
