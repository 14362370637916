<template>
	<div>
		<modal-add-advice />
		<modal-add-action />
	</div>
</template>
<script>
	import ModalAddAdvice from '../advice/ModalAddAdvice';
	import ModalAddAction from '../actionitem/ModalAddAction';

	export default {
		components: {ModalAddAction, ModalAddAdvice}
	};
</script>
