const validationMessages = Object.freeze({
	required: 'Dit is een verplicht veld.',
	requiredIf: 'Dit is een verplicht veld.',
	requiredIfRef: 'Dit is een verplicht veld.',
	minLength: 'Vul hier minimaal {min} tekens in.',
	maxLength: 'Vul hier maximaal {max} tekens in.',
	url: 'Ongeldige url',
	integer: 'Vul hier alleen nummers in.',
	maxValue: 'Vul hier een waarde in kleiner dan of gelijk aan {max}.',
	minValue: 'Vul hier een waarde in groter dan of gelijk aan {min}.',
	email: 'Ongeldig e-mailadres',
	alphaNum: 'Mag alleen letters en cijfers bevatten.',
	dutchZipCode: 'Vul hier een geldige postcode in.',
	dutchPhoneNumber: 'Vul hier een geldig Nederlands telefoonnummer in.',
	password: 'Je wachtwoord moet bestaan uit minimaal acht karakters, waarvan minimaal één kleine letter, één hoofdletter en één cijfer.',
	sameAsPassword: 'Vul hier hetzelfde wachtwoord in.',
	validDate: 'Vul hier een geldige datum in.'
});

const getValidationMessageForValidationRule = (validationObject, invalidRuleName) => {
	let errorMessage = 'Voldoet niet aan de validatie regels.';
	if (validationMessages[invalidRuleName] && validationMessages[invalidRuleName].length > 0) {
		errorMessage = validationMessages[invalidRuleName];
	}

	if (validationObject[invalidRuleName] !== null) {
		Object.keys(validationObject[invalidRuleName].$params).forEach((paramName) => {
			const paramValue = validationObject[invalidRuleName].$params[paramName];
			if (paramValue) {
				errorMessage = errorMessage.replace(`{${paramName}}`, paramValue);
			}
		});
	}

	return errorMessage;
};

const getFirstInvalidValidationMessage = (validationObject) => {
	const invalidRules = Object.keys(validationObject)
		.filter(rule => !rule.startsWith('$'))
		.filter(rule => validationObject[rule].$invalid);
	return getValidationMessageForValidationRule(validationObject, invalidRules[0]);
};

export {validationMessages, getValidationMessageForValidationRule, getFirstInvalidValidationMessage};
