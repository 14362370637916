<template>
	<card-list-item :line-clamp="3"
	                :router-link-to="detailPageRouterObject"
	                read-more="Bekijk advies">
		<template #heading>
			{{ getFormattedDate(advice.createdDate) }} door {{ advice.createdBy.name }}
			<em v-if="advice.createdByCoach">(Verbetercoach)</em>
		</template>

		<p>
			<strong>{{ getCombinedThemeTitles(advice.themes) }}</strong><br />
			{{ advice.reason }}
		</p>

	</card-list-item>
</template>

<script>
	import {getCombinedThemeTitles, getThemeTitle} from '../../utils/themeHelper';
	import {getFormattedDate} from '../../utils/date';
	import {pageHelper} from '../../utils/pageHelper';
	import CardListItem from '../generic/CardListItem';

	export default {
		name: 'AdvicesListItem',
		components: {CardListItem},
		props: {
			advice: {
				type: Object,
				required: true
			}
		},
		computed: {
			companyId() {
				return this.$route.params.companyId;
			},
			detailPageRouterObject() {
				return {
					name: pageHelper.PAGE_COMPANY_ADVICE_DETAIL,
					params: {
						companyId: this.companyId,
						adviceId: this.advice.id
					}
				};
			}
		},
		methods: {
			getCombinedThemeTitles,
			getFormattedDate,
			getThemeTitle
		}
	};
</script>
