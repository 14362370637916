<template>
	<div>
		<p class="block-page-header-invite">
			<a href="#" @click.prevent="showInvitationModal = true">
				<i class="icon-chevron-right"></i>Nodig een Verbetercoach, collega of externe adviseur uit om mee te
				                                  kijken
			</a>
		</p>
		<modal-invitation :coachInvited="coachInvited"
		                  :company-id="companyId"
		                  :is-company-id-external="true"
		                  :show="showInvitationModal"
		                  @close="showInvitationModal = false" />
	</div>
</template>

<script>
	import ModalInvitation from './ModalInvitation';

	export default {
		name: 'ModalInvitationWrapper',
		components: {ModalInvitation},
		props: {
			coachInvited: {
				type: Boolean,
				required: true
			},
			companyId: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				showInvitationModal: false
			};
		}
	};
</script>
