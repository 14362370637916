<template>

	<div class="popup-content">
		<vuelidate-form :is-submitting="isSubmitting"
		                :vuelidate="v$"
		                :warning-message="warningMessage"
		                class="popup-form"
		                @validSubmit="handleSubmit">

			<div class="row">

				<div class="col-6 col-sm-12">
					<h2 class="invite-form-details_column-title">{{ roleLabel }}:</h2>
					<h3 class="invite-form-details_name"><strong>{{ invitee.name }}</strong></h3>
					<p class="invite-form-details_email">{{ invitee.email }}</p>
				</div>

				<div class="col-6 col-sm-12">
					<label>{{ rightsLabel }}: *</label>
					<div class="checkbox-list">

						<radio-list-item v-model="form.rights"
						                 label="Meelezen"
						                 name="rechten"
						                 :value="readonly" />
						<radio-list-item v-model="form.rights"
						                 label="Meelezen en -werken"
						                 name="rechten"
						                 :value="write" />
						<radio-list-item v-model="form.rights"
						                 label="Bedrijf administreren"
						                 name="rechten"
						                 :value="admin" />
					</div>

					<label>Bij de volgende checks: *</label>
					<div class="checkbox-list">
						<form-checkbox-list :options="checksArray"
						                    :validation="v$.form.checks"
						                    option-label="title"
						                    option-value="enum"
						                    required
						                    v-model="v$.form.checks.$model" />
					</div>

				</div>
			</div>

			<template v-slot:button-bar>
				<div class="row">
					<div class="col-6 col-sm-12">
						<div class="button-bar">
							<button :class="isSubmitting || v$.$invalid ? 'disabled' : ''"
							        :disabled="isSubmitting || v$.$invalid"
							        class="button"
							        type="submit">
								<span>Aanpassen</span>
							</button>
						</div>
					</div>
					<div class="col-6 col-sm-12">
						<div class="button-bar">
							<button class="button button-no-border" type="button" @click="confirmDeletePermission">
								<i class="icon-delete"></i> Verwijder meekijker
							</button>
						</div>
					</div>
				</div>
			</template>

		</vuelidate-form>

	</div>

</template>

<script>
	import VuelidateForm from '../forms/VuelidateForm';
	import {required} from '@vuelidate/validators';
	import {checkHelper} from '@/utils/checkHelper';
	import RadioListItem from '../forms/RadioListItem';
	import {Api} from '@/utils/api';
	import {inviteeRole} from '@/types/InviteeRole';
	import {rights} from '@/types/Rights';
	import {useVuelidate} from '@vuelidate/core';
	import FormCheckboxList from '@/components/forms/FormCheckboxList.vue';

	export default {
		name: 'FormEditInvite',
		components: {FormCheckboxList, RadioListItem, VuelidateForm},

		props: {
			invitee: {
				type: Object,
				required: true
			},
			companyId: {
				type: Number,
				required: true
			}
		},

		computed: {
			roleLabel() {
				if (this.invitee.role === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'externe adviseur';
				}

				return 'collega';
			},
			rightsLabel() {
				if (this.invitee.role === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'De externe adviseur mag';
				}

				return 'Je collega mag';
			},
			checksArray() {
				return checkHelper.getChecksArray();
			},
			readonly() {
				return rights.READONLY;
			},
			write() {
				return rights.WRITE;
			},
			admin() {
				return rights.ADMIN;
			}
		},

		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					rights: this.invitee.rights,
					checks: this.invitee.checks
				},
				texts: {
					role: 'Gegevens collega',
					readWriteLabel: 'Je collega mag',
					checksLabel: 'Bij de volgende checks:'
				}
			};
		},

		setup() {
			return {
				v$: useVuelidate()
			};
		},

		validations: {
			form: {
				rights: {
					required
				},
				checks: {
					required
				}
			}

		},
		methods: {
			async handleSubmit() {
				await Api.updateColleagueOrExternalAdvisorInvite(this.companyId, this.invitee.invitationId, this.form)
					.then(() => {
						this.$emit('close', true);
					})
					.catch(() => {
						this.warningMessage = 'Ophalen van de gegevens van de uitnodiging is niet gelukt';
					});
			},
			confirmDeletePermission() {
				// eslint-disable-next-line
				this.$confirmDialog({
						title: 'Toegang intrekken',
						content: `Weet je zeker dat je de toegang voor ${this.invitee.name} wilt intrekken?`,
						confirmButton: 'Intrekken',
						dialogType: 'warning'
					})
					.then(() => {
						Api.deleteColleagueOrExternalAdvisorInvite(this.companyId, this.invitee.invitationId)
							.then(() => {
								// Eventually close dialog
								this.$emit('close', true);
							})
							.catch(() => {
								// Rejected. Do nothing
								this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
							});
					})
					.catch(() => {
						// Rejected. Do nothing
						this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
					});
			}
		}
	};
</script>

<style>
	.invite-form-details_column-title:first-letter {
		text-transform: capitalize;
	}
</style>
