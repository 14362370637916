<template>
	<link-mijn5xbeter href="/winkelwagen">
			<span>
				<i class="icon-shoppingcart">
					<span v-show="itemsInShoppingCart.length > 0"
					      class="shoppingcart-items">
		                {{ itemsInShoppingCart.length }}
		            </span>
				</i>
			</span>
	</link-mijn5xbeter>
</template>

<script>
	import LinkMijn5xbeter from '@/components/LinkMijn5xbeter';

	export default {
		name: 'LinkShoppingcartHeader',
		components: {LinkMijn5xbeter},
		computed: {
			itemsInShoppingCart() {
				return this.$store.state.shoppingCart.items;
			}
		}
	};
</script>
