<template>
	<div class="block-container">
		<div class="block-result block-result_no-border block-result_no-padding">
			<div class="block-result_title"><h2>Risicomatrix</h2></div>
			<div class="block-result_content">
				<div class="risk-matrix-container">
					<table aria-label="Risicomatrix" class="risk-matrix">
						<tbody>
							<tr>
								<th scope="col">{{ matrixTitle }}</th>

								<th v-for="label in matrixLabels" :key="label" scope="col">
									<span>
										<span class="risk-title_container">
											{{ label }}
										</span>
									</span>
								</th>

								<th scope="col"></th>
							</tr>
							<template v-if="matrixData.length > 0">
								<tr v-for="groupMatrix in matrixData" :key="groupMatrix.id">
									<td>
										<router-link v-if="getDetailPageRouterObject"
										             :to="getDetailPageRouterObject(groupMatrix.id)">
											{{ groupMatrix.name }}
										</router-link>
										<a v-else href="#" @click.prevent="goToDetail(groupMatrix.id)">
											{{ groupMatrix.name }}
										</a>
									</td>
									<td v-for="item in groupMatrix[labelKey]" :key="item.name">
										<icon-status :color="item.status" :hide-icon="true" />
									</td>
									<td class="risk-matrix_delete">
										<a v-if="hasWriteAccess"
										   :title="deleteGroupTitle"
										   href="#"
										   @click.prevent="deleteGroup(groupMatrix.id)">
											<em class="icon-delete"></em>
										</a>
										<a v-else class="disabled" href="#">
											<em class="icon-delete"></em>
										</a>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td :colspan="matrixLabels.length + 2" class="risk-matrix_empty">
										<a v-if="hasWriteAccess"
										   href="#"
										   @click.prevent="goToAddFunctionGroup">
											Er zijn geen functiegroepen toegevoegd.
										</a>
										<template v-else>Er zijn geen functiegroepen toegevoegd.</template>
									</td>
								</tr>
							</template>

							<template v-if="genericResults">
								<tr>
									<th :colspan="matrixLabels.length + 2" scope="row">
										{{ genericResultsTitle }}
									</th>
								</tr>

								<tr v-for="result in genericResults" :key="result.name">
									<td>
										<router-link v-if="getDetailPageRouterObject"
										             :to="getDetailPageRouterObject(result.scriptKey)">
											{{ result.name }}
										</router-link>
										<a v-else href="#" @click.prevent="goToDetail(result.scriptKey)">
											{{ result.name }}
										</a>
									</td>

									<td :colspan="matrixLabels.length + 1">
										<icon-status :color="result.status" :hide-icon="true" />
									</td>
								</tr>

							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import IconStatus from '@/components/icons/IconStatus';

	export default {
		name: 'CheckResultMatrix',
		components: {IconStatus},
		props: {
			matrixLabels: {
				type: Array,
				required: true
			},
			matrixData: {
				type: Array,
				required: true
			},
			matrixTitle: {
				type: String,
				required: true
			},
			getDetailPageRouterObject: {
				type: Function,
				required: true
			},
			labelKey: {
				type: String,
				required: true
			},
			deleteGroupTitle: {
				type: String,
				default: 'Verwijder groep'
			},
			genericResults: {
				type: Array,
				default: () => []
			},
			genericResultsTitle: {
				type: String,
				default: 'Bedrijfsbreed'
			},
			hasWriteAccess: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			goToDetail(id) {
				throw new Error(`No router object is added to risk matrix. Cannot navigate to ${id}`);
			},
			goToAddFunctionGroup() {
				this.$emit('addGroup');
			},
			deleteGroup(id) {
				this.$emit('deleteGroup', id);
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import 'src/assets/variables';

	.category-table_container {
		max-width: 475px;
	}

	.risk-matrix_empty {
		color: $grey;
	}
</style>
