<template>
	<div>
		<label v-if="label && !hideLabel"
		       :for="inputId">
			{{ label }}
			<span v-if="required">*</span>
		</label>
		<div :class="[fullwidth ? 'textarea-wrapper--fullwidth' : '',inputWrapperClass]"
		     class="input-wrapper textarea-wrapper">
			<textarea :id="inputId"
			          :aria-label="hideLabel ? label : null"
			          :autocomplete="autocomplete"
			          :class="inputClass"
			          :cols="cols"
			          :disabled="disabled"
			          :inputmode="inputmode"
			          :maxlength="maxlength"
			          :minlength="minlength"
			          :name="name"
			          :placeholder="inputPlaceholder"
			          :readonly="readonly"
			          :required="required"
			          :rows="rows"
			          :value="modelValue"
			          @input="$emit('update:modelValue', $event.target.value)"></textarea>

			<error-wrapper v-if="validation && validation.$error" :for="inputId">
				{{ validationMessage }}
			</error-wrapper>

			<slot name="after-input"></slot>
		</div>
	</div>
</template>

<script>
	import ErrorWrapper from './ErrorWrapper';
	import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
	import {createUniqueId} from '@/utils/uid';

	export default {
		name: 'FormTextarea',
		components: {ErrorWrapper},
		props: {
			modelValue: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				required: true
			},
			required: {
				type: Boolean,
				default: false
			},
			readonly: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			validation: {
				type: Object
			},
			id: {
				type: String
			},
			placeholder: {
				type: String
			},
			name: {
				type: String
			},
			minlength: {
				type: [Number, String]
			},
			maxlength: {
				type: [Number, String]
			},
			cols: {
				type: [Number, String]
			},
			rows: {
				type: [Number, String]
			},
			inputmode: {
				type: String
			},
			hideLabel: {
				type: Boolean,
				default: false
			},
			autocomplete: {
				type: String
			},
			inputClass: {
				type: String
			},
			inputWrapperClass: {
				type: String
			},
			fullwidth: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			inputId() {
				return this.id ?? `input-id-${this.uid}`;
			},

			inputPlaceholder() {
				if (this.hideLabel) {
					return this.placeholder || this.label;
				}

				return this.placeholder;
			},

			validationMessage() {
				const validationObject = this.validation;
				if (validationObject === null) {
					return null;
				}
				return getFirstInvalidValidationMessage(validationObject);
			}
		},
		data() {
			return {
				showTooltip: false,
				uid: createUniqueId()
			};
		},

		methods: {
			hideError() {
				this.showTooltip = false;
			},
			showError() {
				this.showTooltip = true;
			}
		}
	};
</script>

<style lang="scss">
	.textarea-wrapper--fullwidth {
		&, textarea {
			width: 100%;
			max-width: 100%;
		}
	}
</style>
