import {STORE_NAMES} from '@/store/check/physicalstrain/physicalStrainMethods';
import {
	clearClientScriptAnswers,
	clearStore,
	setClientAnswers,
	setFunctionGroups,
	setScript,
	setScriptFlow
} from '@/store/check/physicalstrain/physicalStrainMutations';
import {
	getResults,
	loadClientAnswers,
	loadFunctionGroups,
	loadScript,
	loadScriptFlow,
	saveAnswer,
	startCheck
} from '@/store/check/physicalstrain/physicalStrainActions';
import {
	getClientScriptAnswers,
	getFunctionGroupById,
	getFunctionGroups,
	getRouteForNextScript,
	getScript,
	getScriptFlow,
	getScriptType
} from '@/store/check/physicalstrain/physicalStrainGetters';

const physicalStrain = {
	namespaced: true,

	state: () => ({
		scripts: {},
		scriptFlow: null,
		clientAnswersPerScript: {},
		functionGroups: []
	}),

	getters: {
		[STORE_NAMES.GET_SCRIPT]: getScript,
		[STORE_NAMES.GET_SCRIPT_FLOW]: getScriptFlow,
		[STORE_NAMES.GET_FUNCTION_GROUPS]: getFunctionGroups,
		[STORE_NAMES.GET_FUNCTION_GROUP_BY_ID]: getFunctionGroupById,
		[STORE_NAMES.GET_CLIENT_SCRIPT_ANSWERS]: getClientScriptAnswers,
		[STORE_NAMES.GET_NEXT_SCRIPT_ROUTE]: getRouteForNextScript,
		[STORE_NAMES.GET_SCRIPT_TYPE]: getScriptType
	},
	mutations: {
		[STORE_NAMES.SET_SCRIPT_FLOW]: setScriptFlow,
		[STORE_NAMES.SET_SCRIPT]: setScript,
		[STORE_NAMES.SET_CLIENT_ANSWERS]: setClientAnswers,
		[STORE_NAMES.SET_FUNCTION_GROUPS]: setFunctionGroups,
		[STORE_NAMES.CLEAR_CLIENT_SCRIPT_ANSWERS]: clearClientScriptAnswers,
		[STORE_NAMES.CLEAR_STORE]: clearStore
	},
	actions: {
		[STORE_NAMES.SAVE_ANSWER]: saveAnswer,
		[STORE_NAMES.LOAD_SCRIPT_FLOW]: loadScriptFlow,
		[STORE_NAMES.LOAD_SCRIPT]: loadScript,
		[STORE_NAMES.LOAD_FUNCTION_GROUPS]: loadFunctionGroups,
		[STORE_NAMES.LOAD_ANSWERS]: loadClientAnswers,
		[STORE_NAMES.START_CHECK]: startCheck,
		[STORE_NAMES.GET_RESULTS]: getResults
	}
};

export default physicalStrain;
