<template>
	<base-grid page-title="Nieuw account aanmaken" sidebar="login">

		<card-block v-if="!formHasSuccessfullSubmit">
			<form-register @success="handleSuccess" />
		</card-block>
		<card-block v-else class="registered-container">

			<div>
				Je bent nu aangemeld voor <strong>Mijn 5xbeter</strong> en kunt direct profiteren van de volgende
				voordelen:
			</div>

			<ul>
				<li>
					<em class="icon-check"></em>
					<strong>Gratis hulpmiddelen</strong>
				</li>
				<li>
					<em class="icon-check"></em>
					<strong>Verbeterchecks bewaren</strong>
				</li>
			</ul>

			<p>
				Heb je vragen? Neem dan contact op met de Verbeterlijn: <br />
				<strong>0800 - 55 55 005</strong> of mail naar
				<a href="mailto:info@5xbeter.nl" style="color: #009fd8; text-decoration:none;">info@5xbeter.nl</a>
			</p>

			<p>
				<router-link :to="{name: pageHelper.PAGE_LOGIN}" class="button">
					Inloggen
				</router-link>
			</p>

		</card-block>

	</base-grid>
</template>

<script>
	import BaseGrid from '@/components/decorations/BaseGrid';
	import {pageHelper} from '@/utils/pageHelper';
	import FormRegister from '@/components/login/FormRegister';
	import CardBlock from '@/components/generic/CardBlock';

	export default {
		name: 'PageRegister',
		components: {CardBlock, FormRegister, BaseGrid},
		data() {
			return {
				formHasSuccessfullSubmit: false,
				email: ''
			};
		},
		computed: {
			pageHelper() {
				return pageHelper;
			}
		},
		methods: {
			handleSuccess(email) {
				this.formHasSuccessfullSubmit = true;
				this.email = email;
			}
		}
	};
</script>

<style lang="scss">
	.registered-container {
		ul {
			list-style-type: none;
		}

		.icon-check {
			color: #009c34;
			margin-right: 4px
		}
	}
</style>
