<template v-if="userIsOwnerForCompany">
	<div>
		<div class="block-company-overview-people-total">
			<a v-if="numberOfInvitees > 0 "
			   :class="showAll ? '' : 'js-content-is-hidden'"
			   href="#"
			   @click.prevent="showAll = !showAll">
				{{ numberOfInvitees }}
				{{ numberOfInvitees > 1 ? 'personen kijken' : 'persoon kijkt' }}
				mee met dit bedrijf
				<i class="icon-chrevron-up"></i>
			</a>
			<span v-else-if="companyHasCoachInvited">
				Geen andere gebruikers of Verbetercoaches hebben momenteel toegang tot de Verbeterchecks van dit bedrijf.
			</span>
		</div>

		<transition-fade :show-if="showAll">
			<div>
				<div v-if="companyHasCoachInvited"
				     class="block-company-overview-people-detail js-company-overview-people-toggle">
					<a aria-label="Toegang intrekken"
					   class="icon-delete"
					   href="#"
					   title="Toegang intrekken"
					   @click.prevent="confirmRevokeCoachPermissions()"></a>
					<p><strong>De Verbetercoach</strong> van 5xbeter kijkt mee naar alle Verbeterchecks.
					</p>
				</div>

				<div v-for="(invitee) in companyData.invitees"
				     :key="invitee.invitationId"
				     class="block-company-overview-people-detail">
					<a :aria-label="`Toegang intrekken voor ${invitee.fullName}`"
					   :title="`Toegang intrekken voor ${invitee.fullName}`"
					   class="icon-delete"
					   href="#"
					   @click.prevent="confirmRevokePermissions(invitee)"></a>
					<a :aria-label="`Aanpassen rechten meekijker ${invitee.fullName}`"
					   :title="`Aanpassen rechten meekijker ${invitee.fullName}`"
					   class="icon-edit"
					   href="#"
					   @click.prevent="editGuestPermissions(invitee)"></a>
					<p><strong>{{ invitee.fullName }}</strong> <span v-text="getReadAndEditText(invitee)"></span>
					</p>
				</div>
			</div>
		</transition-fade>

		<div class="block-company-overview-people-invite">
			<a class="js-fancybox fancybox.ajax" href="#" @click.prevent="showInvitationModal = true">
				<i class="icon-chevron-right"></i>Nodig iemand uit om mee te kijken
			</a>
		</div>

		<modal-invitation :coachInvited="companyHasCoachInvited"
		                  :company-id="companyId"
		                  :show="showInvitationModal"
		                  @close="showInvitationModal = false" />
		<modal-edit-guest-permissions ref="editPermissionsDialog" :company-id="companyId" @close="closeDialog" />
	</div>
</template>

<script>
	import {checkHelper} from '@/utils/checkHelper';
	import TransitionFade from '../decorations/TransitionFade';
	import ModalInvitation from './ModalInvitation';
	import {Api} from '@/utils/api';
	import ModalEditGuestPermissions from './ModalEditGuestPermissions';
	import {arrayToCommaSeparatedString} from '@/utils/text';
	import {rightsHelper} from '@/utils/rightsHelper';

	export default {
		name: 'CompanyPeopleOverview',
		components: {ModalEditGuestPermissions, ModalInvitation, TransitionFade},
		props: {
			companyData: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
				showAll: false,
				showInvitationModal: false
			};
		},
		computed: {
			checkHelper() {
				return checkHelper;
			},

			companyId() {
				return Number.parseInt(this.$route.params.companyId);
			},

			numberOfInvitees() {
				let numInvitees = this.companyData.invitees?.length ?? 0;
				if (this.companyHasCoachInvited) {
					numInvitees = numInvitees + 1;
				}
				return numInvitees;
			},
			userIsOwnerForCompany() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			companyHasCoachInvited() {
				return this.companyData.permission.coachInvited;
			}
		},
		methods: {

			closeDialog(doReload = false) {
				if (doReload) {
					location.reload();
				}
			},

			getReadAndEditText(invitee) {
				let text = 'kan meelezen met ';

				if (rightsHelper.hasAtLeastWriteAccess(invitee.rights)) {
					text += 'en meewerken aan ';
				}

				const checks = invitee.checks.map(check => checkHelper.getTitle(check));
				text += arrayToCommaSeparatedString(checks, 'en');

				if (rightsHelper.hasAdminAccess(invitee.rights)) {
					text += ' en het bedrijf administreren.';
				}
				return text;
			},

			confirmRevokePermissions(invitee) {
				this.$confirmDialog({
						title: 'Toegang intrekken',
						content: `Weet je zeker dat je de toegang voor ${invitee.fullName} wilt intrekken?`,
						confirmButton: 'Intrekken',
						dialogType: 'warning'
					})
					.then(() => {
						Api.deleteColleagueOrExternalAdvisorInvite(this.$route.params.companyId, invitee.invitationId)
							.then(() => {
								this.$emit('update-invitees', this.companyData.invitees.filter(inviteeFromList => inviteeFromList.invitationId !== invitee.invitationId));
							})
							.catch(() => {
								// Rejected. Do nothing
								this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
							});
					})
					.catch(() => {
						// Rejected. Do nothing
						this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
					});
			},

			confirmRevokeCoachPermissions() {
				this.$confirmDialog({
						title: 'Toegang intrekken',
						content: `Weet je zeker dat je de toegang voor de Verbetercoach wilt intrekken?`,
						confirmButton: 'Intrekken',
						dialogType: 'warning'
					})
					.then(() => {
						Api.deleteCoachInvite(this.$route.params.companyId)
							.then(() => {
								this.$emit('update-coach-invite', false);
							})
							.catch(() => {
								// Rejected. Do nothing
								this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
							});
					})
					.catch(() => {
						// Rejected. Do nothing
						this.warningMessage = 'Verwijderen van de uitnodiging is niet gelukt';
					});
			},

			async editGuestPermissions(invitee) {
				await Api.getColleagueOrExternalAdvisorInvite(this.$route.params.companyId, invitee.invitationId)
					.then((response) => {
						this.$refs.editPermissionsDialog.open(response.responseObject);
					})
					.catch(() => {
						this.warningMessage = 'Ophalen van de gegevens van de uitnodiging is niet gelukt';
					});
			}
		}
	};
</script>
