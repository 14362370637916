<template>
	<div class="block-container">
		<actions-list-item
				:action="action"
				:key="action.id"
				v-for="action in actions" />

		<card-block v-if="actions.length === 0">Geen actiepunten</card-block>
	</div>
</template>

<script>
	import ActionsListItem from './ActionListItem';
	import CardBlock from '../generic/CardBlock';

	export default {
		name: 'ActionsList',
		components: {CardBlock, ActionsListItem},
		props: {
			actions: {
				type: Array,
				default: () => []
			}
		}
	};
</script>
