<template>
	<CheckResult :check-enum="checkEnum"
	             :check-sections="results.checkSections"
	             :check-status="results.status"
	             :last-updated="results.lastUpdated"
	             :last-updated-by="results.lastUpdatedBy"
	             :loading="loading && !hasResults"
	             page-title="Resultaten verbetercheck Fysieke belasting">

		<skeleton-check-physical v-if="loading && !hasResults" title="Overzicht wordt geladen" />

		<template v-if="hasResults">
			<check-result-matrix :generic-results="genericResultsWithFunctionGroupTitles"
			                     :get-detail-page-router-object="getDetailPageForFunctionGroup"
			                     :has-write-access="hasWriteAccess"
			                     :matrix-data="processedCheckSections"
			                     :matrix-labels="matrixLabels"
			                     delete-group-title="Verwijder functiegroep"
			                     generic-results-title="Bedrijfsbreed"
			                     label-key="scripts"
			                     matrix-title="Functiegroep"
			                     @addGroup="addFunctionGroup"
			                     @deleteGroup="confirmDeleteFunctionGroup" />

			<div>
				<button :class="{'disabled': !hasWriteAccess}" class="add-button" type="button"
				        @click="addFunctionGroup">
					<em class="icon-plus"></em>
					Nieuwe functiegroep toevoegen
				</button>

			</div>
		</template>

	</CheckResult>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import {PhysicalStrainApi} from '@/components/check/physicalstrain/physicalApi';
	import CheckResultMatrix from '@/components/check/results/CheckResultMatrix';
	import {checkHelper, checkStatus, shouldUpdateStatus} from '@/utils/checkHelper';
	import {getGenericScriptTitle, isLiftingScript} from '@/components/check/physicalstrain/physicalScriptKeys';
	import {checkStoreMethod} from '@/store/check/checkStore';
	import CheckResult from '@/components/check/results/CheckResult';
	import {rightsHelper} from '@/utils/rightsHelper';
	import NumberUtils from '@/utils/numberUtils';
	import {encodeWithHtmlEntities} from '@/utils/text';
	import {PHYSICAL_STRAIN_STORE} from '@/store/check/physicalstrain/physicalStrainMethods';
	import SkeletonCheckPhysical from '@/components/check/physicalstrain/SkeletonCheckPhysical.vue';

	export default {
		name: 'PageResultsPhysical',
		components: {SkeletonCheckPhysical, CheckResult, CheckResultMatrix},
		data: () => ({
			loading: false,
			results: {
				lastUpdated: '',
				lastUpdatedBy: '',
				status: null,
				matrixLabels: [],
				checkSections: [],
				genericResults: []
			},
			checkEnum: checkHelper.PHYSICAL_STRAIN.enum,
			showInvitationModal: false
		}),
		computed: {
			companyId() {
				return NumberUtils.stringToNumber(this.$route.params.companyId);
			},
			isCompanyOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId);
			},
			matrixLabels() {
				return this.getProcessedMatrixLabels(this.results.matrixLabels);
			},
			genericResultsWithFunctionGroupTitles() {
				return this.results.genericResults.map(item => (
					{
						...item,
						name: getGenericScriptTitle(item.scriptKey) ?? item.name
					}
				));
			},
			processedCheckSections() {
				return this.results.checkSections.map(checkSection => this.getProcessedCheckSection(checkSection));
			},
			hasResults() {
				return this.results.genericResults?.length > 0 || this.results.checkSections?.length > 0;
			},
			hasWriteAccess() {
				return rightsHelper.hasWriteAccess(this.$store.getters.readWriteAccess(this.companyId));
			}
		},
		methods: {
			getProcessedMatrixLabels(labels) {
				const processedMatrixLabels = labels.map(({scriptKey, label}) => (
					isLiftingScript(scriptKey) ? 'Tillen' : label
				));

				return [...new Set(processedMatrixLabels)];
			},
			getProcessedCheckSection(checkSection) {
				const liftingScript = {
					name: 'Tillen',
					status: checkStatus.WHITE.enum
				};

				// Make a copy with only the applicable scripts
				const scripts = [...checkSection.scripts];
				let indexToAppend = 0;

				// Bundle the singular lifting scripts into one object
				scripts.forEach((script, index) => {
					if (isLiftingScript(script.scriptKey)) {
						indexToAppend = index;
						if (shouldUpdateStatus(liftingScript.status, script.status)) {
							liftingScript.status = script.status;
						}
					}
				});

				// Add the bundled liftingScript somewhere around the singular lifting scripts
				scripts.splice(indexToAppend, 0, liftingScript);

				return {
					...checkSection,
					scripts: scripts.filter(script => !isLiftingScript(script.scriptKey))
				};
			},

			async addFunctionGroup() {
				if (this.hasWriteAccess) {
					await this.$router.push({
						name: pageHelper.PAGE_PHYSICAL_ADD_EDIT_FUNCTION_GROUP,
						params: {
							companyId: this.companyId
						}
					});
				}
			},

			async getResults() {
				this.loading = true;
				await this.$store.dispatch(PHYSICAL_STRAIN_STORE.GET_RESULTS, this.companyId)
					.then(results => this.results = results)
					.catch(() => this.$store.dispatch(checkStoreMethod.SET_WARNING, 'Resultaten konden niet worden opgehaald.'))
					.finally(() => this.loading = false);
			},

			getDetailPageForFunctionGroup(functionGroupIdOrScriptKey) {
				return {
					name: pageHelper.PAGE_PHYSICAL_RESULTS_FUNCTION_GROUP,
					params: {
						companyId: this.companyId,
						functionGroupIdOrScriptKey
					}
				};
			},

			confirmDeleteFunctionGroup(functionGroupId) {
				const functionGroupName = encodeWithHtmlEntities(this.getNameOfFunctionGroupById(functionGroupId));
				this.$confirmDialog({
						title: 'Functiegroep verwijderen',
						htmlContent: `<p>Weet je zeker dat je de functiegroep <strong>${functionGroupName}</strong> wilt verwijderen?
						Eventueel opgeslagen vragen en resultaten zullen hiermee definitief worden verwijderd.</p><br>`,
						confirmButton: 'Verwijderen',
						dialogType: 'warning'
					})
					.then(() => this.deleteFunctionGroup(functionGroupId))
					.catch(() => {
						// Do nothing
					});
			},

			deleteFunctionGroup(functionGroupId) {
				PhysicalStrainApi.deleteFunctionGroup(this.companyId, functionGroupId)
					.then(() => this.getResults())
					.catch(() => this.$store.dispatch(checkStoreMethod.SET_WARNING, 'Verwijderen van functiegroep is mislukt.'));
			},

			getNameOfFunctionGroupById(functionGroupId) {
				return this.results.checkSections.find(item => item.id === functionGroupId)?.name ?? functionGroupId;
			}
		},
		watch: {
			companyId() {
				this.getResults();
			}
		},
		mounted() {
			this.getResults();
		}
	};
</script>

<style lang="scss" scoped>
	@import 'src/assets/variables';

	.add-button {
		margin: 0;

		&.disabled .icon-plus {
			border-right-color: $blue;
		}

	}
</style>
