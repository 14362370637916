import RouterViewWrapper from '@/components/wrappers/RouterViewWrapper.vue';
import {pageHelper} from '@/utils/pageHelper';
import PageVisitReportOverview from '@/components/visitreports/PageVisitReportOverview.vue';
import PageVisitReportNew from '@/components/visitreports/PageVisitReportNew.vue';
import PageVisitReportDetail from '@/components/visitreports/PageVisitReportDetail.vue';
import {inviteeRole} from '@/types/InviteeRole';
import {personRole} from '@/types/PersonRole';
import {rights} from '@/types/Rights';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default [
	{
		path: 'bezoekverslagen',
		component: RouterViewWrapper,
		meta: {
			roles: [personRole.ROLE_VIJFXBETER_USER],
			breadcrumb: 'Bezoekverslagen'
		},
		children: [
			{
				path: '',
				name: pageHelper.PAGE_COMPANY_VISIT_REPORT_OVERVIEW,
				component: PageVisitReportOverview,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					rights: rights.ADMIN,
					progressStep: '5',
					backLink: {
						label: 'mijn bedrijven',
						url: pageHelper.PAGE_MY_COMPANIES
					}
				},
				props: true
			},
			{
				path: 'nieuw',
				name: pageHelper.PAGE_COMPANY_VISIT_REPORT_NEW,
				component: PageVisitReportNew,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_COACH],
					inviteeRoles: [inviteeRole.ROLE_COACH],
					breadcrumb: 'nieuw'
				},
				props: true
			},
			{
				path: ':visitReportId',
				name: pageHelper.PAGE_COMPANY_VISIT_REPORT_DETAIL,
				component: PageVisitReportDetail,
				meta: {
					roles: [personRole.ROLE_VIJFXBETER_USER],
					inviteeRoles: [
						inviteeRole.ROLE_COACH,
						inviteeRole.ROLE_COLLEAGUE,
						inviteeRole.ROLE_EXTERNAL_ADVISOR,
						inviteeRole.ROLE_COMPANY_OWNER
					],
					rights: rights.ADMIN,
					breadcrumb: 'Bezoekverslag',
					progressStep: '5',
					backLink: {
						label: 'mijn bedrijven',
						url: pageHelper.PAGE_MY_COMPANIES
					}
				},
				props: true
			}
		]
	}
];
