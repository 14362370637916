<template>
	<block-page-header>

		<template v-slot:icon v-if="check">
			<result-page-header-icon :check="check" :status="status " />
		</template>

		{{ title }}

		<template v-slot:afterTitle>
			<skeleton-block
					v-if="loading"
					class="block-header-last-edited"
					style="margin-bottom: 18px;">
				<skeleton-text-placeholder length="40" />
			</skeleton-block>

			<last-edited v-if="!loading && $slots['last-edited']">
				<slot name="last-edited" />
			</last-edited>

			<p v-if="showInvite"
			   class="block-page-header-invite">
				<a href="#"
				   @click.prevent="$emit('showInvitationModal')">
					<em class="icon-chevron-right"></em>
					Nodig een Verbetercoach, collega of externe adviseur uit om mee te kijken
				</a>
			</p>
		</template>

	</block-page-header>
</template>
<script>
	import BlockPageHeader from '@/components/decorations/BlockPageHeader';
	import ResultPageHeaderIcon from '@/components/generic/ResultPageHeaderIcon';
	import SkeletonBlock from '@/components/generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '@/components/generic/SkeletonTextPlaceholder';
	import LastEdited from '@/components/generic/LastEdited';

	export default {
		components: {LastEdited, SkeletonTextPlaceholder, SkeletonBlock, ResultPageHeaderIcon, BlockPageHeader},
		props: {
			title: {
				type: String,
				required: true
			},
			check: {
				type: String
			},
			status: {
				type: String
			},
			loading: {
				type: Boolean,
				default: false
			},
			showInvite: {
				type: Boolean,
				default: false
			}
		}
	};
</script>
