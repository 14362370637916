<template>
    <div>
        <icon-check :check="check.check" />
        <icon-status :color="check.checkStatus"
                     :number="check.numUnfinished"
                     :hideIcon="hideIcon" />
    </div>
</template>

<script>

    import IconCheck from '../icons/IconCheck';
    import IconStatus from '../icons/IconStatus';
    import {checkType} from '@/utils/checkHelper';

    export default {
        name: 'CheckStatus',
        components: {IconStatus, IconCheck},
        props: {
            check: {
                type: Object,
                required: true
            }
        },
        computed: {
            hideIcon() {
                return this.check.checkType !== checkType.REGISTER && this.check.numUnfinished > 0;
            }
        }
    };
</script>
