<template>
	<a href="#" v-if="htmlElement === 'a'" @click.prevent="logout">
		<slot>uitloggen</slot>
	</a>
	<button v-else type="button" @click="logout">
		<slot>uitloggen</slot>
	</button>
</template>
<script>
	import {Api} from '@/utils/api';

	export default {
		name: 'Logout-button',
		props: {
			htmlElement: {
				type: String,
				default: 'button'
			}
		},
		methods: {
			async logout() {
				this.isSubmitting = true;
				await Api.getLogout()
					.catch(() => {
						// Do nothing
					})
					.finally(() => {
						this.$store.commit('logout', true);
					});
			}
		}
	};
</script>
