<template>
	<base-grid page-title="Mijn Bedrijven" sidebar="account">

		<block-search-field v-if="showSearchBlock"
		                    v-model="searchValue"
		                    label="Zoek op bedrijfsnaam" />

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<div v-if="loading" title="Overzicht wordt geladen">
			<skeleton-company-list-item v-for="i in numberOfPlaceholderCompanies" :key="i" />
		</div>

		<p v-else-if="showNoResults">
			Geen bedrijven gevonden.
		</p>

		<page-pagination v-else v-slot:="slotProps" :items="filteredCompanies">
			<company-list :companies="slotProps.itemsOnPage" />
		</page-pagination>

	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import CompanyList from './CompanyList';
	import {Api} from '@/utils/api';
	import {getFilteredArray, includesTextInKeys} from '@/utils/filter';
	import BlockSearchField from '../filters/BlockSearchField';
	import SkeletonCompanyListItem from './SkeletonCompanyListItem';
	import PagePagination from '../generic/PagePagination';

	const NUMBER_COMPANIES_TO_HIDE_SEARCH = 3;

	export default {
		name: 'PageMyCompanies',
		components: {
			PagePagination,
			SkeletonCompanyListItem,
			BlockSearchField,
			CompanyList,
			BaseGrid
		},
		data() {
			return {
				loading: true,
				warningMessage: '',
				companies: [],
				searchValue: ''
			};
		},

		computed: {
			filteredCompanies() {
				const filters = [
					...includesTextInKeys(this.searchValue, ['name'])
				];
				return getFilteredArray(this.companies, filters);
			},
			numberOfCompaniesInStore() {
				return Object.keys(this.$store.state.company.companyListObject).length;
			},
			showSearchBlock() {
				const numberOfCompanies = Math.max(this.companies.length, this.numberOfCompaniesInStore);
				return numberOfCompanies >= 5 || this.$store.getters.userHasRoleCoach();
			},
			showNoResults() {
				return this.filteredCompanies.length === 0 && this.companies.length > 0;
			},
			numberOfPlaceholderCompanies() {
				if (this.numberOfCompaniesInStore && this.numberOfCompaniesInStore < 5) {
					return this.numberOfCompaniesInStore;
				}
				return NUMBER_COMPANIES_TO_HIDE_SEARCH;
			}
		},

		methods: {
			async loadCompanies() {
				this.warningMessage = '';
				await Api.getCompanies()
					.then((response) => {
						this.companies = response.responseObject;
						this.$store.dispatch('updateCompanyList', this.companies.map(company => ({
							id: company.id,
							externalId: company.externalId,
							name: company.name,
							permission: {...company.permission},
							checks: [...company.checks]
						})));
					})
					.catch(() => this.warningMessage = 'Overzicht van bedrijven kan niet geladen worden')
					.finally(() => this.loading = false);
			}
		},

		mounted() {
			this.loadCompanies();
		}
	};
</script>
