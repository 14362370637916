<template>
	<div v-if="loading" title="Gegevens worden geladen">
		<skeleton-content-form :columns="1" :rows="6" title="Bezorgadres" />
	</div>

	<p v-else-if="loadingFailed && warningMessage">
		{{ warningMessage }}
	</p>

	<div v-else>

		<div class="block-form-title">
			<h2>Bezorgadres (geen postbus)</h2>
		</div>

		<div class="block-form">
			<vuelidate-form
					:is-submitting="isSubmitting"
					:vuelidate="v$"
					class="content-form"
					submit-button-text="Bestellen"
					@validSubmit="handleSubmit">

				<form-select v-model="v$.form.companyId.$model"
				             :validation="v$.form.companyId"
				             label="Kies één van je bedrijven"
				             required
				             @input="updateCompanyFields">
					<option value="">Selecteer een bedrijf</option>
					<option v-for="company in companies"
					        :key="company.id"
					        :value="company.id">
						{{ company.name }}
					</option>
				</form-select>

				<template>
					<div v-if="form.companyId" class="row">
						<div class="col-6 col-sm-12">

							<form-input v-model.trim="v$.form.name.$model"
							            :validation="v$.form.name"
							            autocomplete="organization"
							            label="Bedrijfsnaam"
							            maxlength="255"
							            name="username"
							            placeholder="Bedrijfsnaam"
							            required />
							<label>
								Straatnaam en huisnummer *
							</label>

							<div>
								<form-input v-model.trim="v$.form.contactInfo.street.$model"
								            :validation="v$.form.contactInfo.street"
								            hide-label
								            inline-block
								            input-class="input-medium"
								            label="Straatnaam"
								            maxlength="255"
								            placeholder="Straatnaam"
								            required />
								<!-- Vue strips all whitespace, old css depends on it, so that's why there's a &nbsp;-->
								&nbsp;
								<form-input v-model.trim="v$.form.contactInfo.houseNumber.$model"
								            :validation="v$.form.contactInfo.houseNumber"
								            hide-label
								            inline-block
								            input-class="input-small"
								            label="Huisnummer"
								            maxlength="255"
								            placeholder="Nr."
								            required />
							</div>

							<form-input v-model.trim="v$.form.contactInfo.zipCode.$model"
							            :validation="v$.form.contactInfo.zipCode"
							            label="Postcode"
							            maxlength="255"
							            placeholder="Postcode"
							            required />

							<form-input v-model.trim="v$.form.contactInfo.city.$model"
							            :validation="v$.form.contactInfo.city"
							            label="Plaats"
							            maxlength="255"
							            placeholder="Plaats"
							            required />

							<form-select v-model="v$.form.membership.$model"
							             :options="membershipOptions"
							             :validation="v$.form.membership"
							             label="Lidmaatschap partners 5xbeter"
							             required />

						</div>
					</div>
				</template>
			</vuelidate-form>
		</div>
	</div>
</template>

<script>
	import {alphaNum, maxLength, required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import FormInput from '@/components/forms/FormInput';
	import VuelidateForm from '@/components/forms/VuelidateForm';
	import FormSelect from '@/components/forms/FormSelect';
	import {membershipOptions} from '@/utils/companyHelper';
	import {dutchZipCode} from '@/utils/customValidators';
	import SkeletonContentForm from '../generic/SkeletonContentForm';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormCheckout',
		components: {SkeletonContentForm, FormSelect, VuelidateForm, FormInput},
		props: {},
		data() {
			return {
				loading: true,
				loadingFailed: false,
				isSubmitting: false,
				warningMessage: '',
				companies: [],
				form: {
					companyId: null,
					name: '',
					membership: '',
					contactInfo: {
						street: '',
						houseNumber: '',
						zipCode: '',
						city: '',
						email: ''
					},
					products: []
				}
			};
		},
		computed: {
			membershipOptions() {
				return membershipOptions;
			},
			shoppingCartItems() {
				return this.$store.state.shoppingCart.items;
			}
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations() {
			return {
				form: {
					name: {
						required,
						maxLength: maxLength(255)
					},
					membership: {
						required
					},
					companyId: {
						required
					},
					contactInfo: {
						street: {
							required,
							maxLength: maxLength(255)
						},
						houseNumber: {
							required,
							alphaNum,
							maxLength: maxLength(255)
						},
						zipCode: {
							required,
							dutchZipCode
						},
						city: {
							required,
							maxLength: maxLength(255)
						}
					}
				}
			};
		},
		methods: {
			initPage() {
				this.loadCompanyData();
			},
			resetPage() {
				this.form = {};
			},
			async loadCompanyData() {
				this.loading = true;
				await Api.getCompanyDataForOrder().then((response) => {
					this.companies = response.responseObject;
				}).catch(() => {
					this.warningMessage = 'Lijst met bedrijven ophalen niet gelukt';
					this.loadingFailed = true;
				}).finally(() => {
					this.loading = false;
				});
			},
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				this.form.products = [...this.shoppingCartItems];
				await Api.postOrder(this.form)
					.then(() => {
						this.$emit('success');
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Bestelling afronden is niet gelukt';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			updateCompanyFields(companyId) {
				if (!companyId) {
					return;
				}
				const companyIdAsNumber = parseInt(companyId);
				const selectedCompany = this.companies.find(company => company.id === companyIdAsNumber);

				this.form = {
					companyId,
					name: selectedCompany.name,
					membership: selectedCompany.membership,
					contactInfo: {
						street: selectedCompany.contactInfo.street,
						houseNumber: selectedCompany.contactInfo.houseNumber,
						zipCode: selectedCompany.contactInfo.zipCode,
						city: selectedCompany.contactInfo.city,
						email: selectedCompany.contactInfo.email
					}
				};

			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
