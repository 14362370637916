<template>
	<icon-welding-smoke v-if="check === checkHelper.WELDING_SMOKE.enum" />
	<icon-machine-safety v-else-if="check === checkHelper.MACHINE_SAFETY.enum" />
	<icon-noise-hazard v-else-if="check === checkHelper.NOISE_HAZARD.enum" />
	<icon-physical-strain v-else-if="check === checkHelper.PHYSICAL_STRAIN.enum" />
	<icon-temp-work-at-height v-else-if="check === checkHelper.TEMP_WORK_AT_HEIGHT.enum" />
	<icon-hazardous-substances v-else-if="check === checkHelper.HAZARDOUS_SUBSTANCES.enum" />
</template>

<script>

	import IconWeldingSmoke from '../icons/IconWeldingSmoke';
	import IconMachineSafety from '../icons/IconMachineSafety';
	import IconNoiseHazard from '../icons/IconNoiseHazard';
	import IconPhysicalStrain from '../icons/IconPhysicalStrain';
	import IconTempWorkAtHeight from '../icons/IconTempWorkAtHeight';
	import IconHazardousSubstances from '../icons/IconHazardousSubstances';
	import {checkHelper} from '../../utils/checkHelper';

	export default {
		name: 'IconCheck',
		components: {
			IconHazardousSubstances,
			IconTempWorkAtHeight, IconPhysicalStrain, IconNoiseHazard, IconMachineSafety, IconWeldingSmoke
		},
		props: {
			check: {
				type: String,
				required: true,
				validator: value => checkHelper.isValidCheck(value)
			}
		},
		computed: {
			checkHelper() {
				return checkHelper;
			}
		}
	};
</script>
