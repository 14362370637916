<template>
	<base-grid :page-title="pageTitle">
		<template v-slot:sidebar>
			<sidebar-physical-strain :function-groups="functionGroups" />
		</template>

		<p v-if="loading">Overzicht van antwoorden wordt geladen</p>

		<template v-if="!loading && script">
			<last-edited v-if="givenAnswers.lastUpdated"
			             :date="givenAnswers.lastUpdated"
			             :name="givenAnswers.lastUpdatedBy" />

			<answers-overview-question v-for="question in script.questions"
			                           :key="question.questionId"
			                           :answers="getAnswersValuesForQuestion(question)"
			                           :has-write-access="hasWriteAccess"
			                           :question="question"
			                           @editAnswer="editAnswer" />
		</template>

	</base-grid>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import BaseGrid from '@/components/decorations/BaseGrid';
	import {PHYSICAL_STRAIN_STORE} from '@/store/check/physicalstrain/physicalStrainMethods.js';
	import AnswersOverviewQuestion from '@/components/check/AnswersOverviewQuestion';
	import LastEdited from '@/components/generic/LastEdited';
	import SidebarPhysicalStrain from '@/components/check/physicalstrain/SidebarPhysicalStrain';
	import {checkStoreMethod} from '@/store/check/checkStore';
	import {rightsHelper} from '@/utils/rightsHelper';
	import NumberUtils from '@/utils/numberUtils';
	import {ClientAnswerService} from '@/services/check/clientAnswerService';

	export default {
		name: 'PagePhysicalAnswersOverview',
		components: {SidebarPhysicalStrain, LastEdited, AnswersOverviewQuestion, BaseGrid},
		props: {
			functionGroups: {
				type: Array,
				default: () => []
			}
		},
		data: () => ({
			loading: true
		}),
		computed: {
			companyId() {
				return NumberUtils.stringToNumber(this.$route.params.companyId);
			},
			functionGroupId() {
				return this.$route.query.functionGroupId;
			},
			scriptKey() {
				return this.$route.query.scriptKey;
			},
			hasWriteAccess() {
				return rightsHelper.hasWriteAccess(this.$store.getters.readWriteAccess(this.companyId));
			},
			givenAnswers() {
				return this.$store.getters[PHYSICAL_STRAIN_STORE.GET_CLIENT_ANSWERS](this.scriptKey);
			},
			script() {
				return this.$store.getters[PHYSICAL_STRAIN_STORE.GET_SCRIPT](this.scriptKey);
			},
			pageTitle() {
				return this.script?.scriptTitle ?? 'Vragen worden geladen...';
			}
		},
		methods: {
			getAnswersValuesForQuestion(question) {
				return ClientAnswerService.findQuestionAnswerValuesByClientAnswers(question, this.givenAnswers?.answers);
			},

			editAnswer(question) {
				const query = {
					scriptKey: this.scriptKey,
					questionId: question.questionId,
					resume: true
				};

				if (this.functionGroupId) {
					query.functionGroupId = this.functionGroupId;
				}

				this.$router.push({
					name: pageHelper.PAGE_CHECK_PHYSICAL_STRAIN,
					query
				});
			},

			async loadScript() {
				if (this.scriptKey === undefined || this.companyId === undefined) {
					return Promise.resolve();
				}
				await this.$store.dispatch(PHYSICAL_STRAIN_STORE.LOAD_SCRIPT, {
					scriptKey: this.scriptKey,
					companyId: this.companyId
				});
			},

			async loadGivenAnswers() {
				if (this.scriptKey === undefined || this.companyId === undefined || this.functionGroupId === undefined) {
					return Promise.resolve();
				}
				await this.$store.dispatch(PHYSICAL_STRAIN_STORE.LOAD_ANSWERS, {
					scriptKey: this.scriptKey,
					companyId: this.companyId,
					functionGroupId: this.functionGroupId
				});
			},

			async init() {
				this.loading = true;
				await Promise.all([this.loadScript(), this.loadGivenAnswers()])
					.catch(() => this.$store.dispatch(checkStoreMethod.SET_WARNING, 'Antwoorden overzicht kon niet geladen worden.'))
					.finally(() => this.loading = false);
			}
		},
		watch: {
			scriptKey() {
				this.init();
			},
			companyId() {
				this.init();
			},
			functionGroupId() {
				this.init();
			}
		},
		mounted() {
			this.init();
		}
	};
</script>
