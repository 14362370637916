<template>

	<dialog-container :show="show"
	                  dialog-id="invite-form"
	                  title="Uitnodigen meekijker"
	                  @close="closeDialog">
		<div class="popup-content">

			<p class="p-small-lineheight text-bold">De uit te nodigen persoon is een:</p>

			<div class="button-bar invite-form_choose">
				<button :class="invitationRole === ROLE_COACH ? 'active' : ''"
				        class="button"
				        type="button"
				        @click="invitationRole = ROLE_COACH">Verbetercoach
				</button>
				<button :class="invitationRole === ROLE_COLLEAGUE ? 'active' : ''"
				        class="button"
				        type="button"
				        @click="invitationRole = ROLE_COLLEAGUE">Collega
				</button>
				<button :class="invitationRole === ROLE_EXTERNAL_ADVISOR ? 'active' : ''"
				        class="button"
				        type="button"
				        @click="invitationRole = ROLE_EXTERNAL_ADVISOR">Externe adviseur
				</button>
			</div>
		</div>

		<form-invite :coach-invited="coachInvited"
		             :companyId="companyId"
		             :invite-role="invitationRole"
		             :is-company-id-external="isCompanyIdExternal"
		             @close="closeDialog" />

	</dialog-container>

</template>

<script>
	import FormInvite from './FormInvite';
	import DialogContainer from '../../plugins/confirmDialog/DialogContainer';
	import {checkHelper} from '@/utils/checkHelper';
	import {inviteeRole} from '@/types/InviteeRole';

	export default {
		name: 'ModalInvitation',
		components: {DialogContainer, FormInvite},
		props: {
			show: {
				type: Boolean,
				default: false
			},
			coachInvited: {
				type: Boolean,
				default: false
			},
			companyId: {
				type: Number,
				required: true
			},
			isCompanyIdExternal: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			ROLE_COACH() {
				return inviteeRole.ROLE_COACH;
			},
			ROLE_COLLEAGUE() {
				return inviteeRole.ROLE_COLLEAGUE;
			},
			ROLE_EXTERNAL_ADVISOR() {
				return inviteeRole.ROLE_EXTERNAL_ADVISOR;
			},
			rightsLabel() {
				if (this.inviteRole === inviteeRole.ROLE_EXTERNAL_ADVISOR) {
					return 'De externe adviseur mag';
				}

				return 'Je collega mag';
			},
			isCoach() {
				return this.inviteRole === inviteeRole.ROLE_COACH;
			},
			checksArray() {
				return checkHelper.getChecksArray();
			}
		},

		data() {
			return {
				invitationRole: inviteeRole.ROLE_COACH
			};
		},
		methods: {
			closeDialog(reload = false) {
				if (reload) {
					this.$router.go();
				}
				this.$emit('close');
			}
		}
	};
</script>
