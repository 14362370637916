import {arrayToObjectByKey} from '@/utils/array';
import {rights} from '@/types/Rights';
import {inviteeRole} from '@/types/InviteeRole';
import {Api} from '@/utils/api';

const company = {
	namespaced: false,

	state: () => ({
		companyListObject: {},
		current: {
			name: ''
		}
	}),

	getters: {

		isCompanyLoaded: state => companyId => state.companyListObject[companyId] !== undefined,

		companyNameById: state => (companyId) => {
			const companyObject = state.companyListObject[companyId];
			if (companyObject && companyObject.name) {
				return companyObject.name;
			}
			return '...';
		},

		companyByExternalId: state => externalId =>
			Object.values(state.companyListObject).find(item => String(item.externalId) === externalId),

		/**
		 * @returns whether the user has no access, read only access or write access to the company. When the user is a
		 * company admin, it will also return write access.
		 */
		readWriteAccess: state => (id) => {
			const companyObject = state.companyListObject[id];
			return companyObject?.permission?.hasWriteAccess ? rights.WRITE : rights.READONLY;
		},

		/**
		 * @returns Whether the user has no access, read access, write access or admin access to the company
		 */
		highestLevelAccess: (state, getters) => (id) => {
			if (getters.isUserCompanyOwnerOrAdmin(id) || getters.isUserInvitedAsCoachByCompany(id)) {
				return rights.ADMIN;
			}

			return getters.readWriteAccess(id);
		},

		checkAccess: state => (id) => {
			const companyObject = state.companyListObject[id];
			return companyObject?.checks?.map(check => check.check) ?? [];
		},

		/**
		 * @returns the role of the user in the company (COACH, COLLEAGUE, EXTERNAL_ADVISOR, COMPANY_OWNER).
		 * If the user is not invited, it will return null.
		 */
		invitedRoleByCompanyId: state => (companyId) => {
			const companyObject = state.companyListObject[companyId];
			return companyObject?.permission?.userRole ?? null;
		},

		/**
		 * @returns whether a coach has been invited to the company.
		 */
		hasCoachInvited: state => (companyId) => {
			const companyObject = state.companyListObject[companyId];
			return companyObject?.permission?.coachInvited ?? false;
		},

		/**
		 * @returns whether the user is invited as a coach to the company.
		 */
		isUserInvitedAsCoachByCompany: (state, getters) => companyId =>
			getters.invitedRoleByCompanyId(companyId) === inviteeRole.ROLE_COACH,

		isUserCompanyOwner: state => (companyId) => {
			const companyObject = state.companyListObject[companyId];
			return companyObject?.permission?.companyOwner ?? false;
		},

		isUserCompanyAdmin: state => (companyId) => {
			const companyObject = state.companyListObject[companyId];
			return companyObject?.permission?.companyAdmin ?? false;
		},

		isUserCompanyOwnerOrAdmin: (state, getters) => companyId =>
			getters.isUserCompanyOwner(companyId) || getters.isUserCompanyAdmin(companyId)
	},
	mutations: {
		setCompanyListObject(state, companyList) {
			state.companyListObject = companyList;
		},

		setNameOfCurrentCompany(state, companyName) {
			state.current.name = companyName;
		}
	},

	actions: {
		async loadCompany({dispatch}, companyId) {
			await Api.getCompanyChecksOverview(companyId).then((response) => {
				dispatch('updateCompany', response.responseObject);
			});
		},

		updateCompany({commit, state}, companyData) {
			const company = {};
			company[companyData.id] = companyData;
			const smallCompanyListObject = {...state.companyListObject, ...company};
			commit('setCompanyListObject', smallCompanyListObject);
			commit('setNameOfCurrentCompany', companyData.name);
		},

		updateCompanyList({commit}, companyList) {
			const smallCompanyList = companyList.map(company => ({
				id: company.id,
				externalId: company.externalId,
				name: company.name,
				permission: company.permission,
				checks: company.checks
			}));
			const smallCompanyListObject = arrayToObjectByKey(smallCompanyList, 'id');
			commit('setCompanyListObject', smallCompanyListObject);
		}
	}
};

export default company;
