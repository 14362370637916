<template>

	<dialog-container :show="show"
	                  @close="closeDialog"
	                  dialog-id="invite-form"
	                  title="Aanpassen meekijker">
		<form-edit-invite :company-id="companyId" :invitee="invitee" @close="closeDialog" />
	</dialog-container>

</template>

<script>
	import DialogContainer from '../../plugins/confirmDialog/DialogContainer';
	import FormEditInvite from './FormEditInvite';

	export default {
		name: 'ModalEditGuestPermissions',
		components: {FormEditInvite, DialogContainer},
		props: {
			companyId: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				show: false,
				invitee: null
			};
		},
		computed: {
			invitationRole() {
				return this.invitee.role;
			}
		},
		methods: {
			closeDialog(doReload) {
				this.show = false;
				this.invitee = null;
				this.$emit('close', doReload);
			},
			open(invitee) {
				this.invitee = invitee;
				this.show = true;
			}
		}
	};
</script>
