<template>
	<base-grid :page-title="pageTitle" sidebar="company">

		<p v-if="warningMessage">
			{{ warningMessage }}
		</p>

		<div v-if="loading && visitReport === null" title="Bezoekverslag wordt geladen">
			<skeleton-visit-report />
		</div>
		<card-block v-if="!loading && visitReport !== null">

			<template v-if="!editMode && !ratingMode">

				<visit-report-overview :visit-report="visitReport" />

				<div class="button-bar">
					<button v-if="userIsCreator && !visitReport.archived"
					        class="button"
					        type="button"
					        @click="editMode = !editMode">
						Bezoekverslag bewerken
					</button>

					<button v-if="userIsCompanyAdminOrOwner"
					        class="button"
					        type="button"
					        @click="ratingMode = !ratingMode">
						{{ ratingButtonTitle() }}
					</button>

					<button v-if="userIsCreator"
					        class="button button-no-border"
					        type="button"
					        @click="confirmDeleteVisitReport(visitReportId, visitReport.reason)">
						<i class="icon-delete" />Verwijder bezoekverslag
					</button>

					<button v-if="userIsCompanyAdminOrOwner"
					        class="button button-no-border"
					        type="button"
					        @click="updateArchivedStatus">
						{{ visitReport.archived ? 'De-archiveren' : 'Archiveren' }}
					</button>
				</div>

			</template>

			<template v-if="editMode">
				<form-visit-report :visit-report="visitReport"
				                   @cancel="editMode = false"
				                   @submitted="updatePage()" />
			</template>

			<template v-if="ratingMode">

				<visit-report-overview :visit-report="visitReport"
				                       compact-mode
				                       style="margin-bottom: 0;" />

				<form-visit-report-rating :visit-report="visitReport"
				                          @cancel="ratingMode = false"
				                          @submitted="updatePage()" />
			</template>

		</card-block>

	</base-grid>
</template>

<script>
	import {Api} from '@/utils/api';
	import BaseGrid from '../decorations/BaseGrid';
	import {getFormattedDate} from '@/utils/date';
	import {getThemeTitle} from '@/utils/themeHelper';
	import {getTextForBoolean, getTextOrFallback} from '@/utils/text';
	import CardBlock from '../generic/CardBlock';
	import VisitReportOverview from './VisitReportOverview';
	import FormVisitReport from './FormVisitReport';
	import {pageHelper} from '@/utils/pageHelper';
	import SkeletonVisitReport from './SkeletonVisitReport';
	import FormVisitReportRating from '@/components/visitreports/FormVisitReportRating';

	export default {
		name: 'PageVisitReportDetail',
		components: {
			FormVisitReportRating,
			SkeletonVisitReport, FormVisitReport, VisitReportOverview, CardBlock, BaseGrid
		},
		props: {
			companyId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			},
			visitReportId: {
				type: [Number, String],
				required: true,
				validator: value => Number.isInteger(Number(value))
			}
		},
		data() {
			return {
				loading: false,
				warningMessage: '',
				visitReport: null,
				editMode: false,
				ratingMode: false
			};
		},
		computed: {
			pageTitle() {
				return `Bezoekverslag voor ${this.$store.state.company.current.name}`;
			},
			userIsCreator() {
				return this.$store.getters.userHasId(this.visitReport.createdBy.id);
			},
			userIsCompanyAdminOrOwner() {
				return this.$store.getters.isUserCompanyOwner(this.companyId)
					|| this.$store.getters.isUserCompanyAdmin(this.companyId);
			}
		},
		methods: {
			async loadVisitReportById() {
				await Api.getVisitReport(this.companyId, this.visitReportId)
					.then((response) => {
						this.visitReport = response.responseObject;
					})
					.catch(() => {
						this.warningMessage = 'Bezoekverslag kon niet geladen worden';
					})
					.finally(() => {
						this.loading = false;
					});
			},
			async updateArchivedStatus() {
				await Api.postUpdateVisitReportArchivedStatus(this.companyId, this.visitReportId)
					.then(() => {
						this.visitReport.archived = !this.visitReport.archived;
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = '(De)-Archiveren van bezoekverslag is mislukt.';
					});
			},
			initPage() {
				this.loading = true;
				this.loadVisitReportById();
			},
			resetPage() {
				this.editMode = false;
				this.ratingMode = false;
				this.visitReport = null;
			},
			updatePage() {
				this.resetPage();
				this.initPage();
			},
			ratingButtonTitle() {
				return this.visitReport.rating ? 'Beoordeling bewerken' : 'Bezoekverslag beoordelen';
			},
			confirmDeleteVisitReport(reportId, reason) {
				this.$confirmDialog({
						title: 'Bezoekverslag verwijderen',
						content: `Weet je zeker dat je het bezoekverslag '${reason}' wilt verwijderen?`,
						confirmButton: 'Verwijderen',
						dialogType: 'warning'
					})
					.then(() => {
						this.deleteVisitReport(reportId);
					});
			},
			deleteVisitReport(reportId) {
				Api.deleteVisitReport(this.companyId, reportId)
					.then(() => {
						this.$router.push({
							name: pageHelper.PAGE_COMPANY_VISIT_REPORT_OVERVIEW
						});
					})
					.catch(() => {
						this.$alertDialog({
							title: 'Bezoekverslag verwijderen mislukt',
							content: `Bezoekverslag verwijderen is niet gelukt.`,
							dialogType: 'warning'
						});
					});
			},
			getFormattedDate,
			getThemeTitle,
			getTextOrFallback,
			getTextForBoolean
		},
		watch: {
			$route() {
				this.updatePage();
			}
		},
		mounted() {
			this.initPage();
		}
	};
</script>
