<template>
	<li>
		<skeleton-block>
			<a style="pointer-events: none; color: currentColor">
				<skeleton-text-placeholder length="12" />
			</a>
		</skeleton-block>
	</li>
</template>
<script>
	import SkeletonBlock from '@/components/generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '@/components/generic/SkeletonTextPlaceholder';

	export default {
		components: {SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
