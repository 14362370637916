import {helpers} from '@vuelidate/validators';

/**
 * Checks if string is a valid Dutch Zipcode
 * @param {string} zipcode Case insensitive, should have white space trimmed.
 * @return {boolean}
 */
export const dutchZipCode = (zipcode) => {
	// Based on https://stackoverflow.com/a/17898538
	const zipCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
	return !helpers.req(zipcode) || zipCodeRegex.test(zipcode);
};

/**
 * Checks if value is a valid Dutch Phonenumber
 * @param {string} value Should have white space trimmed.
 * @return {boolean}
 */
export const dutchPhoneNumber = (value) => {
	// Based on https://github.com/jquery-validation/jquery-validation/blob/master/src/additional/phoneNL.js
	const phoneRexeg = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9]){8}$/;
	return !helpers.req(value) || phoneRexeg.test(value);
};

export const password = (value) => {
	const validPassword = value.length >= 8
		&& /[A-Z]/.test(value)
		&& /[a-z]/.test(value)
		&& /\d/.test(value);

	return !helpers.req(value) || validPassword;
};


/**
 * Check if date is valid
 * @param {string} value String of a date in the yyyy-mm-dd format
 * @return {boolean}
 */
export const validDate = (value) => {
	// Based on http://html5pattern.com/Dates#dates_pattern2
	const dateRegex = /^(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))$/;
	return !helpers.req(value) || dateRegex.test(value);
};

/**
 * Checks if value is True
 * @param {boolean} value Boolean value to be checked
 * @return {boolean}
 */
export const mustBeTrue = value => value === true;


