<template>
	<form :class="isSubmitting ? 'vuelidate-form--submitting' : ''"
	      @mouseleave="$emit('mouseleave')"
	      @submit="validateAndSubmitForm"
	      class="vuelidate-form"
	      novalidate
	      ref="form">
		<slot></slot>

		<form-warning-block :message="clientSideValidationMessage" v-if="clientSideValidationMessage" />

		<form-warning-block :message="warningMessage" v-if="warningMessage" />

		<div class="button-bar" v-if="!hideButtonBar">
			<slot name="button-bar">
				<button :disabled="isSubmitting" class="button" type="submit">{{ submitButtonText }}</button>
				<button :disabled="isSubmitting"
				        @click="$emit('cancel')"
				        class="button button-no-border"
				        type="button"
				        v-if="cancelButtonText.length > 0">
					{{ cancelButtonText }}
				</button>
			</slot>
		</div>

		<slot name="after-button-bar"></slot>
	</form>
</template>

<script>

	import FormWarningBlock from './FormWarningBlock';

	export default {
		name: 'VuelidateForm',
		components: {FormWarningBlock},
		props: {
			vuelidate: {
				type: Object,
				required: true
			},
			hideButtonBar: {
				type: Boolean,
				default: false
			},
			isSubmitting: {
				type: Boolean,
				default: false
			},
			submitButtonText: {
				type: String,
				default: 'Verzend'
			},
			cancelButtonText: {
				type: String,
				default: ''
			},
			warningMessage: {
				type: String,
				default: ''
			}
		},
		data() {
			return {};
		},
		computed: {
			clientSideValidationMessage() {
				if (this.vuelidate.$invalid && this.vuelidate.$dirty) {
					return 'Niet alle velden zijn correct ingevuld';
				}

				return null;
			}
		},
		methods: {
			validateAndSubmitForm(event) {
				event.preventDefault();
				this.$emit('preValidation');
				this.vuelidate.$touch();
				if (this.vuelidate.$invalid) {
					return;
				}

				this.$emit('validSubmit', event);

			}
		}
	};
</script>

<style lang="scss">
	.vuelidate-form {
		position: relative;
		z-index: 1;

		.button-bar {
			margin-top: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		button[type=submit].button {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 16px 16px;
		}

		&:after {
			content: '';
			background: #ffffff;
			opacity: 0;
			position: absolute;
			z-index: 99;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			cursor: wait;
			pointer-events: none;
			visibility: hidden;
		}

		&.vuelidate-form--submitting {
			&:after {
				opacity: .25;
				visibility: visible;
				pointer-events: auto;
				transition: all .2s ease-in-out;
			}

			button[type=submit].button {
				color: transparent;
				cursor: wait;
				background-image: url('@/assets/loader.gif');
			}
		}
	}
</style>
