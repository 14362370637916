import store from '@/store';
import {pageHelper} from '@/utils/pageHelper';
import ErrorPage404 from '@/components/ErrorPage404';
import PageNoRights from '@/components/PageNoRights';

/**
 * An overview of permissions can be found here:
 * https://docs.google.com/spreadsheets/d/1G4whMO7pzANOM3HjUZDIC_fMSGHVqgu0zl24voSH3-M/edit?usp=sharing
 */
export default () => [
	{
		path: '/',
		redirect: {
			name: store.state.user.isLoggedIn ? pageHelper.PAGE_MY_COMPANIES : pageHelper.PAGE_LOGIN
		}
	},
	{
		path: '/:pathMatch(.*)*',
		component: ErrorPage404
	},
	{
		path: '/geen-toegang',
		name: pageHelper.PAGE_NO_RIGHTS,
		component: PageNoRights,
		meta: {
			noRedirectForLogin: true,
			breadcrumb: 'Geen toegang'
		}
	}
];
