<template>
	<div class="header-fixed-container">
		<header>
			<div class="header-container">
				<div class="header-logo">
						<span>
							<a href="https://www.5xbeter.nl">
								<img alt="5xbeter"
								     src="../../assets/logo-5xbeter.png"
								     srcset="../../assets/logo-5xbeter.svg" />
							</a>
						</span>
				</div>

				<div class="header-actions">
					<div class="button-bar">
						<button class="button-header"
						        type="button"
						        @click="saveImprovementCheck">
							{{ saveButtonTitle }}
						</button>

						<button class="button-header" type="button" @click="askForHelp">
							Hulp nodig?
						</button>

						<button class="button-header" type="button" @click="leaveCheck">
							<em class="icon-close"></em>
						</button>
					</div>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	import {pageHelper} from '@/utils/pageHelper';
	import NumberUtils from '@/utils/numberUtils';

	export default {
		name: 'ImprovementCheckHeader',
		data: () => ({
			saved: false
		}),
		computed: {
			companyId() {
				return NumberUtils.stringToNumber(this.$route.params.companyId);
			},
			saveButtonTitle() {
				return this.saved ? 'Opgeslagen' : 'Opslaan';
			}
		},
		methods: {
			saveImprovementCheck() {
				this.saved = !this.saved;
				// TODO: Implement
			},
			askForHelp() {
				// TODO: Implement
			},
			leaveCheck() {
				this.$router.push({
					name: pageHelper.PAGE_PHYSICAL_RESULTS,
					params: {
						companyId: this.companyId
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../../assets/variables';
	@import '../../assets/mixins';

	.button {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		line-height: 20px;
		padding: 8px 20px;
		text-align: center;
		text-decoration: none;
		background: invert($content-color);
		background: rgba(255, 255, 255, 0);
		color: $blue;
		border: 2px solid $blue;
		border-radius: 0;
		max-width: 100%;

		-webkit-transition: background 0.2s, border-color 0.2s, color 0.3s, opacity 0.3s;
		transition: background 0.2s, border-color 0.2s, color 0.3s, opacity 0.3s;

		&:hover,
		&.active {
			background: $blue;
			color: #ffffff;
			text-decoration: none;
		}

		&:focus:not(:focus-visible) {
			outline: none;
		}

		&.disabled,
		&.disabled:hover {
			opacity: 0.25;
			cursor: default;
			color: $blue;
			background: invert($content-color);
			background: rgba(255, 255, 255, 0);
		}

		&.hidden {
			display: none;
		}

		.button-bar & {
			margin: 0 10px 0 0;
		}

		[class^="icon-"], [class*=" icon-"] {
			&:first-child {
				margin-right: 7px;
			}
		}

		.icon-delete {
			margin-bottom: 2px;
		}

		.icon-help {
			&:first-child {
				margin-right: 14px;
			}
		}

		p:not(.button-bar) & {
			margin-top: 4px;
		}
	}

	.button-header {
		@extend .button;

		background: #000000;
		color: #ffffff;
		border: none;
		border-top: 3px solid transparent;
		border-bottom: 3px solid #444444;
		font-weight: 600;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 0 !important;
		width: auto !important;
		min-width: 40px;
		text-align: center;

		.button-bar & {
			margin: 0 0 0 10px;
		}

		&:hover {
			border-bottom-color: $blue;
		}

		&:after {
			all: unset;
		}
	}

	span.button-header:hover {
		background: inherit;
	}

	.button-bar {
		font-size: 0;
		margin: 0 0 28px;

		@media #{$mobile} {
			margin: 10px 0 20px;

			.button {
				width: 100%;
				margin: 10px 0 0;
			}

			.button-arrow {
				width: max-content;
			}
		}

		.gs-container & {
			margin-top: 14px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	header,
	.header-fixed {
		position: relative;
		z-index: 5;
		width: 100%;
		background: #000000;
		color: #ffffff;

		@include clearfix;
	}

	.header-fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: auto;
		background: #000000;
	}

	.header-container {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		padding: 5px 20px;
		min-height: 50px;

		@media screen and (max-width: 680px) {
			padding-right: 5px;
		}
	}

	.header-logo {
		float: left;
		max-width: 20%;

		a {
			-webkit-transition: opacity 0.2s;
			transition: opacity 0.2s;
		}

		a:hover {
			opacity: 0.75;
		}
	}

	@media screen and (max-width: 480px) {
		.header-logo a,
		.header-logo span {
			display: inline-block;
			width: 24px;
			height: 31px;
			overflow: hidden;
		}
		.header-logo img {
			max-width: none;
			width: 106px;
			height: 31px;
		}
	}

	.header-actions {
		max-width: 80%;
		float: right;
		text-align: right;
		white-space: nowrap;
		position: relative;
		bottom: -3px;

		.button-bar {
			margin: 0;
		}

		.icon-close,
		.icon-cross {
			font-size: 10px;
		}

		.icon-close:first-child:last-child {
			margin: 0;
		}
	}
</style>
