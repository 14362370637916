<template>
	<div class="block-container">
		<advices-list-item
				:advice="advice"
				:key="advice.id"
				v-for="advice in advices" />

		<card-block v-if="advices.length === 0">Geen adviezen</card-block>

	</div>
</template>

<script>
	import AdvicesListItem from './AdviceListItem';
	import CardBlock from '../generic/CardBlock';

	export default {
		name: 'AdvicesList',
		components: {CardBlock, AdvicesListItem},
		props: {
			advices: {
				type: Array,
				default: () => []
			}
		}
	};
</script>
