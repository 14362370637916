<template>
	<div class="block-container">
		<div :class="cardBlockClass"
		     class="block-result">
			<div class="block-result_title">
				<icon-status v-if="hasStatus" :color="status" />
				<h2>{{ title }}</h2>
			</div>
			<card-block-result-content>
				<slot></slot>
			</card-block-result-content>
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
	import {checkStatus, isValidCheckStatus} from '@/utils/checkHelper';
	import IconStatus from '@/components/icons/IconStatus';
	import CardBlockResultContent from '@/components/check/results/CardBlockResultContent';

	export default {
		name: 'CardBlockResult',
		components: {CardBlockResultContent, IconStatus},
		props: {
			title: {
				type: String,
				required: true
			},
			status: {
				type: String,
				validator: value => isValidCheckStatus(value),
				default: checkStatus.GREY.enum
			},
			hasStatus: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			statusColorClass() {
				switch (this.status) {
					case checkStatus.RED.enum:
						return 'red';
					case checkStatus.WHITE.enum:
						return 'white';
					case checkStatus.GREEN.enum:
						return 'green';
					default:
						return 'grey';
				}
			},
			cardBlockClass() {
				return this.hasStatus ? `block-result-${this.statusColorClass}` : ' block-result_no-border block-result_less-padding';
			}
		}
	};
</script>
<style scoped>
	.block-result.block-result-white {
		border-left: 2px solid #ffffff;
	}
</style>
