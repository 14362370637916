export const check = {
	WELDING_SMOKE: 'WELDING_SMOKE',
	MACHINE_SAFETY: 'MACHINE_SAFETY',
	NOISE_HAZARD: 'NOISE_HAZARD',
	PHYSICAL_STRAIN: 'PHYSICAL_STRAIN',
	TEMP_WORK_AT_HEIGHT: 'TEMP_WORK_AT_HEIGHT',
	HAZARDOUS_SUBSTANCES: 'HAZARDOUS_SUBSTANCES'
} as const;

export type ICheck = typeof check[keyof typeof check];
