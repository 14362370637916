<template>
	<div v-if="loading">
		<skeleton-content-form :columns="1" :rows="3" />
	</div>

	<p v-else-if="loadingFailed && warningMessage">
		{{ warningMessage }}
	</p>

	<div v-else class="block-form">
		<vuelidate-form
			:is-submitting="isSubmitting"
			:vuelidate="v$"
			:warning-message="formWarningMessage"
			class="content-form"
			submit-button-text="Opslaan"
			@validSubmit="handleSubmit">

			<form-select v-model.number="v$.form.fromCompanyId.$model"
			             :disabled="!!$route.query.from"
			             :validation="v$.form.fromCompanyId"
			             add-empty-option
			             label="Kies een bedrijf als bron"
			             required>
				<template v-if="$route.query.from">
					<option :key="fromCompanyObject.id"
					        :value="`${fromCompanyObject.id}`"
					        selected>
						{{ fromCompanyObject.id }} - {{ fromCompanyObject.name }}
					</option>
				</template>
				<template v-else>
					<option value="">--</option>
					<option v-for="company in companyList"
					        :key="company.id"
					        :value="`${company.id}`">
						{{ company.id }} - {{ company.name }}
					</option>
				</template>
			</form-select>

			<form-select v-model.number="v$.form.toCompanyId.$model"
			             :disabled="companyToList.length === 0"
			             :validation="v$.form.toCompanyId"
			             add-empty-option
			             label="Kies een bedrijf als doel"
			             required>
				<option value="">---</option>
				<option v-for="company in companyToList"
				        :key="company.id"
				        :disabled="company.id === fromCompanyObject.id"
				        :value="`${company.id}`">
					{{ company.id }} - {{ company.name }}
				</option>
			</form-select>

			<label>Te verplaatsen verbetercheck: *</label>
			<div class="checkbox-list">
				<radio-list-item v-for="check in checksArray"
				                 :key="check.enum"
				                 v-model="form.check"
				                 :disabled="!form.fromCompanyId || !form.toCompanyId"
				                 :label="check.title"
				                 :value="check.enum"
				                 name="checks" />
			</div>

			<p v-if="successMessage" class="success-message" v-text="successMessage"></p>
		</vuelidate-form>
	</div>
</template>

<script>
	import VuelidateForm from '@/components/forms/VuelidateForm';
	import {not, required, sameAs} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import FormSelect from '@/components/forms/FormSelect';
	import {checkHelper} from '@/utils/checkHelper';
	import RadioListItem from '@/components/forms/RadioListItem';
	import SkeletonContentForm from '@/components/generic/SkeletonContentForm';
	import {useVuelidate} from '@vuelidate/core';

	export default {
	name: 'FormMigrateImprovementCheck',
	components: {SkeletonContentForm, RadioListItem, FormSelect, VuelidateForm},
	data() {
		return {
			loading: true,
			loadingFailed: false,
			isSubmitting: false,
			warningMessage: '',
			successMessage: '',
			formWarningMessage: '',
			companyList: [],
			form: {
				fromCompanyId: null,
				toCompanyId: null,
				check: null
			}
		};
	},
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	validations() {
		return {
			form: {
				fromCompanyId: {
					required
				},
				toCompanyId: {
					required,
					notTheSameAsFromCompany: not(sameAs('fromCompanyId'))
				},
				check: {
					required
				}
			}
		};
	},
	computed: {
		companyId() {
			return this.$route.query.from;
		},
		companyToList() {
			if (this.fromCompanyObject) {
				return this.getCompaniesWithSameEmail();
			}

			return [];
		},
		fromCompanyObject() {
			return this.companyList.find(company => company.id === this.form.fromCompanyId);
		},
		checksArray() {
			return checkHelper.getChecksArray();
		}
	},
	methods: {
		getCompaniesWithSameEmail() {
			return this.companyList.filter(company => company.ownerEmail === this.fromCompanyObject.ownerEmail);
		},
		async handleSubmit() {
			this.$confirmDialog({
					title: 'Verbetercheck verplaatsen',
					content: 'Weet je zeker dat je de verbetercheck wilt verplaatsen?',
					confirmButton: 'Ja',
					cancelButton: 'Nee',
					dialogType: 'warning'
				})
				.then(() => {
					this.submitForm();
				});
		},
		async submitForm() {
			await Api.postMigrateImprovementCheck(this.form)
				.then((response) => {
					if (response.message && response.message.length > 0) {
						this.successMessage = response.message;
						return;
					}
					this.successMessage = 'Verbetercheck is succesvol verplaatst';
				})
				.catch((error) => {
					if (error.message && error.message.length > 0) {
						this.formWarningMessage = error.message;
						return;
					}
					this.formWarningMessage = 'Het is niet gelukt de verbetercheck te verplaatsen';
				}).finally(() => {
					this.isSubmitting = false;
				});
		},
		async getCompanyData() {
			this.loading = true;

			await Api.getAllCompanies().then((response) => {
				this.companyList = response.responseObject;
			}).catch((error) => {
				this.loadingFailed = true;
				if (error.message && error.message.length > 0) {
					this.formWarningMessage = error.message;
					return;
				}
				this.warningMessage = 'Bedrijven ophalen is niet gelukt';
			}).finally(() => {
				this.loading = false;
			});
		},

		setFormDataFromParameters() {
			if (this.$route.query.from) {
				this.form.fromCompanyId = parseInt(this.$route.query.from);
			}
			if (this.$route.query.to) {
				this.form.toCompanyId = parseInt(this.$route.query.to);
			}
		}

	},
	mounted() {
		this.setFormDataFromParameters();
		this.getCompanyData();
	}
};
</script>

<style lang="scss">
@import '../../../assets/variables';

.success-message {
	background: $green;
	padding: .5em 1em;
	font-weight: normal;
	color: #ffffff;
}
</style>
