import {IClientAnswer} from '@/types/check/IClientAnswer';
import {IAnswer, IQuestion} from '@/types/check/IQuestion';

export class ClientAnswerService {
	/**
	 *
	 * @returns Array of client answers for a specific question.
	 */
	static getClientAnswersForQuestion(clientScriptAnswers: IClientAnswer[], questionId: number): IClientAnswer[] {
		const clientAnswers = clientScriptAnswers?.filter(answer => answer.questionId === questionId);

		if (Array.isArray(clientAnswers)) {
			return clientAnswers;
		}

		return clientAnswers ? [clientAnswers] : [];
	}

	static mapClientAnswersToQuestionAnswers(question: IQuestion, clientAnswers: IClientAnswer[]): IAnswer[] {
		return question.answers.flatMap((questionAnswer) => {
			const localAnswer = clientAnswers.find(localAnswer => questionAnswer.id === localAnswer.answerId);
			if (!localAnswer) {
				return [];
			}
			return question.type === 'OPEN' ? {...questionAnswer, value: localAnswer.value} : questionAnswer;
		});
	}

	/**
	 * @returns Array of answer values for the given question mapped by the client answers.
	 */
	static findQuestionAnswerValuesByClientAnswers(question: IQuestion, clientScriptAnswers: IClientAnswer[]): string[] {
		return ClientAnswerService.mapClientAnswersToQuestionAnswers(question, clientScriptAnswers)
			.map(questionAnswer => questionAnswer.value);
	}

}
