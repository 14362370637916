<template>
	<div>
		<skeleton-block :key="index" style="margin-bottom: 20px;" v-for="(item,index) in [24, 35, 13]">
			<div style="padding: 8px 12px;">
				<div>
					<skeleton-text-placeholder :length="item" />
				</div>
			</div>
		</skeleton-block>
	</div>
</template>
<script>
	import SkeletonBlock from '@/components/generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '@/components/generic/SkeletonTextPlaceholder';

	export default {
		components: {SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
