export const arrayToObjectByKey = (array, keyField) =>
	array.reduce((obj, item) => {
		obj[item[keyField]] = item;
		return obj;
	}, {});

/**
 * Sorts an array by the date in the given key
 * @param {Array.<Object>} array Array of objects
 * @param {string} key Name of the property which contains the date to be sorted by
 * @param {string} [order=descending] Order to sort by, descending or ascending
 * @return {array}
 */
// eslint-disable-next-line arrow-body-style
export const sortArrayByDate = (array, key, order = 'descending') => {
	// Copy the array to avoid mutating the orignal array
	return [...array].sort((a, b) => {
		if (order === 'ascending') {
			return new Date(a[key]) - new Date(b[key]);
		}

		return new Date(b[key]) - new Date(a[key]);
	});
};

/**
 * Returns index of object inside an array with the given id value
 * @param {Array.<{id: number|string}>} array Array of objects
 * @param {string|number} id Value of id property for the object of which you want the index
 * @return {number} Index of the object with the given id. Returns -1 if not found.
 */
export const findIndexByIdValue = (array, id) => array.findIndex((item => item.id === id));
