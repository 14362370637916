<template>
	<div class="block-content block-content-blue">
		<vuelidate-form :is-submitting="isSubmitting"
		                :vuelidate="v$"
		                :warning-message="warningMessage"
		                @validSubmit="handleSubmit"
		                class="content-form"
		                submit-button-text="Bedrijf aanmaken">

			<div class="row">
				<div class="col-6 col-sm-12">
					<form-input :validation="v$.form.name"
					            autocomplete="organization"
					            label="Bedrijfsnaam"
					            maxlength="255"
					            name="username"
					            placeholder="Bedrijfsnaam"
					            required
					            v-model.trim="v$.form.name.$model" />
				</div>

				<div class="col-6 col-sm-12">
					<form-select :options="numberOfEmployeesOptions"
					             :validation="v$.form.numberOfEmployees"
					             label="Bedrijfsgrootte"
					             required
					             v-model="v$.form.numberOfEmployees.$model">
					</form-select>
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-sm-12">

					<form-select :options="brancheOptions"
					             :validation="v$.form.branch"
					             label="Branche"
					             required
					             v-model="v$.form.branch.$model" />
				</div>
				<div class="col-6 col-sm-12">
					<form-select :options="membershipOptions"
					             :validation="v$.form.membership"
					             label="Lidmaatschap partners 5xbeter"
					             required
					             v-model="v$.form.membership.$model" />
				</div>
			</div>

		</vuelidate-form>
	</div>

</template>

<script>
	import {maxLength, required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import {pageHelper} from '@/utils/pageHelper';
	import FormInput from '../forms/FormInput';
	import FormSelect from '../forms/FormSelect';
	import {brancheOptions, membershipOptions, numberOfEmployeesOptions} from '@/utils/companyHelper';
	import VuelidateForm from '../forms/VuelidateForm';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormAddCompany',
		components: {VuelidateForm, FormSelect, FormInput},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				showSticky: false,
				form: {
					name: '',
					numberOfEmployees: '<10',
					branch: 'Metaalbewerking',
					membership: 'Geen van beide'
				}
			};
		},
		validations: {
			form: {
				name: {
					required,
					maxLength: maxLength(255)
				},
				numberOfEmployees: {
					required
				},
				branch: {
					required
				},
				membership: {
					required
				}
			}
		},
		computed: {
			numberOfEmployeesOptions() {
				return numberOfEmployeesOptions;
			},
			brancheOptions() {
				return brancheOptions;
			},
			membershipOptions() {
				return membershipOptions;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await Api.postCreateCompany(this.form)
					.then((response) => {
						if (response.responseObject.id) {
							this.$router.push({
								name: pageHelper.PAGE_COMPANY_IMPROVEMENT_CHECKS_OVERVIEW,
								params: {
									companyId: response.responseObject.id
								}
							});
						} else {
							this.$router.push({
								name: pageHelper.PAGE_MY_COMPANIES
							});
						}
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Bedrijf aanmaken niet gelukt';
					}).finally(() => {
						this.isSubmitting = false;
					});
			}
		}
	};
</script>
