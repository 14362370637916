<template>
	<vuelidate-form :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="warningMessage"
	                cancel-button-text="Annuleren"
	                class="content-form content-form--edit-action"
	                submit-button-text="Bezoekverslag opslaan"
	                @cancel="$emit('cancel')"
	                @validSubmit="handleSubmit">

		<form-checkbox-list v-model="v$.form.themes.$model"
		                    :options="themeOptions"
		                    :validation="v$.form.themes"
		                    label="Thema's"
		                    multicolumn
		                    option-label="title"
		                    option-value="enum"
		                    required />

		<div class="row">
			<div class="col-6 col-sm-12">
				<form-datepicker v-model.trim="v$.form.dateAppointment.$model"
				                 :validation="v$.form.dateAppointment"
				                 label="Datum bezoek" />

			</div>
			<div class="col-6 col-sm-12">
				<label for="firstVisit">Eerste bezoek</label>
				<div class="checkbox-list">
					<checkbox-list-item
							id="firstVisit"
							v-model="form.firstVisit"
							label="Eerste bezoek"
							name="firstVisit" />
				</div>
			</div>
		</div>

		<form-input v-model.trim="form.createdBy.name"
		            input-class="input-fullwidth"
		            label="Aangemaakt door"
		            readonly />

		<form-input v-model.trim="v$.form.reason.$model"
		            :validation="v$.form.reason"
		            input-class="input-fullwidth"
		            label="Aanleiding bezoek"
		            required />

		<form-textarea v-model.trim="v$.form.recap.$model"
		               :validation="v$.form.recap"
		               fullwidth
		               input-class="input-fullwidth"
		               input-wrapper-class="input-inline"
		               label="Samenvatting"
		               required />

		<form-datepicker v-model.trim="v$.form.dateNextAppointment.$model"
		                 :validation="v$.form.dateNextAppointment"
		                 label="Datum vervolgbezoek" />
	</vuelidate-form>
</template>

<script>
	import {required} from '@vuelidate/validators';
	import {Api} from '@/utils/api';
	import VuelidateForm from '../forms/VuelidateForm';
	import {themes} from '@/utils/themeHelper';
	import FormTextarea from '../forms/FormTextarea';
	import {validDate} from '@/utils/customValidators';
	import FormInput from '../forms/FormInput';
	import CheckboxListItem from '../forms/CheckboxListItem';
	import FormDatepicker from '../forms/FormDatepicker';
	import {getToday} from '@/utils/date';
	import FormCheckboxList from '../forms/FormCheckboxList';
	import {useVuelidate} from '@vuelidate/core';

	export default {
		name: 'FormVisitReport',
		components: {FormCheckboxList, FormDatepicker, CheckboxListItem, FormInput, FormTextarea, VuelidateForm},
		props: {
			visitReport: {
				type: Object,
				default: () => ({})
			},
			newVisitReport: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isSubmitting: false,
				warningMessage: '',
				form: {
					themes: [],
					companyId: null,
					createdBy: {
						id: null,
						name: null
					},
					dateAppointment: '',
					dateNextAppointment: '',
					reason: '',
					recap: '',
					firstVisit: false
				}
			};
		},
		setup() {
			return {
				v$: useVuelidate()
			};
		},
		validations() {
			return {
				form: {
					themes: {
						required
					},
					dateAppointment: {
						required,
						validDate
					},
					dateNextAppointment: {
						validDate
					},
					reason: {
						required
					},
					recap: {
						required
					},
					firstVisit: {
						required
					}
				}
			};
		},
		computed: {
			themeOptions() {
				return themes;
			},
			companyId() {
				return this.$route.params.companyId;
			}
		},
		methods: {
			async handleSubmit() {
				this.isSubmitting = true;
				this.warningMessage = '';
				await this.doApiCall()
					.then(() => {
						this.$emit('submitted');
					})
					.catch((error) => {
						if (error.message && error.message.length > 0) {
							this.warningMessage = error.message;
							return;
						}
						this.warningMessage = 'Bezoekverslag kon niet worden opgeslagen';
					}).finally(() => {
						this.isSubmitting = false;
					});
			},
			initFormObject() {
				let visitReportNew = {};

				if (this.newVisitReport) {
					visitReportNew = {
						themes: [],
						companyId: this.companyId,
						createdBy: {
							name: this.$store.state.user.fullName
						},
						dateAppointment: getToday()
					};
				}

				this.form = {...this.form, ...this.visitReport, ...visitReportNew};
			},
			doApiCall() {
				if (this.newVisitReport) {
					return Api.postAddVisitReport(this.companyId, this.form);
				}
				return Api.postUpdateVisitReport(this.form.companyId, this.visitReport.id, this.form);
			}
		},
		mounted() {
			this.initFormObject();
		}
	};
</script>
