<template>
	<base-grid sidebar="account" page-title="Mijn gegevens">
		<form-my-account />
	</base-grid>
</template>

<script>
	import BaseGrid from '../decorations/BaseGrid';
	import FormMyAccount from './FormMyAccount';
	export default {
		name: 'PageMyAccount',
		components: {FormMyAccount, BaseGrid},
		data() {
			return {
				form: {
					firstName: ''

				}
			};
		},
		validation: {}
	};
</script>

