<template>
	<div>
		<label v-if="label && !hideLabel"
		       :for="inputId">
			{{ label }}
			<span v-if="required">*</span>
		</label>
		<div :class="disabled ? 'custom-select--disabled' : ''" class="custom-select">
			<select :id="inputId"
			        :aria-label="hideLabel ? label : null"
			        :autocomplete="autocomplete"
			        :disabled="disabled"
			        :name="name"
			        :required="required"
			        :value="modelValue"
			        @input="$emit('update:modelValue', $event.target.value)">
				<slot>
					<option v-if="addEmptyOption"></option>
					<option v-for="(option, index) in options"
					        :key="index"
					        :value="option"
					        v-text="option"></option>
				</slot>
			</select>

			<!-- TODO: Fix positioning of error at 'custom selects' -->
			<error-wrapper v-if="validation && validation.$error" :for="inputId">
				{{ validationMessage }}
			</error-wrapper>
		</div>
	</div>
</template>

<script>
import ErrorWrapper from './ErrorWrapper';
import {getFirstInvalidValidationMessage} from '@/utils/validatonMessages';
import {createUniqueId} from '@/utils/uid';

export default {
	name: 'FormSelect',
	components: {ErrorWrapper},
	props: {
		modelValue: {
			type: [String, Number],
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		validation: {
			type: Object
		},
		id: {
			type: String
		},
		name: {
			type: String
		},
		hideLabel: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			default: () => []
		},
		multiple: {
			type: Boolean
		},
		autocomplete: {
			type: String
		},
		addEmptyOption: {
			type: Boolean
		}
	},
	computed: {
		inputId() {
			return this.id ?? `input-id-${this.uid}`;
		},

		validationMessage() {
			const validationObject = this.validation;
			if (validationObject === null) {
				return null;
			}
			return getFirstInvalidValidationMessage(validationObject);
		}
	},
	data() {
		return {
			showTooltip: false,
			uid: createUniqueId()
		};
	},

	methods: {
		hideError() {
			this.showTooltip = false;
		},
		showError() {
			this.showTooltip = true;
		}
	}
};
</script>

<style>
.custom-select--disabled {
	opacity: .25;
}
</style>
