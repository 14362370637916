// store methods
export const STORE_NAMES = {
	// getters
	GET_SCRIPT: 'script',
	GET_SCRIPT_FLOW: 'scriptFlow',
	GET_CLIENT_SCRIPT_ANSWERS: 'getClientScriptAnswers',
	GET_FUNCTION_GROUPS: 'getFunctionGroups',
	GET_FUNCTION_GROUP_BY_ID: 'getFunctionGroupById',
	GET_NEXT_SCRIPT_ROUTE: 'getRouteForNextScript',
	GET_SCRIPT_TYPE: 'getScriptType',

	// mutations
	SET_SCRIPT_FLOW: 'setScriptFlow',
	SET_SCRIPT: 'setScript',
	SET_CLIENT_ANSWERS: 'setAnswers',
	SET_FUNCTION_GROUPS: 'setFunctionGroups',
	CLEAR_CLIENT_SCRIPT_ANSWERS: 'clearClientScriptAnswers',
	CLEAR_STORE: 'clearStore',

	// actions
	SAVE_ANSWER: 'saveAnswers',
	LOAD_SCRIPT_FLOW: 'loadScriptFlow',
	LOAD_SCRIPT: 'loadScript',
	LOAD_ANSWERS: 'loadAnswers',
	START_CHECK: 'startCheck',
	LOAD_FUNCTION_GROUPS: 'loadFunctionGroups',
	GET_RESULTS: 'getResults'
};

const PHYSICAL_STRAIN_PREFIX = 'physicalStrain/';

// namespaced store methods
export const PHYSICAL_STRAIN_STORE = {
	// getters
	GET_SCRIPT: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_SCRIPT,
	GET_SCRIPT_FLOW: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_SCRIPT_FLOW,
	GET_CLIENT_ANSWERS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_CLIENT_SCRIPT_ANSWERS,
	GET_FUNCTION_GROUPS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_FUNCTION_GROUPS,
	GET_FUNCTION_GROUP_BY_ID: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_FUNCTION_GROUP_BY_ID,
	GET_NEXT_SCRIPT_ROUTE: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_NEXT_SCRIPT_ROUTE,
	GET_SCRIPT_TYPE: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_SCRIPT_TYPE,

	// mutations
	SET_SCRIPT_FLOW: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.SET_SCRIPT_FLOW,
	SET_SCRIPT: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.SET_SCRIPT,
	SET_CLIENT_ANSWERS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.SET_CLIENT_ANSWERS,
	SET_FUNCTION_GROUPS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.SET_FUNCTION_GROUPS,
	CLEAR_CLIENT_SCRIPT_ANSWERS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.CLEAR_CLIENT_SCRIPT_ANSWERS,
	CLEAR_STORE: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.CLEAR_STORE,

	// actions
	SAVE_ANSWER: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.SAVE_ANSWER,
	LOAD_SCRIPT_FLOW: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.LOAD_SCRIPT_FLOW,
	LOAD_SCRIPT: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.LOAD_SCRIPT,
	LOAD_ANSWERS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.LOAD_ANSWERS,
	LOAD_FUNCTION_GROUPS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.LOAD_FUNCTION_GROUPS,
	START_CHECK: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.START_CHECK,
	GET_RESULTS: PHYSICAL_STRAIN_PREFIX + STORE_NAMES.GET_RESULTS
};
