import store from '../store';

/**
 * Contains the logic for executing a Fetch call.
 * Also contains the default code for handling error responses.
 */
class FetchHelper {

	/**
	 * Function to handle the json returned after a fetch call
	 * @param {object} json
	 * @param {object} response - Response object of the fetch
	 * @return {object} If all goes well it return the same json it received
	 * @throws Will throw an error if response has a custom error message or response is not ok
	 */
	static jsonHandler(json, response) {
		if (json.errors && json.errors.length > 0) {
			const customError = new Error(json.errors[0]);
			customError.name = 'customError';
			throw customError;
		}

		if (!response.ok) {
			throw new Error(`Er is iets misgegaan (${response.status})`);
		}

		return json;
	}

	/**
	 * Function to handle a response error so it throws a more human friendly error
	 * @param {(object|string)} error
	 * @param {object} response - Response object of the fetch
	 * @throws {object}
	 */
	static errorHandler(error, response) {
		let errormessage;
		if (typeof error === 'string') {
			errormessage = error;
		} else if (error.name === 'customError') {
			errormessage = error.message;
		} else {
			errormessage = FetchHelper.getErrorMessageForResponseStatus(response.status);
		}
		throw {
			message: errormessage,
			status: response.status
		};
	}

	/**
	 * Get a text for a http status code
	 * @param {number} status - HTTP status code
	 * @return {string}
	 */
	static getErrorMessageForResponseStatus(status) {
		if (status === 403) {
			return 'Geen rechten of toegang tot dit onderdeel';
		}

		if (status >= 500) {
			return 'Applicatie is tijdelijk niet beschikbaar, probeer het later nog eens.';
		}

		if (status >= 400) {
			return 'De applicatie heeft een onverwachte fout gegeven, probeer het later nog eens.';
		}

		return `Er is iets misgegaan (${status})`;
	}

	static loadJson(url) {
		const promise = fetch(url, {
			headers: {
				'content-type': 'application/json'
			},
			credentials: 'include'
		});

		return promise.then((response) => {

			// Not logged in
			if (response.status === 403 && response.redirected && response.url.endsWith('/login')) {
				store.commit('logout', false);
				return;
			}

			// No content
			if (response.status === 204) {
				return;
			}

			return response.json()
				.then(json => FetchHelper.jsonHandler(json, response))
				.catch(error => FetchHelper.errorHandler(error, response));
		});
	}

	static executeFetchJson(url, method, body) {
		return FetchHelper.executeFetch(url, method, 'application/json', JSON.stringify(body));
	}

	/**
	 * @param url
	 * @param method
	 * @param contentType - pass null in case of a multipart/form-data file upload call,
	 * because the content-type header should get a (automatic) dynamic value,
	 * such as 'multipart/form-data; boundary=----WebKitFormBoundarys3LIuuA30JnHHyRS'
	 * @param body
	 * @return {object} Returns the JSON of the fetch
	 */
	static executeFetch(url, method, contentType, body) {
		const headers = new Headers();

		headers.append('Accept', 'application/json');

		if (contentType) {
			headers.append('Content-Type', contentType);
		}

		return fetch(url, {
			method,
			headers,
			body,
			credentials: 'include'
			// eslint-disable-next-line
		}).then((response) => {
			return response.json()
				.then(json => FetchHelper.jsonHandler(json, response))
				.catch(error => FetchHelper.errorHandler(error, response));
		}).catch((err) => {
			const error = err;
			// If the fetch doesn't have a response the error.message will be 'Failed to fetch'
			// This is the case when the request is canceled, has a timout or if there's no connection at all.
			if (error.message.toLowerCase() === 'failed to fetch') {
				error.message = 'Applicatie is tijdelijk niet beschikbaar, probeer het later nog eens.';
			}

			throw error;
		});
	}

}

export {FetchHelper};
