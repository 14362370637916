<template>
	<p v-if="loading">
		Gegevens worden opgehaald
	</p>

	<p v-else-if="loadingFailed && warningMessage">
		{{ warningMessage }}
	</p>

	<vuelidate-form v-else
	                :is-submitting="isSubmitting"
	                :vuelidate="v$"
	                :warning-message="formWarningMessage"
	                class="content-form"
	                submit-button-text="Opslaan"
	                @validSubmit="handleSubmit">

		<div class="block-form-title">
			<h2>Eigenaar van {{ form.name }}</h2>
		</div>

		<div class="block-form">
			<div class="row">
				<div class="col-6 col-sm-12">

					<form-input v-model.trim="v$.form.ownerName.$model"
					            disabled
					            label="Naam" />

					<form-input v-model.trim="v$.form.ownerEmail.$model"
					            :validation="v$.form.ownerEmail"
					            label="E-mailadres"
					            maxlength="255"
					            placeholder="E-mailadres"
					            type="email" />
				</div>
			</div>
		</div>
	</vuelidate-form>

</template>

<script>
import VuelidateForm from '@/components/forms/VuelidateForm';
import FormInput from '@/components/forms/FormInput';
import {email, maxLength, required} from '@vuelidate/validators';
import {Api} from '@/utils/api';
import {pageHelper} from '@/utils/pageHelper';
import {useVuelidate} from '@vuelidate/core';

export default {
	name: 'FormCompanyDetail',
	components: {VuelidateForm, FormInput},
	data() {
		return {
			loading: true,
			loadingFailed: false,
			isSubmitting: false,
			warningMessage: '',
			formWarningMessage: '',
			form: {
				ownerName: '',
				ownerEmail: ''
			}
		};
	},
	setup() {
		return {
			v$: useVuelidate()
		};
	},
	validations: {
		form: {
			ownerName: {},
			ownerEmail: {
				email,
				required,
				maxLength: maxLength(255)
			}
		}
	},
	computed: {
		companyId() {
			return this.$route.params.companyId;
		}
	},
	methods: {
		async handleSubmit() {
			this.$confirmDialog({
					title: 'Eigenaar wijzigen',
					content: 'Weet je zeker dat je de eigenaar van dit bedrijf wilt wijzigen?',
					confirmButton: 'Ja',
					cancelButton: 'Nee',
					dialogType: 'warning'
				})
				.then(() => {
					this.updateCompanyOwner();
				});
		},
		async updateCompanyOwner() {
			this.isSubmitting = true;
			this.formWarningMessage = '';

			await Api.postChangeCompanyOwner(this.companyId, this.form)
				.then(() => {
					this.$router.push({
						name: pageHelper.PAGE_ADMIN_COMPANIES_OVERVIEW
					});
				})
				.catch((error) => {
					if (error.message && error.message.length > 0) {
						this.formWarningMessage = error.message;
						return;
					}
					this.formWarningMessage = 'Het is niet gelukt de bedrijfseigenaar te wijzigen';
				}).finally(() => {
					this.isSubmitting = false;
				});
		},
		async getCompanyData() {
			this.loading = true;

			await Api.getCompanyDetailForAdmin(this.companyId).then((response) => {
				this.form = response.responseObject;
			}).catch(() => {
				this.warningMessage = 'Gegevens ophalen is niet gelukt';
				this.loadingFailed = true;
			}).finally(() => {
				this.loading = false;
			});
		}
	},
	mounted() {
		this.getCompanyData();
	}
};
</script>
