<template>
	<div class="header-check5-progressbar">
		<div class="row">
			<div class="col-12">
				<ul class="check5-tabs">
					<li :class="{active: progressStep === '1'}">
						<div>
							Kies
							<icon-check-of5-check />
						</div>
					</li>
					<li :class="{active: progressStep === '2'}">
						<div>
							Inventariseer
							<icon-check-of5-check />
						</div>
					</li>
					<li :class="{active: progressStep === '3'}">
						<div>
							Check
							<icon-check-of5-check />
						</div>
					</li>
					<li :class="{active: progressStep === '4'}">
						<div>
							Signaleer
							<icon-check-of5-check />
						</div>
					</li>
					<li :class="{active: progressStep === '5'}">
						<div>
							Verbeter
							<icon-check-of5-check />
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import IconCheckOf5Check from '@/components/icons/IconCheckOf5Check';

	export default {
		name: 'CheckOf5Header',
		components: {IconCheckOf5Check},
		props: {
			progressStep: {
				type: String
			}
		}
	};
</script>

<style lang="scss">
	@import '../../assets/variables';

	.check5-tabs {
		padding: 0;
		margin: 0;
		list-style: none;
		counter-reset: check5tabs;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-content: stretch;
		align-items: flex-end;
		max-width: 100%;
		overflow: hidden;

		li {
			list-style: none;
			counter-increment: check5tabs;
			display: inline-block;

			order: 0;
			flex: 0 1 auto;
			align-self: auto;

			span, a, div {
				display: block;
				position: relative;
				text-transform: uppercase;
				font-family: $font-stack-header-bold;
				font-size: 13px;
				line-height: 18px;
				text-decoration: none;
				color: #abd8e9;
				background: $blue;
				padding: 17px 12px 10px 44px;
				border-radius: 5px 5px 0 0;
				letter-spacing: calc(0.27 / 13 * 1em);
				overflow: hidden;

				@media screen and (max-width: 980px) {
					text-indent: -100em;
				}

				&:before {
					content: counter(check5tabs);
					position: absolute;
					top: 4px;
					left: 21px;

					font-size: 17px;
					font-weight: bold;
					text-indent: 0;
				}

				svg {
					content: '';
					position: absolute;
					left: 18px;
					top: 14px;
					width: 24px;
					height: 19px;
					opacity: .5;
				}
			}

			&.active {
				span, a, div {
					color: #009fda;
					background: #ffffff;
					text-indent: 0;

					svg {
						opacity: 1;
					}
				}
			}
		}
	}

	.header-check5-progressbar {
		background: $blue;
		color: #ffffff;
		border-bottom: 4px solid #ffffff;
		padding: 0 0 0;

		@media #{$mobile} {
			display: none;
		}

		.check5-tabs {
			padding-top: 8px;
			justify-content: flex-end;
			margin-left: auto;
		}
	}
</style>
