<template>
	<div :class="`radio-list-item--checkbox-${checkboxPosition}`" class="radio-list-item">
		<input :id="inputId"
		       :checked="shouldBeChecked"
		       :disabled="disabled"
		       :name="name"
		       :required="required"
		       :value="value"
		       type="radio"
		       @change="updateInput">
		<label :for="inputId">{{ label }}</label>
	</div>
</template>

<script>

import {createUniqueId} from '@/utils/uid';

export default {
	name: 'RadioListItem',
	model: {
		prop: 'modelValue',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		modelValue: {
			default: false
		},
		label: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		id: {
			type: String
		},
		name: {
			type: String
		},
		checkboxPosition: {
			type: String,
			default: 'left'
		}
	},
	computed: {
		inputId() {
			return this.id ?? `input-id-${this.uid}`;
		},
		shouldBeChecked() {
			return this.modelValue === this.value;
		}
	},
	data() {
		return {
			uid: createUniqueId()
		};
	},
	methods: {
		updateInput() {
			this.$emit('update:modelValue', this.value);
		}
	}
};
</script>

<style lang="scss">
.radio-list-item.radio-list-item--checkbox-left {
	label {
		user-select: none;
		padding-left: 38px;

		&::before {
			position: absolute;
			left: 10px;
			top: 10px;
			margin: 0;
		}
	}
}
</style>
