import {rights} from '@/types/Rights';

export const rightsHelper = Object.freeze({
	hasReadOnlyAccess(right) {
		return right === rights.READONLY;
	},
	hasWriteAccess(right) {
		return right === rights.WRITE;
	},
	hasAdminAccess(right) {
		return right === rights.ADMIN;
	},

	hasAtLeastReadOnlyAccess: right => (right === rights.READONLY || rightsHelper.hasAtLeastWriteAccess(right)),
	hasAtLeastWriteAccess: right => (right === rights.WRITE || rightsHelper.hasAtLeastAdminAccess(right)),
	hasAtLeastAdminAccess: right => right === rights.ADMIN
});
