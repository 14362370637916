<template>
	<div>
		<skeleton-block :key="i" v-for="i in 6">
			<div class="block-company-overview-check">
				<div class="block-company-overview-check_status">
					<svg height="40" viewBox="0 0 48 30" width="70">
						<circle cx="30" cy="15" fill="#aaa" r="15" />
						<circle cx="15" cy="15" fill="#000" r="15" />
					</svg>
				</div>
				<div class="block-company-overview-check_title">
					<h2>
						<skeleton-text-placeholder length="13" />
					</h2>
					<h2 class="inline-header"><strong>
						<skeleton-text-placeholder length="20" />
					</strong></h2>
				</div>

				<button class="button block-company-overview-check_button" type="button">
					<skeleton-text-placeholder length="8" />
				</button>
			</div>
		</skeleton-block>
	</div>
</template>

<script>
	import SkeletonBlock from '../generic/SkeletonBlock';
	import SkeletonTextPlaceholder from '../generic/SkeletonTextPlaceholder';

	export default {
		name: 'SkeletonImprovementCheckOverview',
		components: {SkeletonTextPlaceholder, SkeletonBlock}
	};
</script>
