<template>
	<div>
		<div class="content-form" v-if="title">
			<div class="block-form-title">
				<h2 v-text="title"></h2>
			</div>
		</div>

		<div class="block-form">
			<skeleton-block transparent>
				<div :key="row" class="row" v-for="row in rows">
					<div :key="column" class="col-6 col-sm-12" v-for="column in columns">
						<label>
							<skeleton-text-placeholder length="15" />
						</label>
						<div class="input-wrapper">
							<input disabled type="text" />
						</div>
					</div>
				</div>
			</skeleton-block>
		</div>
	</div>
</template>

<script>
	import SkeletonBlock from './SkeletonBlock';
	import SkeletonTextPlaceholder from './SkeletonTextPlaceholder';

	export default {
		name: 'SkeletonContentForm',
		components: {SkeletonTextPlaceholder, SkeletonBlock},
		props: {
			title: {
				type: String
			},
			columns: {
				type: Number,
				default: 2
			},
			rows: {
				type: Number,
				default: 2
			}
		}
	};
</script>
