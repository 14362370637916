<template>
	<base-grid page-title="404 Niet gevonden">
		<p>Deze pagina bestaat niet</p>
	</base-grid>
</template>
<script>
	import BaseGrid from './decorations/BaseGrid';

	export default {
		components: {BaseGrid}
	};
</script>
