<template>
	<div class="text-grey"
	     style="clear: left; padding-top: .75em;">
		<strong>LET OP!</strong>
		Het werken met een werkbak in combinatie met een heftruck is vanaf 1 juli 2020 niet meer toegestaan. Deze
		mogelijkheid uit de verbetercheck Valgevaar mag je dus niet meer gebruiken. Zie voor uitgebreide informatie
		en een leidraad van SZW
		(<a href="https://www.arboportaal.nl/actueel/nieuws/2020/06/29/leidraad-voor-werken-met-een-werkbak-of-werkplatform-ontwikkeld"
		    rel="noopener noreferrer" target="_blank">link</a>).
	</div>
</template>

<script>
	export default {
		name: 'WorkAtHeightDisclaimer'
	};
</script>
